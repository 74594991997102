import React, { Component } from "react";
import { isTablet, isMobile } from "react-device-detect";
import MuiThemeProvider from "material-ui/styles/MuiThemeProvider";
import { download } from "./Api";
import LinkModal from "./LinkModal";
import FolderModal from "./FolderModal";
import DeleteModal from "./DeleteModal";
import EditModal from "./edit/EditModal";
import Upload from "./Upload";
import AmazonAds from "./AmazonAds";
import Toolbar from "./Toolbar";
import "../css/club-menu.css";

export default class CloudMenu extends Component {
  static defaultProps = {
    mode: "full",
  };

  state = {
    openLinkModal: false,
    openFolderModal: false,
    openDeleteModal: false,
    openEditModal: false,
    uploadModalOpen: false,
    mobileMenu: false,
    amazonAd: false,
    dragUploadFile: true,
  };

  handleItemClick = (e, { name }) => this.setState({ activeItem: name });

  _mobileMenu = () => {
    this.setState({ mobileMenu: !this.state.mobileMenu });
    console.log("mobileMenu: " + this.state.mobileMenu);
  };

  _AmazonAd = () => {
    this.setState({ amazonAd: !this.state.amazonAd });
  };

  showUpload = () => {
    this.props.closeDesktopWindows();
    if (!this.state.uploadModalOpen) {
      this.setState({ uploadModalOpen: true });
    }
  };

  showLink = (dimmer) => () => {
    this.props.closeDesktopWindows();
    this.setState({ dimmer, openLinkModal: true });
  };

  showFolder = (dimmer) => () => {
    this.props.closeDesktopWindows();
    this.setState({ dimmer, openFolderModal: true });
  };

  showDelete = (dimmer) => () =>
    this.setState({ dimmer, openDeleteModal: true });

  showEdit = (dimmer) => () => {
    const { activeIndex, filesList, _addNotification } = this.props;

    console.log("activeIndex: ", activeIndex);
    console.log("filesList: ", filesList);
    console.log("_addNotification: ", _addNotification);

    if (activeIndex[0] !== -1 && activeIndex.length === 1) {
      let selected = filesList[activeIndex[0]];
      console.log("selected:", selected);
      if (selected.default) {
        selected = null;
        _addNotification(
          "warning",
          "Attention",
          "You can't edit default elements"
        );
        return false;
      }
    }

    if (activeIndex[0] !== -1 && activeIndex.length > 1) {
      _addNotification(
        "warning",
        "Attention",
        "Please select only one element, Because only one element can be edited at a time."
      );
      return false;
    }

    this.props.closeDesktopWindows();
    this.props.editElement();
    this.setState({ dimmer, openEditModal: true });
  };

  showDownload = () => {
    let selectedElement = this.props.activeIndex,
      filesList = this.props.filesList,
      _addNotification = this.props._addNotification,
      noSource = false;

    if (selectedElement[0] !== -1) {
      if (selectedElement.length > 5) {
        _addNotification(
          "warning",
          "Attention",
          "Maximum 5 files download at a time."
        );
        return false;
      }

      let arr = [];
      let url = "?";
      for (let a = 0; a < selectedElement.length; a++) {
        if (
          filesList[selectedElement[a]].hasOwnProperty("linkurl") &&
          filesList[selectedElement[a]].hasOwnProperty("source")
        ) {
          arr.push(filesList[selectedElement[a]].linkurl);
        } else {
          noSource = true;
        }
      }

      if (noSource) {
        _addNotification(
          "info",
          "Information",
          "One of your selected elements is link or folder, you can only download uploaded content."
        );
      }

      for (let a = 0; a < arr.length; a++) {
        url += "key" + a + "=" + arr[a] + "&";
      }

      this._AmazonAd();
      download(url.slice(0, -1));
    }
  };

  close() {
    this.setState({
      openLinkModal: false,
      openFolderModal: false,
      openDeleteModal: false,
      openEditModal: false,
      uploadModalOpen: false,
    });
    document.querySelector(".uppy-Dashboard-close").click();
    document.querySelector(".uppy-Dashboard-close").click();
  }

  render() {
    const activeItem = this.state.activeItem,
      activeLayout = this.props.activeLayout,
      isAuth = this.props.isAuth,
      filesList = this.props.filesList,
      activeIndex = this.props.activeIndex,
      selectFile = this.props.selectFile;

    let disable = true,
      download = true;

    if (!isAuth) {
      disable = true;
      download = true;
    } else {
      if (activeIndex[0] !== -1) {
        disable = false;

        if (!isTablet && !isMobile) {
          download = false;
          if (
            activeIndex.length === 1 &&
            typeof filesList[activeIndex] !== "undefined" &&
            !filesList[activeIndex].hasOwnProperty("source")
          ) {
            download = true;
          }
        } else {
          download = true;
        }
      }
    }
    //  console.log("activeIndex: ", activeIndex);
    // Search in current folder

    return (
      <div className={"cloudMenuWrap " + this.props.mode}>
        {this.state.amazonAd && (
          <AmazonAds modal={true} close={this._AmazonAd.bind(this)} />
        )}

        <Toolbar
          filesList={filesList}
          disable={disable}
          download={download}
          activeIndex={activeIndex}
          activeLayout={activeLayout}
          isAuth={isAuth}
          mode={this.props.mode}
          activeItem={activeItem}
          ram={this.props.ram}
          ramAction={this.props.ramAction}
          searchBox={this.props.searchBox}
          tag={this.props.tag}
          _setTag={this.props._setTag}
          controlWindow={this.props.controlWindow}
          _mobileMenu={this._mobileMenu}
          mobileMenu={this.state.mobileMenu}
          gotoMobile={this.props.gotoMobile}
          showFolder={this.showFolder}
          searchText={this.props.searchText}
          handleLayoutClick={this.props.handleLayoutClick}
          paste={this.props.paste}
          showUpload={this.showUpload}
          showLink={this.showLink}
          showEdit={this.showEdit}
          showDelete={this.showDelete}
          CutOrCopy={this.props.CutOrCopy}
          showDownload={this.showDownload}
          setSelectFiles={this.props.setSelectFiles}
          selectFile={selectFile}
        />

        <Upload
          sub={this.props.sub}
          open={this.state.uploadModalOpen}
          close={this.close.bind(this)}
          _addNotification={this.props._addNotification}
          filesManipulation={this.props.filesManipulation}
          checkFileExist={this.props.checkFileExist}
          showUpload={this.showUpload.bind(this)}
        />

        <DeleteModal
          open={this.state.openDeleteModal}
          close={this.close.bind(this)}
          delete={this.props.delete}
          filesList={this.props.filesList}
        />

        <MuiThemeProvider>
          <EditModal
            open={this.state.openEditModal}
            close={this.close.bind(this)}
            selectedElement={this.props.activeIndex}
            filesList={this.props.filesList}
            dimmer={this.state.dimmer}
            editeState={this.props.editElement}
            editFile={this.props.editFile}
            _addNotification={this.props._addNotification}
          />
        </MuiThemeProvider>

        <MuiThemeProvider>
          <LinkModal
            {...this.state}
            closeModal={this.close.bind(this)}
            addNewFile={this.props.addNewFile}
          />
        </MuiThemeProvider>

        <MuiThemeProvider>
          <FolderModal
            {...this.state}
            closeModal={this.close.bind(this)}
            addNewFile={this.props.addNewFile}
          />
        </MuiThemeProvider>
      </div>
    );
  }
}
