import React, { Component } from "react";
import {
  Header,
  Image,
  Divider,
  Button,
  Icon,
  Segment,
} from "semantic-ui-react";
import SadFace from "../images/svg/cancel2.svg";
import "../css/page-404.css";

export default class Cancelled extends Component {
  render() {
    return (
      <Segment compact className="page404" textAlign="center">
        <Divider hidden />
        <Header as="h1" textAlign="center" color="red">
          Purchase Cancelled
        </Header>
        <Image src={SadFace} size="medium" className="sadFace" />
        <Divider hidden />
        <Button as="a" color="blue" href="/">
          <Icon name="home" />
          Go Home
        </Button>
        <Divider hidden />
      </Segment>
    );
  }
}
