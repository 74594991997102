import React, { Component } from "react";
import ReactDOM from "react-dom";
import { Link } from "react-router-dom";
import { isTablet, isMobile } from "react-device-detect";
import {
  Container,
  Divider,
  Dropdown,
  Table,
  Icon,
  Button,
  Sticky,
} from "semantic-ui-react";
import * as Api from "./Api";
import CloudMenu from "./CloudMenu";
import NavBreadcrumbs from "./NavBreadcrumbs";
import File from "./File";
import PageNotFound from "../pages/404";
import { websiteScreenshot, siteTitle } from "../js/functions";
import logo from "./../images/logo-horizontal.svg";
import Intro from "./Intro";

import "../css/cloud-window.css";
import "../css/sticky-menu.css";

export default class CloudWindow extends Component {
  constructor() {
    super();
    this.state = {
      filesList: {},
      sub: null,
      activeIndex: [-1],
      keyPress: false,
      edit: false,
      search: "",
      tag: "",
      sortBy: "linkname",
      sortAsc: true,
      activeLayout: "block",
      ram: [],
      ramUrl: null,
      ramAction: null,
      page404: false,
      selectFile: false,
      noDelay: false,
      orientation: null,
    };

    this.handleScroll = this.handleScroll.bind(this);
    this.handleLayoutClick = this.handleLayoutClick.bind(this);
    this.orientationChange = this.orientationChange.bind(this);
  }

  _uppercase = (str) => {
    var array1 = str.split(" ");
    var newarray1 = [];

    for (var x = 0; x < array1.length; x++) {
      newarray1.push(array1[x].charAt(0).toUpperCase() + array1[x].slice(1));
    }
    return newarray1.join(" ");
  };

  _setTag = (val) => {
    console.log("_setTag val: ", val);
    this.setState({
      tag: val,
    });
  };

  _getData() {
    let sub =
      typeof this.props.profile !== "undefined" ? this.props.profile.sub : "";
    Api.fetchFilesList(sub, this.props.defaultLinks).then((res) => {
      //   console.log(res);
      if (res.hasOwnProperty("status") && res.status === 404) {
        this.setState({ page404: true });
      } else if (
        res.hasOwnProperty("docs") &&
        this.state.filesList !== res.docs
      ) {
        this.setState({ filesList: res.docs, sub: sub });
      }
    });
  }

  _editElement() {
    this.setState({
      edit: !this.state.edit,
    });
  }

  handleScroll = (event) => {
    let scrollTop = event.currentTarget.scrollY; //event.target.scrollTop;
    //  console.log("scrollTop: ", scrollTop);
    if (scrollTop > 560 && !this.state.stickyMenu) {
      this.setState({ stickyMenu: true });
      console.log("stickyStatus: " + this.state.stickyMenu);
    } else if (scrollTop <= 560 && this.state.stickyMenu) {
      this.setState({ stickyMenu: false });
      console.log("stickyStatus: " + this.state.stickyMenu);
    }
  };

  orientationChange = () => {
    let parent = this;
    if (window.innerWidth < window.innerHeight) {
      this.setState({ orientation: "portrait" }, function () {
        parent.forceUpdate();
      });
      //   alert("portrait");
    } else {
      this.setState({ orientation: "landscape" }, function () {
        parent.forceUpdate();
      });
      //   alert("landscape");
    }
  };

  componentDidMount() {
    //    this._getData();
    let filesList = this.state.filesList;

    if (
      Object.keys(filesList).length === 0 &&
      filesList.constructor === Object
    ) {
      this._getData();
    }

    document.addEventListener(
      "click",
      this.handleClickOutside.bind(this),
      true
    );
    window.addEventListener("scroll", this.handleScroll, true);
    window.addEventListener("orientationchange", this.orientationChange, true);
  }
  componentWillUnmount() {
    document.removeEventListener(
      "click",
      this.handleClickOutside.bind(this),
      true
    );
    window.removeEventListener("scroll", this.handleScroll, true);
    window.removeEventListener(
      "orientationchange",
      this.orientationChange,
      true
    );
  }

  handleClickOutside(event) {
    if (this.state.activeIndex[0] !== -1) {
      //    console.log(event);
      const domNode = ReactDOM.findDOMNode(this);
      if (
        !this.state.edit &&
        event.target.className !== "ui green inverted button" &&
        event.target.className !== "checkmark icon" &&
        event.target.className !==
          "ui page modals dimmer transition visible active"
      ) {
        // && event.target.className !== "ui yellow inverted button"
        if (
          !domNode ||
          !domNode.contains(event.target) ||
          event.target.className === "mainLayout clearfix" ||
          event.target.className === "clearfix"
        ) {
          this.setState({
            activeIndex: [-1],
          });
        }
      }
    }
  }

  _checkFileExist = (file, folder, id = null) => {
    let exist = false;
    let filesObject = this.state.filesList;
    console.log("Check file: ", file);
    if (!folder) {
      for (let count = 0; count < filesObject.length; count++) {
        if (
          filesObject[count].hasOwnProperty("linkname") &&
          filesObject[count].hasOwnProperty("linktype") &&
          file.linkname.trim().toLowerCase() ===
            filesObject[count].linkname.trim().toLowerCase() &&
          id !== filesObject[count]._id &&
          filesObject[count].linktype.trim().toLowerCase() !== "folder"
        ) {
          exist = true;
        }
      }
    } else {
      for (let count = 0; count < filesObject.length; count++) {
        if (
          filesObject[count].hasOwnProperty("linkname") &&
          filesObject[count].hasOwnProperty("linktype") &&
          file.linkname.trim().toLowerCase() ===
            filesObject[count].linkname.trim().toLowerCase() &&
          id !== filesObject[count]._id &&
          filesObject[count].linktype.trim().toLowerCase() === "folder"
        ) {
          exist = true;
        }
      }
    }

    return exist;
  };

  _addNewFile = (file, folder = false) => {
    let fileExist = !this._checkFileExist(file, folder); // true;
    if (fileExist) {
      try {
        this.props.showDimmer(true);
        file = Object.assign({ date: Date() }, file);
        if (!folder && websiteScreenshot) {
          file = Object.assign({ thumbnailWebsite: null }, file);
        }
        Api.addFile(this.state.sub, file)
          .then((res) => {
            //    res.docs.insertedIds = (typeof res.docs.insertedIds[0] === "undefined") ? res.docs.insertedIds : res.docs.insertedIds[0];
            //    file = Object.assign({ user: this.state.sub, _id: res.docs.insertedIds }, file);
            //    this.setState({ filesList: [...this.state.filesList, file]});
            this.setState({
              filesList: [...this.state.filesList, res.docs.ops[0]],
            });
            this.props.showDimmer(false);
            this.props._addNotification("success", "Inserted successfully", "");
          })
          .catch(function (error) {
            console.log(error);
            this.props.showDimmer(false);
          });
      } catch (error) {
        this.props.showDimmer(false);
        this.props._addNotification("error", "Error", "Some error occurred");
        console.log("some error");
      }
    } else {
      this.props._addNotification("error", "Error", "Name already exist");
      console.log("*** File already exist ****");
    }
    return fileExist;
  };

  _editStateFile = (filesList, file, a) => {
    if (file.hasOwnProperty("linktype")) {
      filesList[a].linktype = file.linktype;
    }
    if (file.hasOwnProperty("linkname")) {
      filesList[a].linkname = file.linkname;
    }
    if (file.hasOwnProperty("linkurl")) {
      filesList[a].linkurl = file.linkurl;
    }
    if (file.hasOwnProperty("linktag")) {
      filesList[a].linktag = file.linktag;
    }
    if (file.hasOwnProperty("linkimg")) {
      filesList[a].linkimg = file.linkimg;
    }
    if (file.hasOwnProperty("thumbnailImage")) {
      filesList[a].thumbnailImage = file.thumbnailImage;
    }
    if (file.hasOwnProperty("thumbnailVideo")) {
      filesList[a].thumbnailVideo = file.thumbnailVideo;
    }
    if (file.hasOwnProperty("thumbnailWebsite")) {
      filesList[a].thumbnailWebsite = file.thumbnailWebsite;
    }
    if (file.hasOwnProperty("openElement")) {
      filesList[a].openElement = file.openElement;
    }
    console.log("file: ", file);
    console.log("filesList[a]: ", filesList[a]);

    this.setState({ filesList: filesList });
  };

  _editFile = (id, file, folder = false) => {
    let fileExist = !this._checkFileExist(file, folder, id); // true;
    console.log("_editFile:", file);
    if (fileExist) {
      this.props.showDimmer(true);
      try {
        console.log("fileExist:");
        Api.editFile(this.state.sub, id, file)
          .then((res) => {
            console.log("fileExist res:", res);
            if (res.docs.result.ok && res.docs.modifiedCount > 0) {
              let filesList = this.state.filesList;
              for (let a = 0; a < filesList.length; a++) {
                if (filesList[a]._id === id) {
                  if (
                    file.hasOwnProperty("linktype") &&
                    file.linktype === "folder"
                  ) {
                    console.log("type folder!");
                    let path = window.location.pathname.replace(
                      "/allfiles",
                      ""
                    );
                    let prevPath = path + filesList[a].linkname + "/";
                    let newPath = path + file.linkname + "/";

                    Api.editPath(this.state.sub, prevPath, newPath).then(
                      (res) => {
                        console.log(res.docs);
                        this._editStateFile(filesList, file, a);
                      }
                    );
                  } else if (
                    filesList[a].hasOwnProperty("source") ||
                    (filesList[a].hasOwnProperty("thumbnailWebsite") &&
                      filesList[a].thumbnailWebsite !== null)
                  ) {
                    var arrNew = [],
                      arrOld = [];

                    console.log("Has Source or thumbnailWebsite");

                    if (
                      filesList[a].hasOwnProperty("thumbnailWebsite") &&
                      filesList[a].thumbnailWebsite !== "" &&
                      filesList[a].thumbnailWebsite !== null
                    ) {
                      arrOld.push(filesList[a].thumbnailWebsite);
                      arrNew.push(file.thumbnailWebsite);
                    } else {
                      if (filesList[a].hasOwnProperty("linkurl")) {
                        arrOld.push(filesList[a].linkurl);
                        arrNew.push(file.linkurl);
                      }
                    }

                    if (
                      filesList[a].hasOwnProperty("thumbnailImage") &&
                      filesList[a].thumbnailImage !== ""
                    ) {
                      arrOld.push(filesList[a].thumbnailImage);
                      arrNew.push(file.thumbnailImage);
                    }
                    if (
                      filesList[a].hasOwnProperty("thumbnailVideo") &&
                      filesList[a].thumbnailVideo !== ""
                    ) {
                      arrOld.push(filesList[a].thumbnailVideo);
                      arrNew.push(file.thumbnailVideo);
                    }

                    if (
                      filesList[a].hasOwnProperty("openElement") &&
                      filesList[a].openElement !== ""
                    ) {
                      arrOld.push(filesList[a].openElement);
                      arrNew.push(file.openElement);
                    }

                    console.log("arrOld: ", arrOld);
                    console.log("arrNew: ", arrNew);
                    if (
                      arrNew.length > 0 &&
                      arrOld.length > 0 &&
                      arrNew.length === arrOld.length
                    ) {
                      if (arrOld[0] !== arrNew[0]) {
                        Api.editFileAmazonS3(arrOld, arrNew).then((res) => {
                          console.log(res);
                          if (res.toLowerCase() === "ok") {
                            this._editStateFile(filesList, file, a);
                          }
                        });
                      } else {
                        this._editStateFile(filesList, file, a);
                      }
                    }
                  } else {
                    this._editStateFile(filesList, file, a);
                  }
                }
              }
              this.props.showDimmer(false);
              this.props._addNotification("success", "Edited successfully", "");
            }
          })
          .catch(function (error) {
            console.log(error);
            this.props.showDimmer(false);
            this.props._addNotification(
              "error",
              "Error",
              "Some error occurred"
            );
          });
      } catch (error) {
        this.props.showDimmer(false);
        this.props._addNotification("error", "Error", "Some error occurred");
        console.log("some error");
      }
    } else {
      this.props._addNotification("error", "Error", "Name already exist");
      console.log("*** File already exist ****");
    }
    return fileExist;
  };

  _gotoUrl = (name) => {
    window.history.pushState({}, "", name + "/");
    this._getData();
  };

  _gotoUrlMobile = (name) => {
    window.history.pushState({}, "", window.location.href + name + "/");
    this._getData();
    //  alert(window.location.pathname);
  };

  selectFileClick = (titleProps, e) => {
    e.preventDefault();
    //   e.persist();
    let index = titleProps;
    index = parseInt(index.props.objectNumber, 10);
    //   console.log(e);
    const { activeIndex, selectFile } = this.state;
    const newIndex = activeIndex.includes(index) ? -1 : index;
    //   console.log(this.state.activeIndex);
    if (e.shiftKey || e.ctrlKey || selectFile) {
      if (!selectFile) {
        this.setState({
          activeIndex: [...this.state.activeIndex, parseInt(newIndex, 10)],
        });
      } else {
        if (activeIndex[0] !== -1) {
          this.setState({
            activeIndex: [...this.state.activeIndex, parseInt(newIndex, 10)],
          });
        } else {
          this.setState({ activeIndex: [parseInt(newIndex, 10)] });
        }
      }
    } else {
      this.setState({ activeIndex: [parseInt(newIndex, 10)] });
    }

    //    console.log("Shift Key: " + e.shiftKey);
    //   console.log("Ctrl Key: " + e.ctrlKey);
  };

  _CutOrCopy = (filesList, action = "cut") => {
    const { activeIndex } = this.state;
    let arr = [],
      ramAction = action === "cut" ? "cut" : "copy";

    if (activeIndex[0] !== -1) {
      for (let a = 0; a < activeIndex.length; a++) {
        if (!filesList[activeIndex[a]].hasOwnProperty("default")) {
          arr.push(filesList[activeIndex[a]]);
        } else {
          this.props._addNotification(
            "warning",
            "Attention",
            "Can't " + ramAction + " default elements ."
          );
        }
      }

      this.setState({
        ram: arr,
        ramAction: ramAction,
        ramUrl: window.location.pathname.replace("/allfiles", ""),
        activeIndex: [-1],
      });
    }
  };

  _paste = () => {
    let { ram, ramAction, ramUrl, filesList } = this.state;
    let folder = [],
      temp = [];

    if (ramUrl === window.location.pathname.replace("/allfiles", "")) {
      this.props._addNotification(
        "error",
        "Error",
        "You can't " + ramAction + " and paste elements to the same folder."
      );
      return false;
    }
    //  console.log("New Ram: ", ram);
    if (ram.length > 0 && ramAction !== null) {
      for (let a = 0; a < ram.length; a++) {
        let exist = false;
        for (let b = 0; b < filesList.length; b++) {
          if (
            ram[a].linkname === filesList[b].linkname &&
            ram[a].linktype === filesList[b].linktype
          ) {
            this.props._addNotification(
              "warning",
              "Attention",
              ram[a].linkname + " already exist."
            );
            exist = true;
            //   ram.splice(a, 1);
          }
        }
        if (!exist) {
          temp.push(ram[a]);
        }
      }
    }

    ram = temp;
    //  console.log("ram.length ", ram.length);
    //  return false;
    if (ram.length > 0 && ramAction !== null) {
      console.log("Action: ", ramAction);
      console.log("Paste: ", ram);
      this.props.showDimmer(true);

      for (let a = 0; a < ram.length; a++) {
        if (ram[a].linktype === "folder") {
          //      console.log("^" + ram[a].path + encodeURIComponent(ram[a].linkname) + "/");
          folder.push(
            "^" + ram[a].path + encodeURIComponent(ram[a].linkname) + "/"
          );
        }
        if (ram[a].hasOwnProperty("filesList")) {
          delete ram[a].filesList;
        }
      }

      Api.pasteFile(ram, folder, ramAction, ramUrl).then((res) => {
        console.log(res);
        if (res.hasOwnProperty("docs") && (res.docs.ok || res.docs.result.ok)) {
          if (
            typeof res.docs.ops !== undefined &&
            typeof res.docs.ops !== "undefined" &&
            res.docs.ops !== null &&
            res.docs.ops.length > 0
          ) {
            let insertedDataArr = res.docs.ops;
            for (let a = 0; a < insertedDataArr.length; a++) {
              for (let b = 0; b < ram.length; b++) {
                if (
                  insertedDataArr[a].linkname === ram[b].linkname &&
                  insertedDataArr[a].date === ram[b].date &&
                  insertedDataArr[a].linktype === ram[b].linktype &&
                  insertedDataArr[a].user === ram[b].user
                ) {
                  ram[b]._id = insertedDataArr[a]._id;
                  console.log(
                    "insertedDataArr[" + a + "]: ",
                    insertedDataArr[a]
                  );
                  console.log("ram[" + b + "]: ", ram[b]);
                }
              }
            }
          }

          this.setState({
            filesList: filesList.concat(ram),
            activeIndex: [-1],
          });
          this.props._addNotification("success", "Paste successfully", "");
        } else if (!res.hasOwnProperty("docs")) {
          this.props._addNotification("info", "Please log in again.");
        } else {
          this.props._addNotification("error", "Error", "Can't paste");
          console.log("Error can't paste");
        }
        this.props.showDimmer(false);
      });

      this.setState({ ram: [], ramUrl: null, ramAction: null });
    }
  };

  _delete = (filesList) => {
    const { activeIndex, sub } = this.state;
    let arr = [],
      temp = [],
      folder = [],
      deleteFromS3 = [],
      deleteElements = [];
    console.log("*** Delete File ***");
    if (activeIndex[0] !== -1) {
      this.setState({ Attention: null });

      for (let a = 0; a < activeIndex.length; a++) {
        arr.push(filesList[activeIndex[a]]._id);
        deleteElements.push(filesList[activeIndex[a]]);
        if (
          filesList[activeIndex[a]].hasOwnProperty("default") &&
          filesList[activeIndex[a]].default
        ) {
          arr = [];
          this.props._addNotification(
            "warning",
            "Attention",
            "Default elements can't be deleted. You can hide them in 'My Account' page."
          );
          return false;
        }

        if (filesList[activeIndex[a]].linktype === "folder") {
          folder.push(
            "^" +
              window.location.pathname.replace("/allfiles", "") +
              filesList[activeIndex[a]].linkname +
              "/"
          );
        }

        if (filesList[activeIndex[a]].hasOwnProperty("source")) {
          if (filesList[activeIndex[a]].hasOwnProperty("linkurl")) {
            deleteFromS3.push(filesList[activeIndex[a]].linkurl);
          }
          if (filesList[activeIndex[a]].hasOwnProperty("thumbnailImage")) {
            deleteFromS3.push(filesList[activeIndex[a]].thumbnailImage);
          }
          if (filesList[activeIndex[a]].hasOwnProperty("thumbnailVideo")) {
            deleteFromS3.push(filesList[activeIndex[a]].thumbnailVideo);
          }
          if (filesList[activeIndex[a]].hasOwnProperty("openElement")) {
            deleteFromS3.push(filesList[activeIndex[a]].openElement);
          }
        }

        if (
          filesList[activeIndex[a]].hasOwnProperty("thumbnailWebsite") &&
          filesList[activeIndex[a]].thumbnailWebsite !== null
        ) {
          deleteFromS3.push(filesList[activeIndex[a]].thumbnailWebsite);
        }
      }
      /*
      activeIndex.map(index => {
        arr.push(filesList[index]._id);
        if(filesList[index].linktype === "folder"){
          folder.push("^" + window.location.pathname.replace("/allfiles","") + filesList[index].linkname + "/");
        }
        return true;
      });
*/
      //   console.log(arr);
      //   console.log(folder);
      console.log("Delete Files: ", arr);
      //    console.log("Delete Files List: ", filesList);
      console.log("Delete Files From AWS S3: ", deleteFromS3);
      //    return false;
      Api.deleteFile(sub, arr, false, deleteFromS3, deleteElements).then(
        (res) => {
          if (res.hasOwnProperty("docs") && res.docs.deletedCount) {
            if (folder.length > 0) {
              Api.deleteFile(sub, folder, true).then((res) => {
                for (let a = 0; a < filesList.length; a++) {
                  if (!arr.includes(filesList[a]._id)) {
                    temp.push(filesList[a]);
                  }
                }
                this.setState({
                  filesList: temp,
                  activeIndex: [-1],
                  ram: [],
                  ramAction: null,
                  ramUrl: null,
                });
                console.log("Deleted folder content");
              });
            } else {
              for (let a = 0; a < filesList.length; a++) {
                if (!arr.includes(filesList[a]._id)) {
                  temp.push(filesList[a]);
                }
              }
              this.setState({
                filesList: temp,
                activeIndex: [-1],
                ram: [],
                ramAction: null,
                ramUrl: null,
              });
              console.log("Deleted");
            }
            this.props._addNotification("success", "Deleted successfully", "");
          } else {
            this.props._addNotification("error", "Error", "Can't delete");
            console.log("Error can't delete");
          }
        }
      );
    }
  };

  _filesManipulation = (action, files) => {
    if (action === "upload") {
      this.setState({ filesList: [...this.state.filesList, files] });
    }
  };

  _searchText = (event) => {
    console.log("search: ", event.target.value);
    this.setState({
      search: event.target.value,
    });
  };
  /*
  handleLayoutClick = (e, { name }) => { 
    console.log("Layout: ", name);
    this.setState({ activeLayout: name }); 
  }
*/

  handleLayoutClick = () => {
    //  console.log("Layout: ", name);
    this.setState({
      activeLayout: this.state.activeLayout === "block" ? "list" : "block",
    });
  };

  sortValues = (key, order = "asc") => {
    return function (a, b) {
      if (!a.hasOwnProperty(key)) {
        a[key] = 0;
      }
      if (!b.hasOwnProperty(key)) {
        b[key] = 0;
      }

      const varA = typeof a[key] === "string" ? a[key].toUpperCase() : a[key];
      const varB = typeof b[key] === "string" ? b[key].toUpperCase() : b[key];

      let comparison = 0;
      if (varA > varB) {
        comparison = 1;
      } else if (varA < varB) {
        comparison = -1;
      }
      return order === "desc" ? comparison * -1 : comparison;
    };
  };

  _sortBy = (e, { name, value }) => {
    let val = null;

    switch (value) {
      case "name":
        val = "linkname";
        break;
      case "size":
        val = "size";
        break;
      case "type":
        val = "linktype";
        break;
      case "date":
        val = "date";
        break;
      default:
        val = "linkname";
    }
    console.log("Sort By: ", val);

    if (this.state.sortBy === val) {
      this.setState({ sortAsc: !this.state.sortAsc, noDelay: true });
    } else {
      this.setState({ sortBy: val, sortAsc: true, noDelay: true });
    }
  };

  handleContextRef = (contextRef) => this.setState({ contextRef });

  _stickyOn = () => {
    let stickyStatus = true;
    if (this.state.stickyMenu !== true) {
      this.setState({ stickyMenu: stickyStatus });
      console.log("stickyStatus: " + stickyStatus);
    } else {
      console.log("stickyStatus: " + this.state.stickyMenu);
    }
  };

  _stickyOff = () => {
    let stickyStatus = false;
    if (this.state.stickyMenu !== false) {
      this.setState({ stickyMenu: stickyStatus });
      console.log("stickyStatus: " + stickyStatus);
    }
  };

  _setSelectFiles = (val) => {
    console.log("_setSelectFiles: ", val);
    let selectFile = this.state.selectFile;
    if (selectFile !== val) {
      this.setState({
        selectFile: val,
        activeIndex: [-1],
      });
    }
  };

  _getElementWidth = (obj) => {
    let elementWidth = null;

    if (
      obj.linktype === "youtube" ||
      obj.linktype === "vimeo" ||
      obj.linktype === "pornhub" ||
      obj.linktype === "xvideos" ||
      obj.linktype.includes("video/")
    ) {
      elementWidth = 140;
    } else if (
      websiteScreenshot &&
      (obj.linktype === "website" ||
        obj.linktype === "webapp" ||
        obj.linktype === "webgame")
    ) {
      elementWidth = 140;
    } else {
      elementWidth = 115;
    }

    return elementWidth;
  };

  render() {
    //  console.log("edit: " + this.state.edit);
    window.onpopstate = (event) => {
      console.log("************* Pop State **********");
      console.log(window.location.pathname);
      this._getData();
    };
    console.log("render pathname: " + window.location.pathname);

    var filesObject = this.state.filesList;
    //    console.log("filesObject", filesObject);

    var search = this.state.search;
    if (search.trim() !== "") {
      let arrSearch = [];
      if (this.state.tag !== "") {
        this.setState({ tag: "" });
      }
      for (let a = 0; a < filesObject.length; a++) {
        if (
          filesObject[a] &&
          filesObject[a].linkname &&
          filesObject[a].linkname.toLowerCase().includes(search.toLowerCase())
        ) {
          arrSearch.push(filesObject[a]);
        }
      }
      filesObject = arrSearch;
      //   console.log(filesObject);
    }

    let filterTags = this.state.filesList;
    let arrTags = [];

    for (let a = 0; a < filterTags.length; a++) {
      if (
        typeof filterTags[a].linktag !== undefined &&
        typeof filterTags[a].linktag !== "undefined" &&
        filterTags[a].linktag !== []
      ) {
        for (let b = 0; b < filterTags[a].linktag.length; b++) {
          if (!arrTags.includes(this._uppercase(filterTags[a].linktag[b]))) {
            arrTags.push(this._uppercase(filterTags[a].linktag[b]));
          }
        }
      }
    }

    var tag = this.state.tag;
    if (tag !== "") {
      let arrFilterTags = [];
      if (this.state.search !== "") {
        this.setState({ search: "" });
      }
      for (let a = 0; a < filterTags.length; a++) {
        if (
          typeof filterTags[a].linktag !== undefined &&
          typeof filterTags[a].linktag !== "undefined" &&
          filterTags[a].linktag !== []
        ) {
          let tagExist = false;
          for (let b = 0; b < filterTags[a].linktag.length; b++) {
            if (this._uppercase(filterTags[a].linktag[b]) === tag) {
              tagExist = true;
            }
          }
          if (tagExist) {
            arrFilterTags.push(filterTags[a]);
          }
        }
      }
      filesObject = arrFilterTags;
    }

    if (filesObject.length > 0) {
      console.log("filesObject: ", filesObject);
      let arrFolder = [];
      let arrSize = [];
      let arrTemp = [];
      let arrSimple = [];

      switch (this.state.sortBy) {
        case "linkname":
          filesObject.forEach((element) => {
            if (
              element.hasOwnProperty("linktype") &&
              element.linktype === "folder"
            ) {
              arrFolder.push(element);
            } else {
              arrSimple.push(element);
            }
          });

          arrFolder.sort(this.sortValues("linkname"));
          arrSimple.sort(this.sortValues("linkname"));
          filesObject = arrFolder.concat(arrSimple);
          break;
        case "size":
          filesObject.forEach((element) => {
            if (
              element.hasOwnProperty("linktype") &&
              element.linktype === "folder"
            ) {
              arrFolder.push(element);
            } else if (element.hasOwnProperty("size") && element.size > 0) {
              arrSize.push(element);
            } else {
              arrTemp.push(element);
            }
          });

          arrTemp.sort(this.sortValues("linkname"));
          arrFolder.sort(this.sortValues("linkname"));
          arrSize.sort(this.sortValues("size"));
          filesObject = arrFolder.concat(arrTemp).concat(arrSize);
          break;
        case "linktype":
          filesObject.forEach((element) => {
            if (
              element.hasOwnProperty("linktype") &&
              element.linktype === "folder"
            ) {
              arrFolder.push(element);
            } else {
              arrSimple.push(element);
            }
          });

          arrFolder.sort(this.sortValues("linkname"));
          arrSimple.sort(this.sortValues("linktype"));
          filesObject = arrFolder.concat(arrSimple);
          break;
        case "date":
          filesObject.sort(function (a, b) {
            return new Date(b.date) - new Date(a.date);
          });
          break;
        default:
          filesObject.sort(this.sortValues(this.state.sortBy));
      }

      if (!this.state.sortAsc) {
        filesObject.reverse();
      }
    }

    const sortByOptions = [
      {
        key: "name",
        text: "Name",
        value: "name",
        icon:
          this.state.sortBy === "linkname"
            ? this.state.sortAsc
              ? "arrow down"
              : "arrow up"
            : "",
      },
      {
        key: "date",
        text: "Date",
        value: "date",
        icon:
          this.state.sortBy === "date"
            ? this.state.sortAsc
              ? "arrow down"
              : "arrow up"
            : "",
      },
      {
        key: "size",
        text: "Size",
        value: "size",
        icon:
          this.state.sortBy === "size"
            ? this.state.sortAsc
              ? "arrow down"
              : "arrow up"
            : "",
      },
      //    { key: 'type', text: 'Type', value: 'type', icon: (this.state.sortBy === "linktype") ? ((this.state.sortAsc) ? 'long arrow down': 'long arrow up') : '' }
    ];

    let delay = 0;
    const { contextRef } = this.state;

    let stickyMenuClass =
      typeof this.state.stickyMenu === undefined ||
      typeof this.state.stickyMenu === "undefined" ||
      this.state.stickyMenu === null
        ? ""
        : this.state.stickyMenu
        ? "slide-in"
        : "slide-out";

    let mobileDevice = isTablet || isMobile ? true : false;

    /*  Google Ads Counter Start  */
    /* -------------------------- */

    let numElementsBeforeAdBlock = mobileDevice ? 10 : 20;
    let numElementsBeforeAdList = 10;

    let littleCountBlock =
      filesObject.length < numElementsBeforeAdBlock ? true : false;
    let littleCountList =
      filesObject.length < numElementsBeforeAdList ? true : false;
    if (this.state.activeLayout === "block") {
      if (!littleCountBlock) {
        let windowWidth = this.props.windowInnerWidth - 40;
        let elementsWidthCounter = 0;
        let arrAds = [];
        let elementCounter = 0;

        for (let a = 0; a < filesObject.length; a++) {
          if (!filesObject[a].hasOwnProperty("element")) {
            let elementWidth = this._getElementWidth(filesObject[a]);

            if (elementsWidthCounter + elementWidth > windowWidth) {
              if (elementCounter < numElementsBeforeAdBlock) {
                elementsWidthCounter = 0;
              } else {
                console.log(
                  "elementsWidthCounter: " +
                    elementsWidthCounter +
                    "\nwindowWidth: " +
                    windowWidth
                );
                elementsWidthCounter = 0;
                elementCounter = 0;
                arrAds.push({
                  _id: Math.random().toString(32).slice(2),
                  element: "ad",
                });
              }
            }

            arrAds.push(filesObject[a]);
            elementsWidthCounter += elementWidth;
            elementCounter++;
          }
        }

        filesObject = arrAds;
        console.log("arrAds: ", arrAds);
      }
    } else {
      if (!littleCountList) {
        let arrAds = [];
        let elementCounter = 0;

        for (let a = 0; a < filesObject.length; a++) {
          if (!filesObject[a].hasOwnProperty("element")) {
            if (elementCounter >= numElementsBeforeAdList) {
              elementCounter = 0;
              arrAds.push({
                _id: Math.random().toString(32).slice(2),
                element: "ad",
              });
            }

            arrAds.push(filesObject[a]);
            elementCounter++;
          }
        }

        filesObject = arrAds;
        console.log("arrAds: ", arrAds);
      }
    }
    /*  Google Ads Counter End  */
    /* ------------------------ */

    return (
      // onStick={ this._stickyOn.bind(this) } onUnstick={ this._stickyOff.bind(this) }

      <Container
        fluid
        className={
          "cloud-content clearfix" +
          (isTablet || isMobile ? " cloud-content-mobile" : "")
        }
      >
        {!this.props.isAuth && (
          <Intro mobileDevice={mobileDevice} darkMode={this.props.darkMode} />
        )}
        {!this.state.page404 && (
          <div className="cloudMainContent" ref={this.handleContextRef}>
            {!mobileDevice && this.props.isAuth && (
              <Sticky className="sticky-menu" context={contextRef}>
                <div className="minWidth992">
                  <div className={"scroll-menu " + stickyMenuClass}>
                    <Link className="App-logo" to="/">
                      <img src={logo} alt={siteTitle} />
                    </Link>

                    <CloudMenu
                      addNewFile={this._addNewFile}
                      isAuth={this.props.isAuth}
                      sub={this.state.sub}
                      activeIndex={this.state.activeIndex}
                      filesList={filesObject} //this.state.filesList
                      delete={this._delete}
                      editElement={this._editElement.bind(this)}
                      editFile={this._editFile}
                      _addNotification={this.props._addNotification}
                      filesManipulation={this._filesManipulation}
                      searchText={this._searchText}
                      searchBox={this.state.search}
                      tag={arrTags}
                      _setTag={this._setTag}
                      selectedTag={this.state.tag}
                      handleLayoutClick={this.handleLayoutClick}
                      activeLayout={this.state.activeLayout}
                      ram={this.state.ram}
                      ramAction={this.state.ramAction}
                      CutOrCopy={this._CutOrCopy}
                      paste={this._paste}
                      checkFileExist={this._checkFileExist}
                      controlWindow={this.props.controlWindow}
                      closeDesktopWindows={this.props.closeDesktopWindows}
                      mode="fixed"
                    />
                  </div>
                </div>
              </Sticky>
            )}
            {!mobileDevice && (
              <div className="minWidth992">
                <CloudMenu
                  addNewFile={this._addNewFile}
                  isAuth={this.props.isAuth}
                  sub={this.state.sub}
                  activeIndex={this.state.activeIndex}
                  filesList={filesObject} //this.state.filesList
                  delete={this._delete}
                  editElement={this._editElement.bind(this)}
                  editFile={this._editFile}
                  _addNotification={this.props._addNotification}
                  filesManipulation={this._filesManipulation}
                  searchText={this._searchText}
                  searchBox={this.state.search}
                  tag={arrTags}
                  _setTag={this._setTag}
                  selectedTag={this.state.tag}
                  handleLayoutClick={this.handleLayoutClick}
                  activeLayout={this.state.activeLayout}
                  ram={this.state.ram}
                  ramAction={this.state.ramAction}
                  CutOrCopy={this._CutOrCopy}
                  paste={this._paste}
                  checkFileExist={this._checkFileExist}
                  controlWindow={this.props.controlWindow}
                  closeDesktopWindows={this.props.closeDesktopWindows}
                  mode="full"
                />
              </div>
            )}

            {this.props.isAuth && (
              <div className={mobileDevice ? "maxWidth10000" : "maxWidth991"}>
                <CloudMenu
                  addNewFile={this._addNewFile}
                  isAuth={this.props.isAuth}
                  sub={this.state.sub}
                  activeIndex={this.state.activeIndex}
                  filesList={filesObject} //this.state.filesList
                  delete={this._delete}
                  editElement={this._editElement.bind(this)}
                  editFile={this._editFile}
                  _addNotification={this.props._addNotification}
                  filesManipulation={this._filesManipulation}
                  searchText={this._searchText}
                  searchBox={this.state.search}
                  tag={arrTags}
                  _setTag={this._setTag}
                  selectedTag={this.state.tag}
                  handleLayoutClick={this.handleLayoutClick}
                  activeLayout={this.state.activeLayout}
                  ram={this.state.ram}
                  ramAction={this.state.ramAction}
                  CutOrCopy={this._CutOrCopy}
                  paste={this._paste}
                  checkFileExist={this._checkFileExist}
                  selectFile={this.state.selectFile}
                  setSelectFiles={this._setSelectFiles}
                  controlWindow={this.props.controlWindow}
                  gotoMobile={this._gotoUrlMobile}
                  closeDesktopWindows={this.props.closeDesktopWindows}
                  mode="mobile"
                />
              </div>
            )}
            <Divider hidden clearing />

            <div className="clearfix">
              <NavBreadcrumbs goto={this._gotoUrl} />

              {this.state.activeLayout === "block" && (
                <div className="sortBy">
                  Sort by:{" "}
                  <Dropdown
                    upward
                    floating
                    inline
                    options={sortByOptions}
                    defaultValue="name"
                    onChange={this._sortBy}
                  />
                </div>
              )}
            </div>

            {this.state.activeLayout === "block" && (
              <div className="mainLayout clearfix">
                {Object.keys(filesObject).map((file) => {
                  return filesObject[file].hasOwnProperty("element") &&
                    filesObject[file].element === "ad" ? (
                    this.props.children
                  ) : (
                    <File
                      key={filesObject[file]._id}
                      delay={!this.state.noDelay ? (delay = delay + 0.04) : 0}
                      objectElement={filesObject}
                      objectNumber={file}
                      goto={this._gotoUrl}
                      gotoMobile={this._gotoUrlMobile}
                      selectFile={this.selectFileClick}
                      activeIndex={this.state.activeIndex}
                      activeLayout={this.state.activeLayout}
                      controlWindow={this.props.controlWindow}
                      orientation={this.state.orientation}
                    />
                  );
                })}

                {littleCountBlock && this.props.children}
              </div>
            )}

            {this.state.activeLayout === "list" && (
              <div className="mainLayout clearfix">
                <Table
                  singleLine
                  striped
                  verticalAlign="middle"
                  className="listLayoutTable"
                >
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell colSpan="2" className="tinyCell">
                        <Button
                          name="sort-name"
                          value="name"
                          onClick={this._sortBy}
                          labelPosition="right"
                          icon
                          className="btnSort"
                        >
                          Name{" "}
                          <Icon
                            name={
                              this.state.sortBy === "linkname"
                                ? this.state.sortAsc
                                  ? "arrow down"
                                  : "arrow up"
                                : ""
                            }
                          />
                        </Button>
                      </Table.HeaderCell>
                      <Table.HeaderCell className="smallCell">
                        <Button
                          name="sort-date"
                          value="date"
                          onClick={this._sortBy}
                          labelPosition="right"
                          icon
                          className="btnSort"
                        >
                          Date{" "}
                          <Icon
                            name={
                              this.state.sortBy === "date"
                                ? this.state.sortAsc
                                  ? "arrow down"
                                  : "arrow up"
                                : ""
                            }
                          />
                        </Button>
                      </Table.HeaderCell>
                      <Table.HeaderCell className="smallCell">
                        <Button
                          name="sort-size"
                          value="size"
                          onClick={this._sortBy}
                          labelPosition="right"
                          icon
                          className="btnSort"
                        >
                          Size{" "}
                          <Icon
                            name={
                              this.state.sortBy === "size"
                                ? this.state.sortAsc
                                  ? "arrow down"
                                  : "arrow up"
                                : ""
                            }
                          />
                        </Button>
                      </Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {Object.keys(filesObject).map((file) => {
                      return filesObject[file].hasOwnProperty("element") &&
                        filesObject[file].element === "ad" ? (
                        <Table.Row key={filesObject[file]._id}>
                          <Table.Cell colSpan="4">
                            {this.props.children}
                          </Table.Cell>
                        </Table.Row>
                      ) : (
                        <File
                          key={filesObject[file]._id}
                          delay={
                            !this.state.noDelay ? (delay = delay + 0.1) : 0
                          }
                          objectElement={filesObject}
                          objectNumber={file}
                          goto={this._gotoUrl}
                          gotoMobile={this._gotoUrlMobile}
                          selectFile={this.selectFileClick}
                          activeIndex={this.state.activeIndex}
                          activeLayout={this.state.activeLayout}
                          controlWindow={this.props.controlWindow}
                          orientation={this.state.orientation}
                        />
                      );
                    })}

                    {littleCountList && (
                      <Table.Row key="googleads">
                        <Table.Cell colSpan="4">
                          {this.props.children}
                        </Table.Cell>
                      </Table.Row>
                    )}
                  </Table.Body>
                </Table>
              </div>
            )}
          </div>
        )}
        {this.state.page404 && <PageNotFound />}
      </Container>
    );
  }
}
