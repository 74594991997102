import React, { Component } from "react";
import { Tab } from "semantic-ui-react";
import BeforeAfterSlider from "react-before-after-slider";
import { Link } from "react-router-dom";
import { sitePrimaryDomain, siteTitle } from "../js/functions";
import before from "../images/before.png";
import after from "../images/after.png";
import before400 from "../images/before-400.png";
import after400 from "../images/after-400.png";
import responsiveDesign from "../images/responsive.png";
import laptop from "../images/laptop.png";

export default class introText extends Component {
  state = { activeIndex: 0 };

  handleRangeChange = (e) => this.setState({ activeIndex: e.target.value });
  handleTabChange = (e, { activeIndex }) => this.setState({ activeIndex });

  render() {
    const { activeIndex } = this.state;

    if (activeIndex === 1) {
      this.props.darkMode(true);
    } else {
      this.props.darkMode(false);
    }

    const panes = [
      {
        menuItem: {
          key: "startup-page",
          as: "button",
          content: "Startup page",
          className: "pointer",
        },
        render: () => (
          <Tab.Pane>
            <img
              src={laptop}
              className="responsiveImage"
              width="970"
              height="591"
              alt=""
            />
            <p>
              Using our macOS application or Windows Program you can open{" "}
              {sitePrimaryDomain} automatically on{" "}
              <Link to="/startup">computer startup</Link> and use it as startup
              page, you can also download our{" "}
              <Link to="/extension">browser extension</Link> to set{" "}
              {sitePrimaryDomain} as your homepage.
            </p>
          </Tab.Pane>
        ),
      },
      {
        menuItem: {
          key: "dark-appearance",
          as: "button",
          content: "Dark appearance",
          className: "pointer",
        },
        render: () => (
          <Tab.Pane>
            <BeforeAfterSlider
              className="beforeAfterSlider maxWidth820"
              before={before400}
              after={after400}
              width={400}
              height={267}
            />
            <BeforeAfterSlider
              className="beforeAfterSlider minWidth821"
              before={before}
              after={after}
              width={800}
              height={533}
            />
            <p>
              <br />
              <br />
              You can choose between the light and the dark appearance of{" "}
              {siteTitle}. The default is the light appearance but you can
              change it by going to your Account page and click on the "Dark
              appearance" trigger.
            </p>
          </Tab.Pane>
        ),
      },
      {
        menuItem: {
          key: "responsive",
          as: "button",
          content: "Responsive",
          className: "pointer",
        },
        render: () => (
          <Tab.Pane>
            <br />
            <br />
            <img src={responsiveDesign} className="responsiveImage" alt="" />
            <p>
              <br />
              <br />
              Our web app is responsive that means that you can open {
                siteTitle
              }{" "}
              from any computer or mobile device that has an internet connection
              for example: Mobile, Tablet and Desktop.
            </p>
          </Tab.Pane>
        ),
      },
    ];

    return (
      <div className="tabsWrap">
        <Tab
          menu={{ secondary: true, pointing: true }}
          panes={panes}
          activeIndex={activeIndex}
          onTabChange={this.handleTabChange}
        />
      </div>
    );
  }
}
