import react, { useState, Suspense } from "react";
import { Sidebar, Menu, Icon, Transition, Divider } from "semantic-ui-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { sitePrimaryDomain } from "../js/functions";
import { faCookie, faPuzzlePiece } from "@fortawesome/free-solid-svg-icons";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  RedditShareButton,
  TumblrShareButton,
  FacebookIcon,
  TwitterIcon,
  LinkedinIcon,
  RedditIcon,
  TumblrIcon,
} from "react-share";

const SideMenu = ({
  toggleVisibility,
  logout,
  isAuth,
  visible,
  shareTitle,
  GoogleAds,
}) => {
  const [share, setShare] = useState(false);

  const toggleShare = () => setShare(!share);

  return (
    <Sidebar
      as={Menu}
      animation="uncover"
      width="wide"
      visible={visible}
      icon="labeled"
      vertical
      inverted
      className="leftSideBar"
    >
      <Menu.Item id="emptybox" name="empty" link={true}>
        <Link onClick={toggleVisibility} to="/">
          <Icon name="" />
          <span>&nbsp;</span>
        </Link>
      </Menu.Item>
      <Menu.Item name="home" link={true}>
        <Link onClick={toggleVisibility} to="/">
          <Icon name="home" />
          <span>Home</span>
        </Link>
      </Menu.Item>
      {isAuth ? (
        <Menu.Item name="account" link={true}>
          <Link onClick={toggleVisibility} to="/account">
            <Icon name="user" /> <span>My Account</span>
          </Link>
        </Menu.Item>
      ) : null}
      <Menu.Item name="pricing" link={true}>
        <Link onClick={toggleVisibility} to="/pricing">
          <Icon name="ticket" /> <span>Pricing</span>
        </Link>
      </Menu.Item>
      <Menu.Item name="extension" link={true}>
        <Link onClick={toggleVisibility} to="/extension">
          <FontAwesomeIcon
            className="FontAwesome"
            icon={faPuzzlePiece}
            size="2x"
          />{" "}
          <span>Extension</span>
        </Link>
      </Menu.Item>
      <Menu.Item name="startup" link={true}>
        <Link onClick={toggleVisibility} to="/startup">
          <Icon name="desktop" /> <span>Startup</span>
        </Link>
      </Menu.Item>
      <Menu.Item name="help" link={true}>
        <Link onClick={toggleVisibility} to="/faq">
          <Icon name="help circle" /> <span>FAQ</span>
        </Link>
      </Menu.Item>
      <Menu.Item name="newspaper" link={true}>
        <Link onClick={toggleVisibility} to="/updates">
          <Icon name="newspaper" /> <span>Updates</span>
        </Link>
      </Menu.Item>
      <Menu.Item name="about me" link={true}>
        <Link onClick={toggleVisibility} to="/about">
          <Icon name="drivers license" /> <span>About</span>
        </Link>
      </Menu.Item>
      <Menu.Item name="contact me" link={true}>
        <Link onClick={toggleVisibility} to="/contact">
          <Icon name="at" /> <span>Contact</span>
        </Link>
      </Menu.Item>
      <Menu.Item name="terms" link={true}>
        <Link onClick={toggleVisibility} to="/terms">
          <Icon name="file text outline" /> <span>Terms</span>
        </Link>
      </Menu.Item>
      <Menu.Item name="privacy" link={true}>
        <Link onClick={toggleVisibility} to="/privacy">
          <Icon name="privacy" /> <span>Privacy Policy</span>
        </Link>
      </Menu.Item>
      <Menu.Item name="cookie" link={true}>
        <Link onClick={toggleVisibility} to="/cookie">
          <FontAwesomeIcon className="FontAwesome" icon={faCookie} size="2x" />{" "}
          <span>Cookie Policy</span>
        </Link>
      </Menu.Item>
      <Menu.Item
        as="button"
        className="no-border pointer"
        name="share"
        onClick={toggleShare}
      >
        <Icon name="share" /> <span>Share</span>
      </Menu.Item>
      <Menu.Item name="share-content" className="share-content">
        <Transition visible={share} animation="scale" duration={500}>
          <div className="share-content-inner">
            <FacebookShareButton
              url={sitePrimaryDomain}
              quote={shareTitle}
              className="share-button"
            >
              <FacebookIcon size={48} round />
            </FacebookShareButton>

            <TwitterShareButton
              url={sitePrimaryDomain}
              quote={shareTitle}
              className="share-button"
            >
              <TwitterIcon size={48} round />
            </TwitterShareButton>

            <LinkedinShareButton
              url={sitePrimaryDomain}
              quote={shareTitle}
              className="share-button"
              windowWidth={750}
              windowHeight={600}
            >
              <LinkedinIcon size={48} round />
            </LinkedinShareButton>

            <RedditShareButton
              url={sitePrimaryDomain}
              quote={shareTitle}
              className="share-button"
              windowWidth={660}
              windowHeight={460}
            >
              <RedditIcon size={48} round />
            </RedditShareButton>

            <TumblrShareButton
              url={sitePrimaryDomain}
              quote={shareTitle}
              className="share-button"
              windowWidth={660}
              windowHeight={460}
            >
              <TumblrIcon size={48} round />
            </TumblrShareButton>

            <Divider hidden clearing />
          </div>
        </Transition>
      </Menu.Item>
      {isAuth && (
        <Menu.Item name="log out" onClick={() => logout()}>
          <Icon name="sign out" /> <span>Log Out</span>
        </Menu.Item>
      )}
      <Divider hidden clearing />
      <Suspense fallback={""}>
        <GoogleAds divide={false} adType={"Text & display ads side bar"} />
      </Suspense>
    </Sidebar>
  );
};

export default SideMenu;
