import React, { Component } from "react";
import {
  Container,
  Header,
  Icon,
  Divider,
  Card,
  Button,
} from "semantic-ui-react";
import { isFirefox, isChrome } from "react-device-detect";
import { domainS3, siteTitle, siteCategory } from "../js/functions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import "../css/pages.css";
import "../css/page-404.css";

export default class About extends Component {
  render() {
    return (
      <div className="about">
        <Container fluid textAlign="center">
          <Divider hidden />
          <Header as="h1">
            <FontAwesomeIcon className="FontAwesome" icon="puzzle-piece" />{" "}
            Extension
          </Header>
          <Divider hidden />
        </Container>

        <Card.Group centered className="page404">
          <Card>
            <Card.Content>
              <Card.Header>
                <Icon name="chrome" /> Chrome
              </Card.Header>
              {isChrome && (
                <Card.Meta className="lblgreen">Your current browser</Card.Meta>
              )}
              <Card.Description>
                Download this extension from chrome web store to open{" "}
                {siteTitle + " " + siteCategory} automatically in your browser.
              </Card.Description>
            </Card.Content>
            <Card.Content extra textAlign="center">
              <Button
                as="a"
                primary
                target="_blank"
                rel="noindex,nofollow"
                href="https://chrome.google.com/webstore/detail/cloud-os-startup-page/lcecbkfeokjfhpdaofhlgmfhijbibkfb"
              >
                Download
              </Button>
            </Card.Content>
          </Card>
          <Card>
            <Card.Content>
              <Card.Header>
                <Icon name="firefox" /> Firefox
              </Card.Header>
              {isFirefox && (
                <Card.Meta className="lblgreen">Your current browser</Card.Meta>
              )}
              <Card.Description>
                Download this extension to open {siteTitle + " " + siteCategory}{" "}
                automatically in your browser.
                <br />
                After downloading this extension to enable it just drag the file
                to your firefox browser.
              </Card.Description>
            </Card.Content>
            <Card.Content extra textAlign="center">
              <Button
                as="a"
                primary
                href={
                  domainS3 +
                  "defaultImages/cloud_os_startup_page-1.0-an%2Bfx.xpi"
                }
              >
                Download
              </Button>
            </Card.Content>
          </Card>
        </Card.Group>

        <Divider hidden />
      </div>
    );
  }
}
