import React, { Component } from 'react';
import { Modal, Header, Divider } from 'semantic-ui-react';

export default class AmazonAds extends Component {
	static defaultProps = {
		modal: false,
		windowWidth: 0,
		windowHeight: 0
	}

	render() {

		let amazonAd = 	<div className="amazonProducts">
							<iframe title="SanDisk Cruzer 128GB USB 2.0" className="amazonItem" marginWidth="0" marginHeight="0" scrolling="no" frameBorder="0" src="https://ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ac&ref=tf_til&ad_type=product_link&tracking_id=mike8383-20&marketplace=amazon&region=US&placement=B00TKFCYP0&asins=B00TKFCYP0&linkId=75dae58d335765bffe2ccc0393f00992&show_border=true&link_opens_in_new_window=true&price_color=333333&title_color=0066c0&bg_color=ffffff"></iframe>
							<iframe title="SanDisk Ultra Fit 128GB USB 3.0" className="amazonItem" marginWidth="0" marginHeight="0" scrolling="no" frameBorder="0" src="https://ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ac&ref=tf_til&ad_type=product_link&tracking_id=mike8383-20&marketplace=amazon&region=US&placement=B01BGTG2A0&asins=B01BGTG2A0&linkId=ba0b11ffb0a6e71b429615a6e30ac908&show_border=true&link_opens_in_new_window=true&price_color=333333&title_color=0066c0&bg_color=ffffff"></iframe>
							<iframe title="WD 2TB Elements Portable External" className="amazonItem" marginWidth="0" marginHeight="0" scrolling="no" frameBorder="0" src="https://ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ac&ref=tf_til&ad_type=product_link&tracking_id=mike8383-20&marketplace=amazon&region=US&placement=B06W55K9N6&asins=B06W55K9N6&linkId=09075e216ada14f5cb7447a26ac6bc21&show_border=true&link_opens_in_new_window=true&price_color=333333&title_color=0066c0&bg_color=ffffff"></iframe>
							<iframe title="WD 4TB Black My Passport" className="amazonItem" marginWidth="0" marginHeight="0" scrolling="no" frameBorder="0" src="https://ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ac&ref=tf_til&ad_type=product_link&tracking_id=mike8383-20&marketplace=amazon&region=US&placement=B01LQQH86A&asins=B01LQQH86A&linkId=eb36ed8ec57826738bd596fa43db5de3&show_border=true&link_opens_in_new_window=true&price_color=333333&title_color=0066c0&bg_color=ffffff"></iframe>
						</div>

		let pageContent = (this.props.windowWidth > 505 && this.props.windowHeight > 430) ? amazonAd : "";

		if(this.props.modal){
			pageContent = 	<Modal className="amazonModal" open={true} size="small" dimmer="blurring" closeIcon onClose={ this.props.close }>
								<Modal.Content>
									<Header size='small'>Download dialog box will show up in a moment.</Header>
									<Divider hidden clearing />
									{ amazonAd }
								</Modal.Content>
							</Modal>;
		}
		return (
			<div>
				{ pageContent }
			</div>
		);
	}
}