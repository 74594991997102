import React, { Component } from 'react';
import Notification from 'react-web-notification';
import { Form, Button, Icon, Divider } from 'semantic-ui-react';
import { siteTitle, sitePrimaryDomain, CloudTextField } from '../js/functions';
import { addUpdatesFeed } from '../components/Api';
import TextField from 'material-ui/TextField';
import notificationIcon from '../images/notification.png';

export default class BrowserNotification extends Component {
	constructor(props) {
		super(props);
		this.state = {
		  ignore: true,
		  title: '',
		  headline: '',
		  content: '',
		  headlineStatus: '',
		  contentStatus: '',
		  disable: false
		};

		this.commonChange = this.commonChange.bind(this);
		this.__validatecaptcha = this._validate.bind(this);
		this.handleButtonClick = this.handleButtonClick.bind(this)
	  }
	
	  handlePermissionGranted(){
		console.log('Permission Granted');
		this.setState({
		  ignore: false
		});
	  }
	  handlePermissionDenied(){
		console.log('Permission Denied');
		this.setState({
		  ignore: true
		});
	  }
	  handleNotSupported(){
		console.log('Web Notification not Supported');
		this.setState({
		  ignore: true
		});
	  }
	
	  handleNotificationOnClick(e, tag){
		window.open(sitePrimaryDomain);
	//	this.n.close(event.target.tag);
		console.log(e, 'Notification clicked tag:' + tag);
	  }
	
	  handleNotificationOnError(e, tag){
		console.log(e, 'Notification error tag:' + tag);
	  }
	
	  handleNotificationOnClose(e, tag){
		console.log(e, 'Notification closed tag:' + tag);
	  }
	
	  handleNotificationOnShow(e, tag){
		console.log(e, 'Notification shown tag:' + tag);
	  }
	
	  handleButtonClick() {
		if(this.state.ignore) {
		  return;
		}
	
		const title = siteTitle;
		const body = this.state.headline;
		const icon = notificationIcon;
		// const icon = 'http://localhost:3000/Notifications_button_24.png';
	
		// Available options
		// See https://developer.mozilla.org/en-US/docs/Web/API/Notification/Notification
		const options = {
		  body: body,
		  icon: icon,
		  lang: 'en',
		  dir: 'ltr',
		  requireInteraction: true
		}
		this.setState({
		  title: title,
		  options: options
		});
	  }
	
	  commonChange = (event) => {
		this.setState({
			[event.target.name]: event.target.value
		});
	 //   console.log("Event name: ", { name: event.target.name, value: event.target.value });
	  }

	  _validate = () => {
		let valid = true;
		let message = {};
	
		this.setState({ disable: true });
		if(this.state.headline === ''){
		  valid = false;
		  message["headlineStatus"] = "This field is required";
		}
	
		if(this.state.content === ''){
		  valid = false;
		  message["contentStatus"] = "This field is required";
		}
	
		if(valid){
			const { auth, show } = this.props;
			if(show && auth.isAuthenticated() && auth.hasOwnProperty("userProfile") && auth.userProfile.hasOwnProperty("sub") && auth.userProfile.sub === "google-oauth2|110890134146877110175"){
				let obj = {
					headline: this.state.headline,
					content: this.state.content,
					date: Date()
				}
				addUpdatesFeed(obj).then(resp => {
					console.log("addUpdatesFeed: ", resp);
					if(resp.docs.result.ok){
						this.handleButtonClick();
						this.setState({
							ignore: false,
							title: '',
							headline: '',
							content: '',
							headlineStatus: '',
							contentStatus: '',
							disable: false
						  });
						this.props._addNotification("success", "Inserted successfully", "");
						this.props._rerender();
					//	window.location.reload();
					} else {
						this.props._addNotification("error", "Error insert data.");
					}
				});
			}
		//  this.captcha.execute();
		} else {
		  message["disable"] = false;
		  this.setState(message);
		  return false;
		}
	  }

	  render() {
		const { auth, show } = this.props;
	//	console.log("Auth: ", auth);
		
		return (
		  <div>

			{(show && auth.isAuthenticated() && auth.hasOwnProperty("userProfile") && auth.userProfile.hasOwnProperty("sub") && auth.userProfile.sub === "google-oauth2|110890134146877110175") && 
				<Form className='attached fluid segment browserNotificationForm' onSubmit={ this._validate }>
					<CloudTextField className="text-field" label="Headline" name="headline" onChange={this.commonChange} value={this.state.headline} helperText={ this.state.headlineStatus } fullWidth={true} />
					<CloudTextField className="text-field" label="Content"  name="content" onChange={this.commonChange} value={this.state.content} multiline minRows={1} maxRows={6} helperText={ this.state.contentStatus } fullWidth={true} />
					<Divider hidden />
					<Button disabled={ this.state.disable } loading={ this.state.disable } color='green'><Icon name='send' /> Submit</Button>
				</Form>
			}

			<Notification
			  ignore={this.state.ignore && this.state.title !== ''}
			  notSupported={this.handleNotSupported.bind(this)}
			  onPermissionGranted={this.handlePermissionGranted.bind(this)}
			  onPermissionDenied={this.handlePermissionDenied.bind(this)}
			  onShow={this.handleNotificationOnShow.bind(this)}
			  onClick={this.handleNotificationOnClick.bind(this)}
			  onClose={this.handleNotificationOnClose.bind(this)}
			  onError={this.handleNotificationOnError.bind(this)}
			  timeout={5000}
			  title={this.state.title}
			  options={this.state.options}
			/>
		  </div>
		)
	  }
}