import React, { Component } from "react";
import {
  Container,
  Grid,
  Header,
  Accordion,
  Icon,
  Button,
  Divider,
  List,
} from "semantic-ui-react";
import {
  isIE,
  isEdge,
  isSafari,
  isFirefox,
  isChrome,
} from "react-device-detect";
import { Link } from "react-router-dom";
import {
  bytesToSize,
  maximumFileSize,
  sitePrimaryDomain,
  siteTitle,
} from "../js/functions";
import "../css/faq.css";

export default class Faq extends Component {
  state = { activeIndex: 0, all: false };

  handleClick = (e, titleProps) => {
    const { index } = titleProps;
    const { activeIndex } = this.state;
    const newIndex = activeIndex === index ? -1 : index;

    this.setState({ activeIndex: newIndex, all: false });
  };

  render() {
    const { activeIndex, all } = this.state;

    let maxFileSize = bytesToSize(maximumFileSize);

    let goodBrowser =
      isIE || isEdge || isSafari || isFirefox || isChrome ? true : false;
    return (
      <div className="faq">
        <Container fluid textAlign="center">
          <Divider hidden />
          <Header as="h1">
            <Icon name="help circle" /> Frequently Asked Questions
          </Header>
          <Divider hidden />

          <Button.Group>
            <Button
              color="blue"
              onClick={() => this.setState({ activeIndex: -1, all: true })}
            >
              Expand All
            </Button>
            <Button.Or />
            <Button
              color="blue"
              onClick={() => this.setState({ activeIndex: -1, all: false })}
            >
              Collapse All
            </Button>
          </Button.Group>
          <Divider hidden />
        </Container>

        <Grid centered padded>
          <Grid.Column mobile={16} tablet={16} computer={8}>
            <Accordion fluid styled>
              <Accordion.Title
                active={activeIndex === 0 || all === true}
                index={0}
                onClick={this.handleClick}
              >
                <Icon name="dropdown" />
                What is {siteTitle} ?
              </Accordion.Title>
              <Accordion.Content active={activeIndex === 0 || all === true}>
                <p>
                  Using {siteTitle} you can store all your favorite things in
                  one place and access it from every where in the world{" "}
                  <Icon name="thumbs outline up" />. It helps you organize your
                  data like Youtube videos, Photos, Websites links, Videos &
                  more... in one place.
                </p>
              </Accordion.Content>

              <Accordion.Title
                active={activeIndex === 1 || all === true}
                index={1}
                onClick={this.handleClick}
              >
                <Icon name="dropdown" />
                How can i start using the {siteTitle} service ?
              </Accordion.Title>
              <Accordion.Content active={activeIndex === 1 || all === true}>
                <p>
                  You{" "}
                  <strong className="underline">don't need to register</strong>{" "}
                  simply log in to our website using one of your social media
                  accounts and start using our service is that simple{" "}
                  <Icon name="smile" />.
                </p>
              </Accordion.Content>

              <Accordion.Title
                active={activeIndex === 2 || all === true}
                index={2}
                onClick={this.handleClick}
              >
                <Icon name="dropdown" />
                What is the maximum file size that i can upload ?
              </Accordion.Title>
              <Accordion.Content active={activeIndex === 2 || all === true}>
                <p>
                  Currently {siteTitle} is a beta version so the maximum file
                  size is: {maxFileSize.size + maxFileSize.unit} but we will
                  increase it in the future versions.
                </p>
              </Accordion.Content>

              <Accordion.Title
                active={activeIndex === 3 || all === true}
                index={3}
                onClick={this.handleClick}
              >
                <Icon name="dropdown" />
                How much does it cost ?
              </Accordion.Title>
              <Accordion.Content active={activeIndex === 3 || all === true}>
                <p>
                  Free plan 250MB or if you need more capacity you can upgrade
                  to a <Link to="/pricing">payed plan</Link>.
                </p>
              </Accordion.Content>

              <Accordion.Title
                active={activeIndex === 4 || all === true}
                index={4}
                onClick={this.handleClick}
              >
                <Icon name="dropdown" />
                Why i can't run the downloaded file that opens {siteTitle} on
                computer startup in MacOS ?
              </Accordion.Title>
              <Accordion.Content active={activeIndex === 4 || all === true}>
                <p>
                  You need to open your <strong>System Preferences</strong>{" "}
                  <Icon name="arrow right" />{" "}
                  <strong>Security & Privacy</strong>{" "}
                  <Icon name="arrow right" /> in the <strong>General</strong>{" "}
                  tab click on the <strong>Open Anyway</strong> button.
                </p>
              </Accordion.Content>
            </Accordion>
          </Grid.Column>

          <Grid.Column mobile={16} tablet={16} computer={8}>
            <Accordion fluid styled>
              <Accordion.Title
                active={activeIndex === 5 || all === true}
                index={5}
                onClick={this.handleClick}
              >
                <Icon name="dropdown" />
                Which files can i upload ?
              </Accordion.Title>
              <Accordion.Content active={activeIndex === 5 || all === true}>
                <p>You can upload almost all file types </p>
                {/* <p>
                          As a beta version you can only upload: images, videos, documents, zip files & mp3. But we will open the option to upload much more file types in the future versions.
                        </p>
                    */}
              </Accordion.Content>

              <Accordion.Title
                active={activeIndex === 6 || all === true}
                index={6}
                onClick={this.handleClick}
              >
                <Icon name="dropdown" />I uploaded files to {siteTitle} but when
                i re-login i can't see them ?
              </Accordion.Title>
              <Accordion.Content active={activeIndex === 6 || all === true}>
                <p>
                  You probebly log in with different social media, please log in
                  using the social media that you uploaded the files.
                  <br />
                  For example if you logged in to out website using the social
                  media Facebook and you uploaded some data next time you need
                  to log in using the same social media (in our example is
                  Facebook) otherwise you won't see the data that you uploaded.
                </p>
              </Accordion.Content>

              {goodBrowser && (
                <div>
                  <Accordion.Title
                    active={activeIndex === 7 || all === true}
                    index={7}
                    onClick={this.handleClick}
                  >
                    <Icon name="dropdown" />
                    How can i set {siteTitle} as homepage ?
                  </Accordion.Title>
                  <Accordion.Content active={activeIndex === 7 || all === true}>
                    {isChrome && (
                      <div>
                        <Header as="h3">
                          <Icon name="chrome" />
                          Chrome
                        </Header>
                        <List as="ol">
                          <List.Item as="li">
                            Click Menu <Icon name="ellipsis vertical" /> in the
                            top right-hand corner of the screen.
                          </List.Item>
                          <List.Item as="li">
                            Select Settings from the drop-down menu that
                            appears.
                          </List.Item>
                          <List.Item as="li">
                            Under the On startup section, click the button next
                            to Open a specific page or set of pages.
                          </List.Item>
                          <List.Item as="li">
                            Next to that, select Set pages.
                          </List.Item>
                          <List.Item as="li">
                            Type{" "}
                            <Link to={sitePrimaryDomain}>
                              {sitePrimaryDomain}
                            </Link>{" "}
                            and press Enter.
                          </List.Item>
                          <List.Item as="li">
                            The site should be added to the list. Select the
                            site and press OK.
                          </List.Item>
                        </List>
                      </div>
                    )}

                    {isSafari && (
                      <div>
                        <Header as="h3">
                          <Icon name="safari" />
                          Safari
                        </Header>

                        <List as="ol">
                          <List.Item as="li">
                            Click Safari in the upper left-hand corner of the
                            screen.
                          </List.Item>
                          <List.Item as="li">
                            Select Preferences from the drop-down menu that
                            appears.
                          </List.Item>
                          <List.Item as="li">
                            Under the General tab in the Home page field, type{" "}
                            <Link to={sitePrimaryDomain}>
                              {sitePrimaryDomain}
                            </Link>
                          </List.Item>
                          <List.Item as="li">
                            Close the current window.
                          </List.Item>
                        </List>
                      </div>
                    )}

                    {isIE && (
                      <div>
                        <Header as="h3">
                          <Icon name="internet explorer" />
                          Internet Explorer
                        </Header>

                        <List as="ol">
                          <List.Item as="li">
                            Click Tools <Icon name="setting" /> in the upper
                            right-hand corner.
                          </List.Item>
                          <List.Item as="li">
                            Select Internet Options from the drop-down menu that
                            appears.
                          </List.Item>
                          <List.Item as="li">
                            Under the General tab, type{" "}
                            <Link to={sitePrimaryDomain}>
                              {sitePrimaryDomain}
                            </Link>
                          </List.Item>
                          <List.Item as="li">Click Apply and Ok</List.Item>
                        </List>
                      </div>
                    )}

                    {isEdge && (
                      <div>
                        <Header as="h3">
                          <Icon name="edge" />
                          Microsoft Edge
                        </Header>

                        <List as="ol">
                          <List.Item as="li">
                            Click the More <Icon name="ellipsis horizontal" />{" "}
                            icon in the upper right-hand corner.
                          </List.Item>
                          <List.Item as="li">
                            Select Settings from the drop-down menu that
                            appears.
                          </List.Item>
                          <List.Item as="li">
                            Under the Open Microsoft Edge with section, select A
                            specific page or pages.
                          </List.Item>
                          <List.Item as="li">
                            Type{" "}
                            <Link to={sitePrimaryDomain}>
                              {sitePrimaryDomain}
                            </Link>
                            and click the <Icon name="save" /> icon.
                          </List.Item>
                          <List.Item as="li">Click Apply and Ok</List.Item>
                        </List>
                      </div>
                    )}

                    {isFirefox && (
                      <div>
                        <Header as="h3">
                          <Icon name="firefox" />
                          Firefox
                        </Header>

                        <List as="ol">
                          <List.Item as="li">
                            Click the Menu <Icon name="bars" /> in the top
                            right-hand corner of the screen.
                          </List.Item>
                          <List.Item as="li">
                            Select Options from the drop-down menu that appears.
                          </List.Item>
                          <List.Item as="li">
                            Under the General Tab in the Home Page field, type{" "}
                            <Link to={sitePrimaryDomain}>
                              {sitePrimaryDomain}
                            </Link>
                          </List.Item>
                          <List.Item as="li">Close the Options tab.</List.Item>
                        </List>
                      </div>
                    )}
                  </Accordion.Content>
                </div>
              )}

              <Accordion.Title
                active={activeIndex === 8 || all === true}
                index={8}
                onClick={this.handleClick}
              >
                <Icon name="dropdown" />
                Do you have browser extension that can open {siteTitle}{" "}
                automatically ?
              </Accordion.Title>
              <Accordion.Content active={activeIndex === 8 || all === true}>
                <p>
                  Yes, you can download it in the{" "}
                  <Link to="/extension">extension page</Link>.
                </p>
              </Accordion.Content>

              <Accordion.Title
                active={activeIndex === 9 || all === true}
                index={9}
                onClick={this.handleClick}
              >
                <Icon name="dropdown" />
                How can i select multiple elements / files ?
              </Accordion.Title>
              <Accordion.Content active={activeIndex === 9 || all === true}>
                <p>
                  You can hold ctrl key + mouse click or shift key + mouse
                  click. To clear selection just click outside the file.
                  <br />
                  On Mobile or Tablet device click in the menu on{" "}
                  <Icon name="checkmark box" /> icon and then tap on the files
                  that you want to select and to clear selection click in the
                  menu on the <Icon name="square outline" /> icon.
                </p>
              </Accordion.Content>
            </Accordion>
          </Grid.Column>
        </Grid>

        <Divider hidden />
      </div>
    );
  }
}
