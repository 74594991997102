import React, { Component } from 'react';
import { Icon, Button } from 'semantic-ui-react';
import { Document, Page } from 'react-pdf';
import { domainS3 } from '../js/functions';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import { pdfjs } from 'react-pdf';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

export default class PdfMobile extends Component{

  state = {
    numPages: null,
    page: 1,
    zoom: 1
  }

  onDocumentLoad = ({ numPages }) => {
    this.setState({ numPages });
    this.props.documentPages(numPages);
    this.props.showDimmer(false);
  }

  _showLoading = () => {
    this.props.showDimmer(true);
  }

  componentDidMount(){
    this._showLoading();
  } 

  handlePrevious = (e) => {
    e.preventDefault();
    this.setState({ page: this.state.page - 1 });
  }

  handleNext = (e) => {
    e.preventDefault();
    this.setState({ page: this.state.page + 1 });
  }

  handleZoomIn = (e) => {
    e.preventDefault();
    let newZoom = this.state.zoom + 0.5;
    this.setState({ zoom: newZoom });
    this.props.documentZoom(newZoom);
 //   console.log("Zoom: " + newZoom);
  }

  handleZoomOut = (e) => {
    e.preventDefault();
    let newZoom = this.state.zoom - 0.5;
    this.setState({ zoom: newZoom });
    this.props.documentZoom(newZoom);
  }

  renderPagination = (page, pages) => {
    let previousButton = <Button icon color='blue' onClick={this.handlePrevious.bind(this)}><Icon name='arrow left' /></Button>;
    if (page === 1) {
      previousButton = <Button icon color='blue' disabled><Icon name='arrow left' /></Button>;
    }
    let nextButton = <Button icon color='blue' onClick={this.handleNext.bind(this)}><Icon name='arrow right' /></Button>;
    if (page === pages) {
      nextButton = <Button icon color='blue' disabled><Icon name='arrow right' /></Button>;
    }
    return (
      
        <ul className="pager">
          <li>{previousButton}</li>
          <li><div className="pageInfo">Page {page} / {pages}</div></li>
          <li>{nextButton}</li>
          <li>&nbsp;&nbsp;&nbsp;&nbsp;</li>
          <li><Button icon color='blue' className="zoomPdf" disabled={ (this.state.zoom - 0.5 > 0) ? false : true }  onClick={this.handleZoomOut.bind(this)}><Icon name='zoom out' size="large" /></Button></li>
          <li><Button icon color='blue' className="zoomPdf" disabled={ (this.state.zoom + 0.5 < 3.5) ? false : true } onClick={this.handleZoomIn.bind(this)}><Icon name='zoom in' size="large" /></Button></li>
        </ul>
     
      );
  }

  _percent = (quantity, percent) => {
      return parseInt(quantity * percent / 100,10);
  }

// https://codepen.io/anon/pen/wjQgRL
// https://codepen.io/iammerrick/pen/BzPprd
// https://stackoverflow.com/questions/48866714/how-to-detect-two-finger-touch-reactjs

  render(){
    let linkurl = (this.props.element.hasOwnProperty("openElement")) ? this.props.element.openElement : this.props.element.linkurl;
    const { numPages, page, zoom } = this.state;
  //  console.log("pdf url: " + domainS3 + linkurl);
    let pagination = null;
    if (numPages !== null) {
      pagination = this.renderPagination(this.state.page, this.state.numPages);
    }
    return(
      <div>
        <Document file={ domainS3 + linkurl } onLoadSuccess={this.onDocumentLoad} loading="" >
        {
     //     Array.from( new Array(numPages), (el, index) => (
              <Page key={`page_${page}`} pageNumber={page} width={(this.state.zoom === 1) ? this._percent(window.innerWidth, 95) : "" } scale={ zoom } />
     //       ),
    //      )
        }
        </Document>
        {pagination}
      </div>
    );
  }
}