import React, { Component } from "react";
import Uppy from "@uppy/core";
import { DashboardModal } from "@uppy/react";
import Dropbox from "@uppy/dropbox";
import Webcam from "@uppy/webcam";
import Url from "@uppy/url";
import DropTarget from "@uppy/drop-target";
import AwsS3Multipart from "@uppy/aws-s3-multipart";
import ImageEditor from "@uppy/image-editor";
import * as Api from "./Api";
import {
  stripSignedURL,
  getFileType,
  checkUnSafeCharacters,
  replaceUnSafeCharacters,
  unsafeCharactersArray,
  maximumFileSize,
  onlinePlayVideoFileTypes,
  onlineConvertVideoFileTypes,
  relogin,
} from "../js/functions";

import "@uppy/core/dist/style.css";
import "@uppy/dashboard/dist/style.css";
import "@uppy/webcam/dist/style.css";
import "@uppy/drop-target/dist/style.css";

export default class Upload extends Component {
  constructor(props) {
    super(props);

    let myHost =
        window.location.hostname === "localhost"
          ? "http://localhost:8080"
          : "https://www.cloudos.cloud", //'https://cloudos5.herokuapp.com',
      uppyFiles = null,
      socialUpload = false,
      available = null;

    this.uppy = new Uppy({
      id: "uppy1",
      debug: false,
      autoProceed: false,
      restrictions: {
        maxFileSize: maximumFileSize,
        maxNumberOfFiles: 10,
        minNumberOfFiles: 1,
        allowedFileTypes: null, //['image/*', 'video/*', 'audio/mp3', 'audio/mpeg', 'application/pdf', 'application/powerpoint', 'application/excel', 'application/msword', 'text/plain', 'application/x-troff-msvideo', 'application/vnd.openxmlformats-officedocument.presentationml.presentation', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document']
      },
      meta: {},
      onBeforeUpload: (files) => {
        Api.checkUsage().then((res) => {
          console.log(res.data.result.available);
          available =
            res.hasOwnProperty("data") &&
            res.data.hasOwnProperty("result") &&
            res.data.result.hasOwnProperty("available")
              ? res.data.result.available
              : 0;
        });

        uppyFiles = files;
        let parent = this,
          sizeCounter = 0;

        console.log("Available Disk Space: ", available);
        console.log("uppyFiles: ", uppyFiles);

        Object.keys(uppyFiles).map(function (objectKey, index) {
          if (
            parent.props.checkFileExist(
              { linkname: files[objectKey].name },
              false
            )
          ) {
            parent.props._addNotification(
              "warning",
              "Attention",
              files[objectKey].name + " already exist."
            );
            delete uppyFiles[objectKey];
            delete files[objectKey];
            return false;
          }

          if (
            files[objectKey].hasOwnProperty("size") &&
            files[objectKey].size !== "" &&
            files[objectKey].size !== "undefined" &&
            files[objectKey].size !== null &&
            available !== null
          ) {
            if (files[objectKey].size + sizeCounter > available) {
              parent.props._addNotification(
                "warning",
                "Attention",
                "Not enough disk space for: " + files[objectKey].name
              );
              delete uppyFiles[objectKey];
              delete files[objectKey];
              return false;
            } else {
              sizeCounter += files[objectKey].size;
            }
          }

          if (
            uppyFiles[objectKey].type === "undefined" ||
            uppyFiles[objectKey].type === undefined ||
            uppyFiles[objectKey].type === null ||
            uppyFiles[objectKey].type.includes("application/")
          ) {
            uppyFiles[objectKey].type = getFileType(uppyFiles[objectKey].name);
            files[objectKey].type = uppyFiles[objectKey].type;
          }
          if (
            uppyFiles[objectKey].source !== "Dashboard" &&
            uppyFiles[objectKey].source !== "Webcam"
          ) {
            socialUpload = true;
          }

          //  console.log(checkUnSafeCharacters(uppyFiles[objectKey].name));
          if (checkUnSafeCharacters(uppyFiles[objectKey].name)) {
            let newName = replaceUnSafeCharacters(files[objectKey].name);
            if (newName.slice(0, newName.lastIndexOf(".")) !== "") {
              files[objectKey].name = newName;
            } else {
              newName =
                "file-" +
                Math.random().toString(32).slice(2) +
                newName.slice(newName.lastIndexOf("."), newName.length);
            }
            parent.props._addNotification(
              "warning",
              "Attention",
              uppyFiles[objectKey].name +
                " was renamed to: '" +
                newName +
                "' because it containes one of the unsafe characters: " +
                unsafeCharactersArray.join("")
            );
          }

          if (
            files[objectKey].name.toLowerCase().slice(-4) === ".txt" ||
            files[objectKey].name.toLowerCase().slice(-3) === ".js"
          ) {
            parent.props._addNotification(
              "warning",
              "Attention",
              "Not allowed file type."
            );
            delete uppyFiles[objectKey];
            delete files[objectKey];
            return false;
          }

          uppyFiles[objectKey].name =
            parent.props.sub + "###" + uppyFiles[objectKey].name;
          return true;
        });
        console.log("*****onBeforeUpload files: ", files);
      },
    })
      //    .use(GoogleDrive, { companionUrl: myHost })
      .use(Dropbox, { companionUrl: myHost })
      .use(Url, { companionUrl: myHost })
      .use(DropTarget, { target: document.body })
      .use(Webcam, {
        onBeforeSnapshot: () => Promise.resolve(),
        countdown: false,
        modes: ["video-audio", "video-only", "audio-only", "picture"],
        mirror: true,
        videoConstraints: {
          facingMode: "user",
          width: { min: 720, ideal: 1280, max: 1920 },
          height: { min: 480, ideal: 800, max: 1080 },
        },
        showRecordingLength: true,
        preferredVideoMimeType: null,
        preferredImageMimeType: null,
        locale: {},
      })
      .use(ImageEditor, {})
      .use(AwsS3Multipart, {
        limit: 5,
        companionUrl: myHost, // 'https://uppy-companion.myapp.net/'
      })
      .on("complete", (result) => {
        console.log("successful files:", result.successful);
        //    console.log('failed files:', result.failed)

        if (result.successful.length) {
          this.uppyUploaded(result.successful);
        }

        if (result.failed.length) {
          for (let a = 0; a < result.failed.length; a++) {
            this.props._addNotification(
              "error",
              "Error",
              result.failed[a].name + " faild to upload"
            );
          }
        }
      })
      .on("file-added", (file) => {
        console.log("Added file", file);
        console.log("uppy.getState(): ", this.uppy.getState());
        let uppyState = this.uppy.getState(),
          allowedFile = true; // = false // comment on 8/8/18

        this.props.showUpload();

        if (!allowedFile) {
          delete uppyState.files[file.id];
          console.log("Transformed uppy state: ", uppyState);
          this.uppy.setState({
            uppyState,
          });
          this.props._addNotification(
            "error",
            "Error",
            file.name + " file type not allowed."
          );
        }
      })
      .on("upload-error", (file, error) => {
        if (
          error
            .toString()
            .includes(
              "TypeError: AwsS3: got incorrect result from 'getUploadParameters()'"
            )
        ) {
          console.log("*** relogin ***");
          relogin();
        }
      });
  }

  uppyUploaded = (files) => {
    let tempArray = [],
      finalFiles = [];

    if (Array.isArray(files)) {
      tempArray = files;
    } else {
      Object.keys(files).map((objectKey) => tempArray.push(files[objectKey]));
    }

    console.log("tempArray: ", tempArray);
    for (let a = 0; a < tempArray.length; a++) {
      let fileType = tempArray[a].type.slice(0, tempArray[a].type.indexOf("/")),
        tempObj = {},
        slicedKey = null,
        originalKey = null;

      if (tempArray[a].hasOwnProperty("s3Multipart")) {
        if (tempArray[a].s3Multipart.hasOwnProperty("key")) {
          slicedKey = tempArray[a].s3Multipart.key.slice(
            tempArray[a].s3Multipart.key.indexOf("/"),
            tempArray[a].s3Multipart.key.length
          ); // "/2018-03-23/14-55-09-788/google-oauth2|110890134146877110175/bv63u8ongt/movie.mp4"
          originalKey = tempArray[a].s3Multipart.key;
        } else {
          let resLocation = tempArray[a].response.body.location;
          slicedKey = resLocation.slice(
            resLocation.indexOf("/Cloud/") + 6,
            resLocation.length
          );
          slicedKey = stripSignedURL(slicedKey);
          originalKey = "Cloud" + slicedKey;
        }
      } else {
        let uploadURL = tempArray[a].uploadURL;
        originalKey = uploadURL.slice(
          uploadURL.indexOf("/Cloud/") + 1,
          uploadURL.length
        );
        slicedKey = originalKey.slice(
          originalKey.indexOf("/"),
          originalKey.length
        ); // "/2018-03-23/14-55-09-788/google-oauth2|110890134146877110175/bv63u8ongt/movie.mp4"
      }

      tempArray[a].name = tempArray[a].name.slice(
        tempArray[a].name.indexOf("###") + 3,
        tempArray[a].name.length
      );

      console.log("slicedKey: ", slicedKey);
      switch (fileType) {
        case "image":
          tempObj = {
            linkname: tempArray[a].name,
            linkurl: originalKey, // "Cloud/2018-03-23/14-55-09-788/google-oauth2|110890134146877110175/bv63u8ongt/pic.jpg"
            linktype: tempArray[a].type,
            extension: tempArray[a].extension,
            source: tempArray[a].source,
            size: tempArray[a].size,
            date: Date(),
            counter: 0,
          };

          if (tempArray[a].hasOwnProperty("width")) {
            tempObj.width = tempArray[a].width;
          }
          if (tempArray[a].hasOwnProperty("height")) {
            tempObj.height = tempArray[a].height;
          }
          if (
            tempArray[a].type === "image/png" ||
            tempArray[a].type === "image/jpg" ||
            tempArray[a].type === "image/jpeg" ||
            tempArray[a].type === "image/gif" ||
            tempArray[a].type === "image/tiff"
          ) {
            tempObj.thumbnailImage = "ImageThumbnail" + slicedKey; // "ImageThumbnail/2018-03-23/14-55-09-788/google-oauth2|110890134146877110175/bv63u8ongt/pic.png"
          }

          finalFiles.push(tempObj);
          break;
        case "video":
          tempObj = {
            linkname: tempArray[a].name,
            linkurl: originalKey, // "Cloud/2018-03-23/14-55-09-788/google-oauth2|110890134146877110175/bv63u8ongt/movie.mp4"
            linktype: tempArray[a].type,
            extension: tempArray[a].extension,
            source: tempArray[a].source,
            size: tempArray[a].size,
            date: Date(),
            counter: 0,
          };
          // video/x-msvideo = .avi
          // video/quicktime = .mov
          // video/x-matroska = .mkv
          //   if(tempArray[a].type === "video/mp4" || tempArray[a].type === "video/x-msvideo" || tempArray[a].type === "video/quicktime"){
          if (
            onlinePlayVideoFileTypes.includes(tempArray[a].type) ||
            onlineConvertVideoFileTypes.includes(tempArray[a].type)
          ) {
            tempObj.thumbnailImage =
              "VideoThumbnail" +
              slicedKey.slice(0, slicedKey.lastIndexOf(".")) +
              ".png"; // "ImageThumbnail/2018-03-23/14-55-09-788/google-oauth2|110890134146877110175/bv63u8ongt/movie.png"
            tempObj.thumbnailVideo =
              "VideoThumbnail" +
              slicedKey.slice(0, slicedKey.lastIndexOf(".")) +
              ".webm"; // "VideoThumbnail/2018-03-23/14-55-09-788/google-oauth2|110890134146877110175/bv63u8ongt/movie.webm"
          }

          finalFiles.push(tempObj);
          break;
        default:
          finalFiles.push({
            linkname: tempArray[a].name,
            linkurl: originalKey, // "Cloud/2018-03-23/14-55-09-788/google-oauth2|110890134146877110175/bv63u8ongt/movie.mp4"
            linktype: tempArray[a].type,
            extension: tempArray[a].extension,
            source: tempArray[a].source,
            size: tempArray[a].size,
            date: Date(),
            counter: 0,
          });
          break;
      }
    }

    //  console.log("finalFiles", finalFiles);
    Api.uploadFile(finalFiles).then((res) => {
      //    console.log("res: ", res);
      this.uppy.reset();
      this.props.close();
      if (res.docs.result.ok) {
        let uploadedFiles = res.docs.ops,
          uploadedFilesIds = res.docs.insertedIds;

        uploadedFiles.map((obj, index) => {
          obj._id = uploadedFilesIds[index];
          this.props.filesManipulation("upload", obj);
          return obj;
        });

        console.log("uploadedFiles: ", uploadedFiles);
        this.props._addNotification(
          "success",
          "File(s) uploaded successfully",
          ""
        );
      } else {
        this.props._addNotification(
          "error",
          "Error",
          "Error uploading file(s)"
        );
      }
    });
  };

  componentWillUnmount() {
    this.uppy.close();
  }

  render() {
    return (
      <DashboardModal
        uppy={this.uppy}
        open={this.props.open}
        plugins={["Url", "Dropbox", "Webcam", "ImageEditor"]} // 'GoogleDrive'
        limit="5"
        metaFields={[{ id: "name", name: "Name", placeholder: "File name" }]}
        onRequestClose={this.props.close}
      />
    );
  }
}
