import React, { Component } from "react";
import {
  Divider,
  Modal,
  Button,
  Icon,
  Header,
  Card,
  Label,
  Message,
} from "semantic-ui-react";
import { getPlans } from "../components/Api"; // , testOnly
import "../css/pricing.css";

export default class Pricing extends Component {
  constructor() {
    super();

    this.state = {
      cards: null,
      noDelay: false,
      loginFirst: false,
    };
  }

  _purchase = () => {
    if (!this.props.isAuth) {
      this._loginFirst();
    }
  };

  _loginFirst = () => {
    this.setState({ loginFirst: true });
  };

  close = () => this.setState({ loginFirst: false });

  componentDidMount() {
    let delay = 0.04;
    getPlans(this.props.isAuth).then((res) => {
      console.log("Data: ", res.data);
      let data = res.data;
      console.log("this.props.isAuth: ", this.props.isAuth);

      let result = null;
      if (data.length === 0) {
        result = <Message color="blue">Plans not available</Message>;
      } else {
        result = data.map((response) => {
          delay += !this.state.noDelay ? delay : 0;
          let delayStyle = this.state.noDelay
            ? {}
            : { animationDelay: delay + "s" };

          let buttonPay = null;

          if (this.props.isAuth) {
            let userId = response.userId;

            if (response.name === "Free") {
              buttonPay = null; // <Button onClick={ () => testOnly() }>Test Only</Button>
            } else {
              let buttonText =
                response.hasOwnProperty("upgrade") && response.upgrade
                  ? "Upgrade Now"
                  : "Subscribe Now";

              //  if(userId === "" || userId === null || typeof userId === "undefined") window.location.href = "/relogin";

              buttonPay = (
                <form
                  action="https://www.paypal.com/cgi-bin/webscr"
                  method="post"
                  target="_top"
                >
                  <input type="hidden" name="cmd" value="_s-xclick" />
                  <input
                    type="hidden"
                    name="hosted_button_id"
                    value={response.buttonId}
                  />
                  <input type="hidden" name="custom" value={userId} />
                  <Button
                    name="submit"
                    color="green"
                    alt="PayPal - The safer, easier way to pay online!"
                  >
                    {buttonText}
                  </Button>
                  <img
                    alt=""
                    border="0"
                    src="https://www.paypalobjects.com/en_US/i/scr/pixel.gif"
                    width="1"
                    height="1"
                  />
                </form>
              );
            }
          } else {
            if (response.price !== 0) {
              buttonPay = (
                <Button color="green" onClick={this._purchase.bind(this)}>
                  Subscribe Now
                </Button>
              );
            }
          }

          return (
            <Card
              className="plan fadein"
              key={response.name}
              style={delayStyle}
              disabled={
                this.props.isAuth &&
                response.hasOwnProperty("disabled") &&
                response.disabled
                  ? "disabled"
                  : ""
              }
            >
              {response.name === "Deluxe" && (
                <Label color="blue" pointing="below" className="popular">
                  Most Popular
                </Label>
              )}
              <Card.Content>
                <Card.Header>
                  <Header size="medium">{response.name}</Header>
                </Card.Header>
                <Card.Meta>
                  <Header size="small">{response.description}</Header>
                </Card.Meta>
                <Card.Description>
                  <strong>{response.currencySymbol + response.price}</strong>
                  <div>monthly</div>
                </Card.Description>
              </Card.Content>
              <Card.Content>
                <div className="ui buttons">{buttonPay}</div>
              </Card.Content>
            </Card>
          );
        });
      }

      this.setState({
        cards: result,
      });
    });
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.profile !== this.props.profile &&
      typeof this.props.profile !== "undefined"
    ) {
      this.componentDidMount();
    }
  }

  _paypal = (e) => {
    e.preventDefault();
    window.open(
      "https://www.paypal.com/il/webapps/mpp/paypal-popup",
      "WIPaypal",
      "toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=yes, resizable=yes, width=1060, height=700"
    );
    return false;
  };

  render() {
    //    console.log(this.props.profile);
    return (
      <div className="pricing">
        <Modal
          dimmer="blurring"
          size="mini"
          open={this.state.loginFirst}
          onClose={this.close}
        >
          <Modal.Header>Please Log In First</Modal.Header>
          <Modal.Content>
            <p>In order to subscribe this plan you need to be logged in.</p>
          </Modal.Content>
          <Modal.Actions>
            <Button negative onClick={this.close}>
              Cancel
            </Button>
            <Button positive onClick={this.props.login.bind(this)}>
              Log In
            </Button>
          </Modal.Actions>
        </Modal>

        <Divider hidden clearing />

        <div className="pricingColumn">
          <Header as="h1" textAlign="center">
            <Icon name="ticket" />
            Pick a pricing plan
          </Header>

          <Card.Group className="plansWrap">{this.state.cards}</Card.Group>
        </div>

        <table
          className="paypalBanner"
          border="0"
          cellPadding="10"
          cellSpacing="0"
          align="center"
        >
          <tbody>
            <tr>
              <td align="center"></td>
            </tr>
            <tr>
              <td align="center">
                <a
                  href="https://www.paypal.com/il/webapps/mpp/paypal-popup"
                  rel="noindex, nofollow"
                  title="How PayPal Works"
                  onClick={this._paypal.bind(this)}
                >
                  <img
                    src="https://www.paypalobjects.com/webstatic/mktg/Logo/AM_mc_vs_ms_ae_UK.png"
                    border="0"
                    alt="PayPal Acceptance Mark"
                  />
                </a>
              </td>
            </tr>
          </tbody>
        </table>

        <Divider hidden clearing />
        <Divider hidden clearing />
        <Divider hidden clearing />
      </div>
    );
  }
}
