import React, { Component } from "react";
import {
  Container,
  Grid,
  Header,
  Icon,
  Divider,
  Image,
  Reveal,
} from "semantic-ui-react";
import { Link } from "react-router-dom";
import { siteTitle, siteCategory } from "../js/functions";
import imageColor from "../images/me-c.jpg";
import imageBlackWhite from "../images/me-bw.jpg";
import imageCloudIdea from "../images/cloud-idea.jpg";

import "../css/pages.css";

export default class About extends Component {
  render() {
    return (
      <div className="about">
        <Container fluid textAlign="center">
          <Divider hidden />
          <Header as="h1">
            <Icon name="drivers license" /> About
          </Header>
          <Divider hidden />
        </Container>
        <Grid centered padded>
          <Grid.Column mobile={16} tablet={16} computer={8}>
            <Header as="h2" textAlign="center">
              About {siteCategory}
            </Header>
            <Image
              src={imageCloudIdea}
              size="small"
              circular
              bordered
              className="img"
              color="blue"
            />
            With {siteTitle} you can store all your favorite things in one place
            and access it from every where in the world. It helps you organize
            your data: Youtube videos, Photos, Websites links, Videos and much
            more... in one place with easy access. You don't need to register
            just click on log in button (in upper right corner) and choose your
            favorite social media that you want to log in with.
            <br />
            {siteTitle} currently is in beta.
            <br />
            <br />
            If you have any suggestions about {siteTitle +
              " " +
              siteCategory}{" "}
            fill free to <Link to="/contact">contact me</Link>.<br />
            <br />
            <strong>{siteTitle} created using:</strong> HTML5, CSS3, LESS,
            Javascript, React, Node.js, MongoDB, Semantic UI, AWS S3, AWS
            Lambda, Photoshop, Illustrator.
          </Grid.Column>

          <Grid.Column mobile={16} tablet={16} computer={8}>
            <Header as="h2" textAlign="center">
              About Me
            </Header>
            <Reveal animated="fade">
              <Reveal.Content visible>
                <Image
                  src={imageColor}
                  size="small"
                  circular
                  bordered
                  className="img"
                />
              </Reveal.Content>
              <Reveal.Content hidden>
                <Image
                  src={imageBlackWhite}
                  size="small"
                  circular
                  bordered
                  className="img"
                />
              </Reveal.Content>
            </Reveal>
            Hello, my name is Michael. i'm Freelance Full-Stack Web Developer
            and i'm the creator of {siteTitle + " " + siteCategory}. I was born
            on 8 march 1983 in Kiev, Ukraine.
            <br />
            Currently living in Israel.
            <br />
            <br />
            <strong>Skills:</strong> Javascript, React, Node.js, MongoDB,
            jQuery, HTML5, CSS3, LESS, Bootstrap, Semantic UI, PHP, MySQL, Adobe
            Photoshop, Adobe Animate, Autodesk Maya.
            <br />
            <br />
            <strong>
              My Portfolio:{" "}
              <Link to="https://www.EliteWebmaster.com" target="_blank">
                EliteWebmaster.com
              </Link>
            </strong>
            <br />
            If you have any suggestions fill free to{" "}
            <Link to="/contact">contact me</Link>. <br />
            <br />
          </Grid.Column>
        </Grid>

        <Divider hidden />
      </div>
    );
  }
}
