import React, { Component } from 'react';
import { Modal, Icon, Divider, Button, Accordion, Header, List } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { domainS3, sitePrimaryDomain, siteTitle } from '../js/functions';
import { isIE, isEdge, isSafari, isFirefox, isChrome } from 'react-device-detect';
import { FacebookShareButton, LinkedinShareButton, TwitterShareButton, RedditShareButton, TumblrShareButton, FacebookIcon, TwitterIcon, LinkedinIcon, RedditIcon, TumblrIcon } from 'react-share';

export default class LikeWebsite extends Component {
  state = { activeIndex: 0 }

  handleClick = (e, titleProps) => {
    const { index } = titleProps;
    const { activeIndex } = this.state;
    const newIndex = activeIndex === index ? -1 : index;

    this.setState({ activeIndex: newIndex });
  }

  _detectOSName = () => {
    let osName = null;

    if (window.navigator.userAgent.indexOf("Mac") !== -1) osName="macos";
    else if (window.navigator.userAgent.indexOf("Windows") !== -1) osName="windows";

    return osName;
  }

	componentDidMount() {	
		  let { auth, modal, likeWebsite } = this.props;

      if(auth && modal === true){
        this.likeWebsiteTimeout = setTimeout(() => { 
        if(modal === true) {
          likeWebsite("show");

          if(this._detectOSName() === null && (isChrome || isFirefox)){
            this.setState({ activeIndex: 1 });
          }
          else if(this._detectOSName() === null && isChrome === false && isFirefox === false){
            this.setState({ activeIndex: 2 });
          }
          
        }
        }, 300000); // 300000 = 5 minute
      }
	}
	
  componentWillUnmount() {
    if(typeof this.likeWebsiteTimeout !== "undefined" && this.likeWebsiteTimeout !== "undefined") {
      clearTimeout(this.likeWebsiteTimeout);
    }
  }


	render() {
    let { modal, shareTitle, likeWebsite }= this.props;
    let { activeIndex } = this.state;
    let goodBrowser = (isIE || isEdge || isSafari || isFirefox || isChrome) ? true : false;
    let osName = this._detectOSName();

	  return (
		<div>
        {
          (modal === "share") &&
            <Modal className="likeWebsiteShareWrap" size="mini" dimmer={true} open={true} onClose={() => likeWebsite(true)}>
              <Modal.Header><Icon name="share" /> Share</Modal.Header>
              <Modal.Content>
                <Modal.Description>
                <div className="share-content-inner">
                  <FacebookShareButton url={sitePrimaryDomain} quote={shareTitle} className="share-button">
                    <FacebookIcon size={48} round />
                  </FacebookShareButton>

                  <TwitterShareButton url={sitePrimaryDomain} quote={shareTitle} className="share-button">
                    <TwitterIcon size={48} round />
                  </TwitterShareButton>

                  <LinkedinShareButton url={sitePrimaryDomain} quote={shareTitle} className="share-button" windowWidth={750} windowHeight={600}>
                    <LinkedinIcon size={48} round />
                  </LinkedinShareButton>

                  <RedditShareButton url={sitePrimaryDomain} quote={shareTitle} className="share-button" windowWidth={660} windowHeight={460}>
                    <RedditIcon size={48} round />
                  </RedditShareButton>

                  <TumblrShareButton url={sitePrimaryDomain} quote={shareTitle} className="share-button" windowWidth={660} windowHeight={460}>
                    <TumblrIcon size={48} round />
                  </TumblrShareButton>

                  <Divider hidden clearing />
                </div>
                </Modal.Description>
              </Modal.Content>
              <Modal.Actions>
                <Button color='red' onClick={() => likeWebsite(true)}>
                  Close
                </Button>
              </Modal.Actions>
            </Modal>
        }

        {
          (modal === "homepage") &&
            <Modal className="likeWebsiteShareWrap faq" size="mini" dimmer={true} open={true} onClose={() => likeWebsite(true)}>
              <Modal.Header><Icon name="home" /> Make { siteTitle } Your Homepage</Modal.Header>
              <Modal.Content>
                <Modal.Description>

                    <Accordion fluid styled>
                      { (osName !== null) && 
                        <div>
                          <Accordion.Title active={activeIndex === 0} index={0} onClick={this.handleClick}>
                              <Icon name='dropdown' />
                              Open on computer startup
                            </Accordion.Title>
                            <Accordion.Content active={activeIndex === 0}>
                            { (osName === "macos") && 
                              <p>
                                  Download this app to open { sitePrimaryDomain } when macOS startup.<br />
                                  <strong>If you can't run the downloaded file you need to open your System Preferences <Icon name="arrow right" /> Security & Privacy <Icon name="arrow right" /> in the General tab click on the Open Anyway button.</strong><br /><br />
                                  <Button as='a' href={domainS3 + "defaultImages/MacOS-CloudOS.zip"} primary>
                                     Download
                                  </Button>
                              </p>
                            }

                            { (osName === "windows") && 
                              <p>
                                Download this program to open { sitePrimaryDomain } when windows startup.<br /><br />
                                <Button as='a' href={domainS3 + "defaultImages/Win-CloudOS.zip"} primary>
                                  Download
                                </Button>
                              </p>
                            }
                          </Accordion.Content>
                        </div>
                      }

                      { (isChrome || isFirefox) && 
                        <div>
                          <Accordion.Title active={activeIndex === 1} index={1} onClick={this.handleClick}>
                            <Icon name='dropdown' />
                            Download browser extension
                          </Accordion.Title>
                          <Accordion.Content active={activeIndex === 1}>
                            Automatically set { sitePrimaryDomain } as hompage by download browser extension.<br /><br />

                            { (isChrome) && 
                              <Button as="a" primary target="_blank" rel="noindex,nofollow" href="https://chrome.google.com/webstore/detail/cloud-os-startup-page/lcecbkfeokjfhpdaofhlgmfhijbibkfb">
                                Download
                              </Button>
                            }

                            { (isFirefox) && 
                              <Button as="a" primary href={domainS3 + "defaultImages/cloud_os_startup_page-1.0-an%2Bfx.xpi"}>
                                Download
                              </Button>
                            }
                          </Accordion.Content>
                        </div>
                      }

                      {goodBrowser && 
                        <div>
                            <Accordion.Title active={activeIndex === 2} index={2} onClick={this.handleClick}>
                              <Icon name='dropdown' />
                              Manually change your homepage
                            </Accordion.Title>
                            <Accordion.Content active={activeIndex === 2}>
                              Manually set { sitePrimaryDomain } as your homepage.<br /><br />
                            { (isChrome) && 
                            <div>
                              <Header as="h3"><Icon name="chrome" />Chrome</Header>
                                <List as='ol'>
                                  <List.Item as='li'>Click Menu <Icon name="ellipsis vertical" /> in the top right-hand corner of the screen.</List.Item>
                                  <List.Item as='li'>Select Settings from the drop-down menu that appears.</List.Item>
                                  <List.Item as='li'>Under the On startup section, click the button next to Open a specific page or set of pages.</List.Item>
                                  <List.Item as='li'>Next to that, select Set pages.</List.Item>
                                  <List.Item as='li'>Type <Link to={ sitePrimaryDomain }>{ sitePrimaryDomain }</Link> and press Enter.</List.Item>
                                  <List.Item as='li'>The site should be added to the list. Select the site and press OK.</List.Item>
                                </List>
                            </div>
                            }

                            { (isSafari) && 
                            <div>
                              <Header as="h3"><Icon name="safari" />Safari</Header>

                                <List as='ol'>
                                  <List.Item as='li'>Click Safari in the upper left-hand corner of the screen.</List.Item>
                                  <List.Item as='li'>Select Preferences from the drop-down menu that appears.</List.Item>
                                  <List.Item as='li'>Under the General tab in the Home page field, type <Link to={ sitePrimaryDomain }>{ sitePrimaryDomain }</Link></List.Item>
                                  <List.Item as='li'>Close the current window.</List.Item>
                                </List>

                            </div>
                            }

                            { (isIE) && 
                            <div>
                              <Header as="h3"><Icon name="internet explorer" />Internet Explorer</Header>

                                <List as='ol'>
                                  <List.Item as='li'>Click Tools <Icon name="setting" /> in the upper right-hand corner.</List.Item>
                                  <List.Item as='li'>Select Internet Options from the drop-down menu that appears.</List.Item>
                                  <List.Item as='li'>Under the General tab, type <Link to={ sitePrimaryDomain }>{ sitePrimaryDomain }</Link></List.Item>
                                  <List.Item as='li'>Click Apply and Ok</List.Item>
                                </List>
      
                            </div>
                            }

                            { (isEdge) && 
                            <div>
                              <Header as="h3"><Icon name="edge" />Microsoft Edge</Header>

                                <List as='ol'>
                                  <List.Item as='li'>Click the More <Icon name="ellipsis horizontal" /> icon in the upper right-hand corner.</List.Item>
                                  <List.Item as='li'>Select Settings from the drop-down menu that appears.</List.Item>
                                  <List.Item as='li'>Under the Open Microsoft Edge with section, select A specific page or pages.</List.Item>
                                  <List.Item as='li'>Type <Link to={ sitePrimaryDomain }>{ sitePrimaryDomain }</Link>and click the <Icon name="save" /> icon.</List.Item>
                                  <List.Item as='li'>Click Apply and Ok</List.Item>
                                </List>

                            </div>
                            }

                            { (isFirefox) && 
                              <div>
                                <Header as="h3"><Icon name="firefox" />Firefox</Header>

                                  <List as='ol'>
                                    <List.Item as='li'>Click the Menu <Icon name="bars" /> in the top right-hand corner of the screen.</List.Item>
                                    <List.Item as='li'>Select Options from the drop-down menu that appears.</List.Item>
                                    <List.Item as='li'>Under the General Tab in the Home Page field, type <Link to={ sitePrimaryDomain }>{ sitePrimaryDomain }</Link></List.Item>
                                    <List.Item as='li'>Close the Options tab.</List.Item>
                                  </List>

                              </div>
                            }
                          
                            </Accordion.Content>
                          </div>
                        }
                      </Accordion>
                </Modal.Description>
              </Modal.Content>
              <Modal.Actions>
                <Button color='red' onClick={() => likeWebsite(true)}>
                  Close
                </Button>
              </Modal.Actions>
            </Modal>
        }
		</div>
	  )
	}
}




