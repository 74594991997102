import React, { Component } from "react";
import { Grid, Header, Image } from "semantic-ui-react";
import { siteTitle, siteCategory } from "../js/functions";
import firstImage from "../images/svg/satellite.svg";
import secondeImage from "../images/svg/rocket2.svg";

export default class introText extends Component {
  render() {
    return (
      <div className="introTextComponentWrap">
        <div className="topClouds"></div>

        <Grid className="introTextComponent" verticalAlign="middle">
          <Grid.Row>
            <Grid.Column mobile={16} tablet={6} computer={6}>
              <Image
                width="300"
                height="300"
                src={firstImage}
                size="medium"
                centered
                alt={"Using " + siteTitle + " " + siteCategory}
              />
            </Grid.Column>
            <Grid.Column mobile={16} tablet={10} computer={10}>
              <Header as="h3" textAlign="center">
                Using {siteTitle} {siteCategory}
              </Header>
              <p>
                Living in a fast-paced world such as we have today requires
                having productivity tools, like {siteTitle} web app, to simplify
                things. {siteTitle} is a web-based cloud storage online
                application that allows you organise and keep all your favourite
                stuff in one place, so you have access to them wherever and
                whenever you want.
                <br />
                <br />
                No need to be scared of losing your precious photos, music or
                video clips, and document files when you lose your mobile
                device, or you computer crashes. You don’t have to memorize
                those important websites or titbits of information, just store
                everything in our web app and access them whenever you want.
              </p>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column
              mobile={16}
              tablet={6}
              computer={6}
              className="maxWidth767"
            >
              <Image
                width="300"
                height="300"
                src={secondeImage}
                size="medium"
                centered
                alt="Main Features"
              />
            </Grid.Column>
            <Grid.Column mobile={16} tablet={10} computer={10}>
              <Header as="h3" textAlign="center">
                Main Features
              </Header>
              <p>
                <strong>No Registration Required:</strong> Using our online
                product is very easy; simply log in to the site using your
                favourite social account, and you’re good to go - no new
                username or password to remember.
                <br />
                <br />
                <strong>Get to Your Files from Anywhere:</strong> Picture
                yourself travelling on a long journey or maybe stuck in traffic,
                you can kill the boredom by reaching for your favourite
                playlist. Whether you’re on a beach in California or hiking in
                Canada’s Banff National Park, you can access your important
                data.
                <br />
                <br />
                <strong>Various File Types Supported:</strong> You can upload
                almost all file types to our cloud storage and have unhindered
                access at any time.
                <br />
                <br />
                <strong>Easy to Navigate Site:</strong> Our web app is
                responsive, user-friendly and easy to use from your Mac, PC,
                Tablet or mobile device.
                <br />
                <br />
                <strong>Startup Page:</strong> Instead of your boring homepage
                you can use our web app as your browsers default Homepage.
                <br />
                <br />
                There’s no better time, than now, to start enjoying the
                convenience that this cloud storage gives.
              </p>
            </Grid.Column>
            <Grid.Column
              mobile={16}
              tablet={6}
              computer={6}
              className="minWidth768"
            >
              <Image
                width="300"
                height="300"
                src={secondeImage}
                size="medium"
                centered
                alt="Main Features"
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>

        <div className="bottomClouds"></div>
      </div>
    );
  }
}
