import React, { Component } from 'react';
import ReactPlayer from 'react-player';
import { convertVideo, checkIfExist, updateOpenElement } from './Api';
import { domainS3 } from '../js/functions';
import { Loader, Dimmer } from 'semantic-ui-react';

export default class ConvertVideo extends Component{
  static defaultProps = {
    mobile: false
  }

  constructor(props){
    super(props);
    this.state = ({
      file: null,
      try: 0
    });
  }

  _videoPlayer(url){
    return (this.props.mobile) ? <div className='ratio'><ReactPlayer url={ domainS3 + url } className='react-player' playing controls /></div> 
                               : <div className='player-wrapper video-player'><ReactPlayer url={ domainS3 + url } className='react-player' playing controls width='100%' height='100%' /></div>;
  }

  _checkTimeoutFile(id, url) {
    let { showDimmer, fileDownloadWrap } = this.props;

    console.log(url);

      checkIfExist(url).then(res => {
        if(res.data.file){
          clearInterval(this.state.interval);
          this.setState({
            file: this._videoPlayer(url) 
          });
          showDimmer(false);
          updateOpenElement(id, url);
        } else {
          if(this.state.try >= 3) {
            clearInterval(this.state.interval);  
            this.setState({
              file: fileDownloadWrap("Unable to play this video, You can download and preview locally.")
            });
            showDimmer(false);        
          } else {
            this.setState({
              try: this.state.try + 1
            });  
          }
        }
      })
        
  }


  componentDidMount(){
    let { element, showDimmer } = this.props;
    var url = decodeURIComponent(element.linkurl);
    let urlArr = url.split("/");
    urlArr[0] = "fileConverter";
    let filename = urlArr[urlArr.length - 1];
    urlArr[urlArr.length - 1] = filename.slice(0, filename.lastIndexOf(".")) + ".mp4";
    url = urlArr.join("/");

    console.log("*** Convert Video File ***");
    showDimmer(true);

    checkIfExist(url).then(response => {
      if(response.data.file){
        this.setState({
          file: this._videoPlayer(url)
        });
        showDimmer(false);
      } 
      else {
        showDimmer(true, true, "Please wait, converting video to play online.");
        convertVideo(element).then(res => {
          console.log("convertVideo result: ",res);
          if(typeof res !== "undefined" && res.hasOwnProperty("ok") && res.hasOwnProperty("openElement") && res.ok && res.openElement !== ""){
            this.setState({
              file: this._videoPlayer(res.openElement)
            })
            showDimmer(false);
          }
          else {
            let parent = this;
            this._checkTimeoutFile(element._id, url);
            let interval = setInterval(function(){
              parent._checkTimeoutFile(element._id, url);
            }, 60000); // 1 Minute
            this.setState({interval: interval});
          }
        }); 
      }
    });
  }

  render(){
    const { mobile } = this.props;
    const { file } = this.state;
    const message = <Dimmer active inverted><Loader size='massive'>Loading</Loader></Dimmer>;

    return((mobile && file === null) ? message : file);
  }
}