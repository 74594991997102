import React, { Component } from "react";
import { Divider, Grid, Header, Container, Icon } from "semantic-ui-react";
import { fetchUpdates } from "../components/Api";
import BrowserNotification from "../components/BrowserNotification";

import "../css/pages.css";
import "../css/updates.css";

export default class Updates extends Component {
  state = {
    data: null,
  };

  componentDidMount() {
    fetchUpdates().then((res) => {
      let result = res.docs;
      let data = null;

      const monthNames = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];

      if (result.length < 1) {
        console.log("No Updates");
        data = (
          <Header as="h2" className="noUpdates">
            No Updates
          </Header>
        );
      } else {
        data = result.map((element) => {
          let d = new Date(element.date),
            month = monthNames[d.getMonth()],
            year = d.getFullYear(),
            num = d.getDate();

          return (
            <Container key={element._id} fluid className="feed">
              <Header as="h2">{element.headline}</Header>
              <span className="updateDate">
                {num + " " + month + ", " + year}
              </span>
              <p>{element.content}</p>
            </Container>
          );
        });
      }

      this.setState({ data: data });
      console.log(res);
    });
  }

  _rerender = () => {
    this.componentDidMount();
  };

  render() {
    return (
      <div className="updates">
        <Divider hidden clearing />
        <Grid centered>
          <Grid.Column mobile={15} tablet={15} computer={8}>
            <Container fluid textAlign="center" className="updatesElement">
              <Header as="h1">
                <Icon name="newspaper" />
                Updates
              </Header>
            </Container>
            <Divider hidden clearing />

            <BrowserNotification
              auth={this.props.auth}
              show={true}
              _addNotification={this.props._addNotification}
              _rerender={this._rerender}
            />

            <div className="updates-feeds">{this.state.data}</div>
          </Grid.Column>
        </Grid>
      </div>
    );
  }
}
