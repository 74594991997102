import React, { Component } from "react";
import { Table } from "semantic-ui-react";
import { isTablet, isMobile } from "react-device-detect";
import Truncate from "react-truncate";
import * as Api from "./Api";
import {
  bytesToSize,
  formatDate,
  domainS3,
  websiteScreenshot,
} from "../js/functions";

import gameIcon from "../images/icons/game4.png";
import hi_gameIcon from "../images/icons/hi-game4.png";
import websiteIcon from "../images/icons/website2.png";
import hi_websiteIcon from "../images/icons/hi-website2.png";
import webappIcon from "../images/icons/webapp.png";
import hi_webappIcon from "../images/icons/hi-webapp.png";
import folderIcon from "../images/icons/folder3.png";
import hi_folderIcon from "../images/icons/hi-folder3.png";
import xvideosIcon from "../images/icons/xvideos.png";
import pornhubIcon from "../images/icons/pornhub.png";
import hi_pornhubIcon from "../images/icons/hi-pornhub.png";
import videoIcon from "../images/icons/movie.png";
import hi_videoIcon from "../images/icons/hi-movie.png";
import imageIcon from "../images/icons/image.png";
import hi_imageIcon from "../images/icons/hi-image.png";
import musicIcon from "../images/icons/music2.png";
import hi_musicIcon from "../images/icons/hi-music2.png";
import docIcon from "../images/icons/doc.png";
import hi_docIcon from "../images/icons/hi-doc.png";
import pdfIcon from "../images/icons/pdf.png";
import hi_pdfIcon from "../images/icons/hi-pdf.png";
import pptIcon from "../images/icons/ppt.png";
import hi_pptIcon from "../images/icons/hi-ppt.png";
import txtIcon from "../images/icons/txt.png";
import hi_txtIcon from "../images/icons/hi-txt.png";
import xlsIcon from "../images/icons/xls.png";
import hi_xlsIcon from "../images/icons/hi-xls.png";
import psdIcon from "../images/icons/psd.png";
import hi_psdIcon from "../images/icons/hi-psd.png";
import aiIcon from "../images/icons/ai.png";
import hi_aiIcon from "../images/icons/hi-ai.png";
import zipIcon from "../images/icons/zip.png";
import hi_zipIcon from "../images/icons/hi-zip.png";
import fileIcon from "../images/icons/file.png";
import hi_fileIcon from "../images/icons/hi-file.png";

import "../css/file.css";

//import loadingIcon from '../images/loader.gif';
//import injectTapEventPlugin from "react-tap-event-plugin";
//import isDblTouchTap from "./isDblTouchTap";
//injectTapEventPlugin();

//const loading = <img className="loading" src={ loadingIcon } alt="" />;

const loading = (
  <div className="loading">
    <svg viewBox="0 0 64 64">
      <g strokeWidth="0">
        <circle cx="24" cy="0" transform="translate(32,32)" r="1.39099">
          <animate
            attributeName="r"
            dur="750ms"
            values="8;7;6;5;4;3;2;1;8"
            repeatCount="indefinite"
          ></animate>
        </circle>
        <circle
          cx="16.970562748477143"
          cy="16.97056274847714"
          transform="translate(32,32)"
          r="2.39099"
        >
          <animate
            attributeName="r"
            dur="750ms"
            values="1;8;7;6;5;4;3;2;1"
            repeatCount="indefinite"
          ></animate>
        </circle>
        <circle
          cx="1.4695761589768238e-15"
          cy="24"
          transform="translate(32,32)"
          r="3.39099"
        >
          <animate
            attributeName="r"
            dur="750ms"
            values="2;1;8;7;6;5;4;3;2"
            repeatCount="indefinite"
          ></animate>
        </circle>
        <circle
          cx="-16.97056274847714"
          cy="16.970562748477143"
          transform="translate(32,32)"
          r="4.39099"
        >
          <animate
            attributeName="r"
            dur="750ms"
            values="3;2;1;8;7;6;5;4;3"
            repeatCount="indefinite"
          ></animate>
        </circle>
        <circle
          cx="-24"
          cy="2.9391523179536475e-15"
          transform="translate(32,32)"
          r="5.39099"
        >
          <animate
            attributeName="r"
            dur="750ms"
            values="4;3;2;1;8;7;6;5;4"
            repeatCount="indefinite"
          ></animate>
        </circle>
        <circle
          cx="-16.970562748477143"
          cy="-16.97056274847714"
          transform="translate(32,32)"
          r="6.39099"
        >
          <animate
            attributeName="r"
            dur="750ms"
            values="5;4;3;2;1;8;7;6;5"
            repeatCount="indefinite"
          ></animate>
        </circle>
        <circle
          cx="-4.408728476930472e-15"
          cy="-24"
          transform="translate(32,32)"
          r="7.39099"
        >
          <animate
            attributeName="r"
            dur="750ms"
            values="6;5;4;3;2;1;8;7;6"
            repeatCount="indefinite"
          ></animate>
        </circle>
        <circle
          cx="16.970562748477136"
          cy="-16.970562748477143"
          transform="translate(32,32)"
          r="5.26307"
        >
          <animate
            attributeName="r"
            dur="750ms"
            values="7;6;5;4;3;2;1;8;7"
            repeatCount="indefinite"
          ></animate>
        </circle>
      </g>
    </svg>
  </div>
);

export default class File extends Component {
  constructor() {
    super();

    this.state = {
      isHovering: false,
      try: 0,
      webmThumbnailLoaded: false,
      webmThumbnail: loading,
      imgThumbnail: loading,
      errorImage: false,
      truncate: false,
      img: null,
    };
  }

  handleMouseOver() {
    const { objectElement, objectNumber } = this.props;
    const element = objectElement[objectNumber];
    const linktype = element.linktype;

    if (this.state.webmThumbnailLoaded) {
      this.setState({ isHovering: true });
    }

    if (linktype === "youtube") {
      let parent = this;
      let linkimg = null;

      this.youtube = setInterval(function () {
        if (parent.state.img === null) {
          linkimg = element.linkimg;
        } else {
          linkimg = parent.state.img.props.src;
          console.log("linkimg: ", linkimg);
          //  linkimg = linkimg.slice(linkimg.indexOf('src="')+5, linkimg.length);
          //  linkimg = linkimg.slice(0, linkimg.indexOf('"'));
        }
        // let linkimg = (parent.state.img === null) ? element.linkimg : parent.state.img;
        let templateUrl = linkimg.slice(0, linkimg.lastIndexOf("/") + 1);
        let imageNumber = linkimg.slice(
          linkimg.lastIndexOf("/") + 1,
          linkimg.lastIndexOf(".")
        );
        let nextImage = null;

        console.log("linkurl: " + linkimg);
        console.log("templateUrl: " + templateUrl);
        console.log("imageNumber: " + imageNumber);
        console.log("image length: " + imageNumber.length);

        if (imageNumber.length > 1) {
          nextImage = templateUrl + "1.jpg";
        } else {
          imageNumber = parseInt(imageNumber, 10);
          if (imageNumber + 1 > 3) {
            nextImage = templateUrl + "default.jpg";
          } else {
            nextImage = templateUrl + (imageNumber + 1) + ".jpg";
          }
        }

        parent.setState({ img: <img src={nextImage} alt="" /> });
      }, 1000);
    }
  }

  handleMouseOut() {
    const { objectElement, objectNumber } = this.props;
    const element = objectElement[objectNumber];
    const linktype = element.linktype;

    if (this.state.webmThumbnailLoaded) {
      this.setState({ isHovering: false });
    }

    if (linktype === "youtube") {
      let linkimg = null;
      clearInterval(this.youtube);
      if (this.state.img === null) {
        linkimg = element.linkimg;
      } else {
        linkimg = this.state.img.props.src;
        //  linkimg = linkimg.slice(linkimg.indexOf('src="')+5, linkimg.length);
        //  linkimg = linkimg.slice(0, linkimg.indexOf('"'));
      }
      //   let linkimg = (this.state.img === null) ? element.linkimg : this.state.img;
      let templateUrl =
        linkimg.slice(0, linkimg.lastIndexOf("/") + 1) + "default.jpg";
      this.setState({ img: <img src={templateUrl} alt="" /> });
    }
  }

  /*
  webmCheckIfExist(s3Domain, thumbnailImage, thumbnailVideo) {
    if(!this.state.webmThumbnailLoaded && this.webmFile){
      Api.checkIfExist(thumbnailVideo).then(res => {
        if(res.data.file){
          if(typeof this.webmInterval !== undefined){ 
            clearInterval(this.webmInterval);
          }
          this.setState({
            imgThumbnail: <img className="image" src={ s3Domain + thumbnailImage }  alt="" />,
            webmThumbnail: <video autoPlay loop preload="auto" src={ s3Domain + thumbnailVideo } poster={ s3Domain + thumbnailImage }></video>,
            webmThumbnailLoaded: true
          });
        } else {
          console.log(thumbnailImage + ", Try: " + this.state.webmTry);
          if(this.state.webmTry === 0){
            this.webmInterval = setInterval(() => {
              this.setState({
                webmTry: this.state.webmTry+1
              });
            }, 30000); // 30 sec
          }
          if(this.state.webmTry >= 0 && !this.webmFile){
            if(typeof this.webmInterval !== undefined || typeof this.webmInterval !== "undefined"){ 
              clearInterval(this.webmInterval);
            }
          }
          if(this.state.webmTry > 3) {
            clearInterval(this.webmInterval);
            this.setState({
              imgThumbnail: <img className="videoIcon" src={ videoIcon } srcSet={ hi_videoIcon + " 2x"} alt="" />,
              webmThumbnail: <img className="videoIcon" src={ videoIcon } srcSet={ hi_videoIcon + " 2x"} alt="" />,
              webmThumbnailLoaded: true
            });            
          }
        }
      })
    }    
  }

  webmCheckIfExist2(s3Domain, thumbnailImage, thumbnailVideo) {
    if(!this.state.webmThumbnailLoaded){
      Api.checkIfExist(thumbnailVideo).then(res => {
        if(res.data.file){
          this.setState({
            imgThumbnail: <img className="image" src={ s3Domain + thumbnailImage }  alt="" />,
            webmThumbnail: <video autoPlay loop preload="auto" src={ s3Domain + thumbnailVideo } poster={ s3Domain + thumbnailImage }></video>,
            webmThumbnailLoaded: true
          });
        }
      })
    }    
  }
*/

  webmCheck(elementId, thumbnailImage, thumbnailVideo) {
    console.log("elementId: " + thumbnailVideo + ", Try: " + this.state.try);
    if (!this.state.webmThumbnailLoaded) {
      Api.checkIfExist(thumbnailVideo).then((res) => {
        if (res.data.file) {
          Api.confirmFile(elementId, true);
          clearInterval(this.state.interval);
          this.setState({
            imgThumbnail: (
              <img className="image" src={domainS3 + thumbnailImage} alt="" />
            ),
            webmThumbnail: (
              <video
                autoPlay
                loop
                preload="auto"
                src={domainS3 + thumbnailVideo}
              ></video>
            ),
            webmThumbnailLoaded: true,
          });
        } else {
          if (this.state.try >= 3) {
            Api.confirmFile(elementId, false);
            clearInterval(this.state.interval);
            this.setState({
              imgThumbnail: (
                <img
                  className="videoIcon"
                  src={videoIcon}
                  srcSet={hi_videoIcon + " 2x"}
                  alt=""
                />
              ),
              webmThumbnail: (
                <img
                  className="videoIcon"
                  src={videoIcon}
                  srcSet={hi_videoIcon + " 2x"}
                  alt=""
                />
              ),
              webmThumbnailLoaded: true,
            });
          } else {
            this.setState({
              try: this.state.try + 1,
            });
          }
        }
      });
    }
  }

  imgCheck(elementId, thumbnailImage) {
    console.log("elementId: " + thumbnailImage + ", Try: " + this.state.try);
    if (this.state.img === null) {
      Api.checkIfExist(thumbnailImage).then((res) => {
        if (res.data.file) {
          Api.confirmFile(elementId, true);
          clearInterval(this.state.interval);
          this.setState({
            img: (
              <img
                className="thumbnail"
                src={domainS3 + thumbnailImage}
                alt=""
              />
            ),
          });
        } else {
          if (this.state.try >= 3) {
            Api.confirmFile(elementId, false);
            clearInterval(this.state.interval);
            this.setState({
              img: <img src={imageIcon} srcSet={hi_imageIcon + " 2x"} alt="" />,
            });
          } else {
            this.setState({
              try: this.state.try + 1,
            });
          }
        }
      });
    }
  }

  openFile = () => {
    const { objectElement, objectNumber } = this.props;
    const element = objectElement[objectNumber];

    if (
      element.linktype === "website" ||
      element.linktype === "webapp" ||
      element.linktype === "webgame"
    ) {
      window.open(element.linkurl);
      return false;
    } else {
      this.props.controlWindow(element, "add", objectElement);
    }
  };

  handleTruncate = (truncated) => {
    if (this.state.truncate !== truncated) {
      this.setState({
        truncate: true,
      });
    }
  };

  componentDidMount() {
    const { objectElement, objectNumber } = this.props;
    const element = objectElement[objectNumber];
    const linktype = element.linktype;

    switch (linktype) {
      case "video/mp4":
      case "video/x-msvideo":
      case "video/quicktime":
      case "video/avi":
      case "video/msvideo":
      case "application/x-troff-msvideo":
      case "application/x-mpegURL":
      case "video/x-matroska":
      case "video/webm":
      case "video/ogg":
        if (element.hasOwnProperty("confirm")) {
          if (element.confirm) {
            this.setState({
              imgThumbnail: (
                <img
                  className="image"
                  src={domainS3 + element.thumbnailImage}
                  alt=""
                />
              ),
              webmThumbnail: (
                <video
                  autoPlay
                  loop
                  preload="auto"
                  src={domainS3 + element.thumbnailVideo}
                ></video>
              ),
              webmThumbnailLoaded: true,
            });
          } else {
            this.setState({
              imgThumbnail: (
                <img
                  className="videoIcon"
                  src={videoIcon}
                  srcSet={hi_videoIcon + " 2x"}
                  alt=""
                />
              ),
              webmThumbnail: (
                <img
                  className="videoIcon"
                  src={videoIcon}
                  srcSet={hi_videoIcon + " 2x"}
                  alt=""
                />
              ),
              webmThumbnailLoaded: true,
            });
          }
        } else {
          let parent = this;
          this.webmCheck(
            element._id,
            element.thumbnailImage,
            element.thumbnailVideo
          );
          let interval = setInterval(function () {
            parent.webmCheck(
              element._id,
              element.thumbnailImage,
              element.thumbnailVideo
            );
          }, 30000); // 30 sec

          this.setState({ interval: interval });
        }
        break;
      case "image/png":
      case "image/jpg":
      case "image/jpeg":
      case "image/gif":
      case "image/tiff":
        if (element.hasOwnProperty("confirm")) {
          if (element.confirm) {
            this.setState({
              img: (
                <img
                  className="thumbnail"
                  src={domainS3 + element.thumbnailImage}
                  alt=""
                />
              ),
            });
          } else {
            this.setState({
              img: <img src={imageIcon} srcSet={hi_imageIcon + " 2x"} alt="" />,
            });
          }
        } else {
          let parent = this;
          this.imgCheck(element._id, element.thumbnailImage);
          let interval = setInterval(function () {
            parent.imgCheck(element._id, element.thumbnailImage);
          }, 2000); // 2 sec

          this.setState({ interval: interval });
        }
        break;
      case "tiktok":
        Api.tikTokData(element.linkurl).then((res) => {
          this.setState({
            img: <img src={res.data.thumbnail_url} alt="" />,
          });
        });
        break;
    }
  }

  componentWillUnmount() {
    const { objectElement, objectNumber } = this.props;
    const element = objectElement[objectNumber];
    const linktype = element.linktype;

    if (
      typeof this.state.interval !== "undefined" &&
      typeof this.state.interval !== undefined &&
      this.state.interval !== "undefined"
    ) {
      console.log("componentWillUnmount clearInterval");
      clearInterval(this.state.interval);
    }

    if (linktype === "youtube") {
      clearInterval(this.youtube);
    }
  }
  /*
  shouldComponentUpdate(nextProps, nextState){
    if(this.props.orientation !== nextProps.orientation){
      return true;
    }
  }


  componentDidUpdate(prevProps, prevState) {
    if (prevState.imgThumbnail !== this.state.imgThumbnail) {
      this.setState({ imgThumbnailLoaded: true });
    }
    if (prevState.webmThumbnail !== this.state.webmThumbnail) {
      this.setState({ webmThumbnailLoaded: true });
    }
  }
*/
  render() {
    const { objectElement, objectNumber, activeIndex } = this.props;
    const element = objectElement[objectNumber];

    let { isHovering, webmThumbnailLoaded, webmThumbnail, imgThumbnail } =
      this.state;
    let video = false,
      webm = false,
      img = null,
      folder = false,
      bgBlack = false,
      screenshot = false,
      active = activeIndex.includes(parseInt(objectNumber, 10))
        ? " active"
        : "";
    //    s3Domain = domainS3;
    //  s3Domain = "https://os5-cloud.s3.amazonaws.com/";

    //   active = (parseInt(activeIndex, 10) === parseInt(objectNumber, 10)) ? " active" : "";

    if (typeof element.default !== "undefined" && element.default === true) {
      img = (
        <img
          src={domainS3 + element.image.toString()}
          srcSet={domainS3 + element.hiimage.toString() + " 2x"}
          alt=""
        />
      );
    } else {
      switch (element.linktype) {
        case "website":
          if (
            element.hasOwnProperty("thumbnailWebsite") &&
            element.thumbnailWebsite !== null &&
            websiteScreenshot
          ) {
            screenshot = true;
            img = <img src={domainS3 + element.thumbnailWebsite} alt="" />;
          } else {
            img = (
              <img src={websiteIcon} srcSet={hi_websiteIcon + " 2x"} alt="" />
            );
          }
          break;
        case "webapp":
          if (
            element.hasOwnProperty("thumbnailWebsite") &&
            element.thumbnailWebsite !== null &&
            websiteScreenshot
          ) {
            screenshot = true;
            img = <img src={domainS3 + element.thumbnailWebsite} alt="" />;
          } else {
            img = (
              <img src={webappIcon} srcSet={hi_webappIcon + " 2x"} alt="" />
            );
          }
          break;
        case "webgame":
          if (
            element.hasOwnProperty("thumbnailWebsite") &&
            element.thumbnailWebsite !== null &&
            websiteScreenshot
          ) {
            screenshot = true;
            img = <img src={domainS3 + element.thumbnailWebsite} alt="" />;
          } else {
            img = <img src={gameIcon} srcSet={hi_gameIcon + " 2x"} alt="" />;
          }
          break;
        case "folder":
          folder = true;
          img = <img src={folderIcon} srcSet={hi_folderIcon + " 2x"} alt="" />;
          break;
        case "youtube":
        case "vimeo":
          video = true;
          img = <img src={element.linkimg} alt="" />;
          break;
        case "tiktok":
          img = this.state.img;
          break;
        case "pornhub":
          if (
            element.hasOwnProperty("thumbnailWebsite") &&
            element.thumbnailWebsite !== null &&
            websiteScreenshot
          ) {
            screenshot = true;
            img = <img src={domainS3 + element.thumbnailWebsite} alt="" />;
          } else {
            img = (
              <img src={pornhubIcon} srcSet={hi_pornhubIcon + " 2x"} alt="" />
            );
          }
          /*  video = true;
          bgBlack = true;
          img = <img className="pornhub" src={ pornhubIcon } srcSet={ hi_pornhubIcon + " 2x"}  alt="" /> */
          break;
        case "xvideos":
          if (
            element.hasOwnProperty("thumbnailWebsite") &&
            element.thumbnailWebsite !== null &&
            websiteScreenshot
          ) {
            screenshot = true;
            img = <img src={domainS3 + element.thumbnailWebsite} alt="" />;
          } else {
            img = <img src={xvideosIcon} alt="" />;
          }
          /*  video = true;
          bgBlack = true;
          img = <img className="xvideos" src={ xvideosIcon }  alt="" /> */
          break;
        case "image/png":
        case "image/jpg":
        case "image/jpeg":
        case "image/gif":
        case "image/tiff":
          /*   let imageUrl = decodeURIComponent(s3Domain + element.thumbnailImage);
          if (this.state.errorImage) {
            imageUrl = imageIcon;
          }
          img = <img className="thumbnail" src={ imageUrl } onError={e => this.setState({ errorImage: true })} alt="" />
          */

          img = loading;
          break;
        case "video/mp4":
        case "video/x-msvideo":
        case "video/quicktime":
        case "video/avi":
        case "video/msvideo":
        case "application/x-troff-msvideo":
        case "application/x-mpegURL":
        case "video/webm":
        case "video/ogg":
          video = true;
          webm = true;
          //     this.webmCheckIfExist(s3Domain, element.thumbnailImage, element.thumbnailVideo);
          // s3Domain + element.thumbnailImage
          //      img = <img className="image" src={ "https://www.pornhub.com/img.png"} onError={(e)=>{e.target.src=loadingIcon; e.target.className="loading"; }} alt="" />
          break;
        case "application/pdf":
          img = <img src={pdfIcon} srcSet={hi_pdfIcon + " 2x"} alt="" />;
          break;
        case "application/powerpoint":
        case "application/vnd.ms-powerpoint":
        case "application/vnd.openxmlformats-officedocument.presentationml.presentation":
          img = <img src={pptIcon} srcSet={hi_pptIcon + " 2x"} alt="" />;
          break;
        case "application/excel":
        case "application/vnd.ms-excel":
        case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
          img = <img src={xlsIcon} srcSet={hi_xlsIcon + " 2x"} alt="" />;
          break;
        case "application/msword":
        case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
          img = <img src={docIcon} srcSet={hi_docIcon + " 2x"} alt="" />;
          break;
        case "text/plain":
          img = <img src={txtIcon} srcSet={hi_txtIcon + " 2x"} alt="" />;
          break;
        case "application/octet-stream":
          img = <img src={psdIcon} srcSet={hi_psdIcon + " 2x"} alt="" />;
          break;
        case "application/postscript":
          img = <img src={aiIcon} srcSet={hi_aiIcon + " 2x"} alt="" />;
          break;
        case "application/zip":
          img = <img src={zipIcon} srcSet={hi_zipIcon + " 2x"} alt="" />;
          break;
        default:
          let fileType = element.linktype.slice(
            0,
            element.linktype.indexOf("/")
          );
          if (fileType === "image") {
            img = <img src={imageIcon} srcSet={hi_imageIcon + " 2x"} alt="" />;
          } else if (fileType === "video") {
            img = <img src={videoIcon} srcSet={hi_videoIcon + " 2x"} alt="" />;
          } else if (fileType === "audio") {
            img = <img src={musicIcon} srcSet={hi_musicIcon + " 2x"} alt="" />;
          } else {
            let ext = element.hasOwnProperty("extension")
              ? element.extension
              : "";
            img = (
              <div>
                <img src={fileIcon} srcSet={hi_fileIcon + " 2x"} alt="" />
                <span className="someFile">{ext}</span>
              </div>
            );
          }
          break;
      }
    }

    let linkname = element.linkname;
    let delay = this.props.delay;
    let fileSize = null;
    if (element.hasOwnProperty("size")) {
      fileSize = bytesToSize(element.size);
      fileSize = fileSize.size + fileSize.unit;
    } else {
      fileSize = "";
    }

    let delayStyle = delay === 0 ? {} : { animationDelay: delay + "s" };

    if (this.props.activeLayout === "block") {
      if (folder) {
        return (
          <a
            className={
              delay !== 0
                ? `file-tile-box ${screenshot} fadein ` + active
                : `file-tile-box ${screenshot} ` + active
            }
            style={delayStyle}
            index={objectNumber}
            onClick={this.props.selectFile.bind(objectNumber, this)}
            onContextMenu={this.props.selectFile.bind(objectNumber, this)}
            onDoubleClick={() => this.props.goto(element.linkname)}
            /*     onTouchTap={ (e) => {
                  if ((isTablet || isMobile) && isDblTouchTap(e)) {
                    this.props.gotoMobile(element.linkname);
                  }
                } } */
          >
            <div className="file-w">{img}</div>
            <div className="file-name">
              <Truncate
                lines={2}
                ellipsis={"..."}
                onTruncate={this.handleTruncate.bind(this)}
              >
                {linkname}
              </Truncate>
              {this.state.truncate ? (
                <span className="hoverName">{linkname}</span>
              ) : (
                ""
              )}
            </div>
          </a>
        );
      } else if (webm) {
        return (
          <a
            className={
              delay !== 0
                ? "file-tile-box video-tile-box fadein " + active
                : "file-tile-box video-tile-box " + active
            }
            style={delayStyle}
            index={objectNumber}
            onClick={this.props.selectFile.bind(objectNumber, this)}
            onDoubleClick={this.openFile.bind(this)}
            onContextMenu={this.props.selectFile.bind(objectNumber, this)}
            onMouseOver={this.handleMouseOver.bind(this)}
            onMouseOut={this.handleMouseOut.bind(this)}
            /*  onTouchTap={ (e) => {
                  if ((isTablet || isMobile) && isDblTouchTap(e)) {
                    this.openFile();
                  }
                } } */
          >
            <div className="file-w uploaded-video">
              {isHovering && webmThumbnailLoaded && !isTablet && !isMobile
                ? webmThumbnail
                : imgThumbnail}
            </div>
            <div className="file-name">
              <Truncate
                lines={2}
                ellipsis={"..."}
                onTruncate={this.handleTruncate.bind(this)}
              >
                {linkname}
              </Truncate>
              {this.state.truncate ? (
                <span className="hoverName">{linkname}</span>
              ) : (
                ""
              )}
            </div>
          </a>
        );
      } else {
        return (
          <a
            className={
              delay !== 0
                ? video || screenshot
                  ? "file-tile-box video-tile-box fadein " + active
                  : "file-tile-box fadein" + active
                : video || screenshot
                ? "file-tile-box video-tile-box" + active
                : "file-tile-box" + active
            }
            style={delayStyle}
            index={objectNumber}
            onClick={this.props.selectFile.bind(objectNumber, this)}
            onDoubleClick={this.openFile.bind(this)}
            onContextMenu={this.props.selectFile.bind(objectNumber, this)}
            onMouseOver={this.handleMouseOver.bind(this)}
            onMouseOut={this.handleMouseOut.bind(this)}
            /*  onTouchTap={ (e) => {
                  if ((isTablet || isMobile) && isDblTouchTap(e)) {
                    this.openFile();
                  }
                } } */
          >
            <div className={bgBlack ? "file-w bg-black" : "file-w"}>
              {this.state.img === null ? img : this.state.img}
            </div>
            <div className="file-name">
              <Truncate
                lines={2}
                ellipsis={"..."}
                onTruncate={this.handleTruncate.bind(this)}
              >
                {linkname}
              </Truncate>
              {this.state.truncate ? (
                <span className="hoverName">{linkname}</span>
              ) : (
                ""
              )}
            </div>
          </a>
        );
      }
    } else {
      if (folder) {
        return (
          <Table.Row
            className={delay !== 0 ? "row fadeinRow" + active : "row " + active}
            style={delayStyle}
            index={objectNumber}
            onClick={this.props.selectFile.bind(objectNumber, this)}
            onContextMenu={this.props.selectFile.bind(objectNumber, this)}
            onDoubleClick={() => this.props.goto(element.linkname)}
            /*  onTouchTap={ (e) => {
                        if ((isTablet || isMobile) && isDblTouchTap(e)) {
                          this.props.gotoMobile(element.linkname);
                        }
                      } } */
          >
            <Table.Cell className="iconCell">
              <div className="iconImage">{img}</div>
            </Table.Cell>
            <Table.Cell className="iconName">{linkname}</Table.Cell>
            <Table.Cell className="date">{formatDate(element.date)}</Table.Cell>
            <Table.Cell className="size">{fileSize}</Table.Cell>
          </Table.Row>
        );
      } else if (webm) {
        return (
          <Table.Row
            className={delay !== 0 ? "row fadeinRow" + active : "row " + active}
            index={objectNumber}
            style={delayStyle}
            onClick={this.props.selectFile.bind(objectNumber, this)}
            onDoubleClick={this.openFile.bind(this)}
            onContextMenu={this.props.selectFile.bind(objectNumber, this)}
            onMouseOver={this.handleMouseOver.bind(this)}
            onMouseOut={this.handleMouseOut.bind(this)}
            /*  onTouchTap={ (e) => {
                if ((isTablet || isMobile) && isDblTouchTap(e)) {
                  this.openFile();
                }
              } } */
          >
            <Table.Cell className="iconCell">
              <div className="iconImage">{imgThumbnail}</div>
            </Table.Cell>
            <Table.Cell className="iconName">{linkname}</Table.Cell>
            <Table.Cell className="date">{formatDate(element.date)}</Table.Cell>
            <Table.Cell className="size">{fileSize}</Table.Cell>
          </Table.Row>
        );
      } else {
        return (
          <Table.Row
            className={delay !== 0 ? "row fadeinRow" + active : "row " + active}
            style={delayStyle}
            index={objectNumber}
            onClick={this.props.selectFile.bind(objectNumber, this)}
            onDoubleClick={this.openFile.bind(this)}
            onContextMenu={this.props.selectFile.bind(objectNumber, this)}
            /*    onTouchTap={ (e) => {
                        if ((isTablet || isMobile) && isDblTouchTap(e)) {
                          this.openFile();
                        }
                      } } */
          >
            <Table.Cell className="iconCell">
              <div className="iconImage">
                {this.state.img === null ? img : this.state.img}
              </div>
            </Table.Cell>
            <Table.Cell className="iconName">{linkname}</Table.Cell>
            <Table.Cell className="date">{formatDate(element.date)}</Table.Cell>
            <Table.Cell className="size">{fileSize}</Table.Cell>
          </Table.Row>
        );
      }
    }
  }
}
