import React, { useState, useEffect } from "react";
import { weatherApi } from "./Api";

function Weather() {
  const [images, setImages] = useState([]),
    [weather, setWeather] = useState({});

  const importAll = (r) => {
    let obj = {};
    r.keys().map((item, index) => {
      return (obj[item.replace("./", "").slice(0, item.lastIndexOf(".") - 2)] =
        r(item));
    });
    return obj;
  };

  const getPosition = async (position) => {
    const lat = position.coords.latitude,
      long = position.coords.longitude,
      req = await weatherApi(lat, long);

    setWeather(req.data.currentConditions);
  };

  useEffect(() => {
    const imgs = importAll(
      require.context("../images/svg/weather/", false, /\.(png|jpe?g|svg)$/)
    );
    setImages(imgs);
    navigator.geolocation.getCurrentPosition(getPosition);
  }, []);

  return (
    <>
      {weather.icon && (
        <div
          className="weather"
          title={`${weather.conditions}, feels like ${Math.round(
            weather.feelslike
          )}°C.`}
        >
          <img src={images[weather.icon].default} alt="" />
          <div className="temperature">
            <strong>{Math.round(weather.temp)}</strong>
            <span>°C</span>
          </div>
        </div>
      )}
    </>
  );
}

export default Weather;
