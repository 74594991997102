import React, { Component, lazy, Suspense } from "react";
import {
  Sidebar,
  Segment,
  Icon,
  Dimmer,
  Loader,
  Button,
  Checkbox,
  Item,
} from "semantic-ui-react";
import { BrowserRouter, Link } from "react-router-dom";
import auth0 from "auth0-js";
import history from "./history";
import { isTablet, isMobile } from "react-device-detect";
import * as Api from "./components/Api";
import CloudHeader from "./components/CloudHeader";
import CloudWindow from "./components/CloudWindow";
import WindowsLooper from "./components/WindowsLooper";
import SideMenu from "./components/SideMenu";
import Routes from "./components/Routes";
import NotificationSystem from "react-notification-system";
import CookieBanner from "react-cookie-banner";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faCookie,
  faFingerprint,
  faPuzzlePiece,
} from "@fortawesome/free-solid-svg-icons";
import GoogleTagManager from "./components/GoogleTagManager";
import Account from "./pages/account";
import StartupAd from "./components/StartupAd";
import LoginBar from "./components/LoginBar";
import Footer from "./components/Footer";
import LikeWebsite from "./components/LikeWebsite";
import {
  sitePrimaryDomain,
  siteTitle,
  siteCategory,
  decodeHtmlCharCodes,
  from24to12,
} from "./js/functions";
import ModalMobile from "./components/ModalMobile";
import BrowserNotification from "./components/BrowserNotification";
import { ReactComponent as NewspaperSVG } from "./images/svg/news_newspaper.svg";
import Weather from "./components/Weather";
import "semantic-ui-css/semantic.min.css";
import "./css/style.css";

library.add(faCookie, faFingerprint, faPuzzlePiece);

const GoogleAds = lazy(() => import("./components/GoogleAds"));

const monthFullNames = {
  Jan: "January",
  Feb: "February",
  Mar: "March",
  Apr: "April",
  May: "May",
  Jun: "June",
  Jul: "July",
  Aug: "August",
  Sep: "September",
  Oct: "October",
  Nov: "November",
  Dec: "December",
};

class Auth {
  auth0 = new auth0.WebAuth({
    domain: "cloudos.auth0.com",
    clientID: "bwH35HLUdvV3n2TTsN2QhcUcT8AcBtsz",
    redirectUri:
      (window.location.hostname === "localhost"
        ? "http://localhost:3000"
        : sitePrimaryDomain) + "/callback", //'http://localhost:3000/callback',
    audience: "https://cloudos.auth0.com/userinfo",
    responseType: "token id_token",
    scope: "openid profile email",
  });

  constructor() {
    this.login = this.login.bind(this);
    this.logout = this.logout.bind(this);
    this.handleAuthentication = this.handleAuthentication.bind(this);
    this.isAuthenticated = this.isAuthenticated.bind(this);
    this.getProfile = this.getProfile.bind(this);
  }

  login() {
    this.auth0.authorize();
  }

  loginPricing() {
    this.auth0 = new auth0.WebAuth({
      domain: "cloudos.auth0.com",
      clientID: "bwH35HLUdvV3n2TTsN2QhcUcT8AcBtsz",
      redirectUri:
        (window.location.hostname === "localhost"
          ? "http://localhost:3000"
          : sitePrimaryDomain) + "/pricingcallback",
      audience: "https://cloudos.auth0.com/userinfo",
      responseType: "token id_token",
      scope: "openid profile email",
    });

    this.auth0.authorize();
  }

  handleAuthentication(returnUrl) {
    this.auth0.parseHash((err, authResult) => {
      if (authResult && authResult.accessToken && authResult.idToken) {
        this.setSession(authResult, returnUrl);
        //  history.replace('/allfiles/');
        //  window.location.reload();
      } else if (err) {
        if (err.error === "invalid_token") {
          window.location.href = window.location.origin + window.location.hash;
        }
        history.replace("/");
        console.log(err);
      }
    });
  }

  setSession(authResult, returnUrl) {
    // Set the time that the access token will expire at
    let expiresAt = JSON.stringify(
      1000 * 60 * 60 * 24 * 14 + new Date().getTime()
    ); // JSON.stringify((authResult.expiresIn * 1000) + new Date().getTime());
    console.log("expiresAt: ", expiresAt);
    localStorage.setItem("access_token", authResult.accessToken);
    localStorage.setItem("id_token", authResult.idToken);
    //    localStorage.setItem('profile_id', authResult.idTokenPayload.sub);
    //    localStorage.setItem('profile_name', authResult.idTokenPayload.name);
    //    localStorage.setItem('profile_picture', authResult.idTokenPayload.picture);
    localStorage.setItem("expires_at", expiresAt);
    // navigate to the home route
    history.replace(returnUrl);
    window.location.reload();
  }

  logout() {
    // Clear access token and ID token from local storage
    localStorage.removeItem("access_token");
    localStorage.removeItem("id_token");
    //    localStorage.removeItem('profile_id');
    //    localStorage.removeItem('profile_name');
    //    localStorage.removeItem('profile_picture');
    localStorage.removeItem("expires_at");
    // navigate to the home route
    history.replace("/");
    window.location.reload();
  }

  isAuthenticated() {
    // Check whether the current time is past the
    // access token's expiry time
    let expiresAt = JSON.parse(localStorage.getItem("expires_at"));
    return new Date().getTime() < expiresAt;
  }

  getAccessToken() {
    const accessToken = localStorage.getItem("access_token");
    if (!accessToken) {
      //throw new Error('No access token found');
      return false;
    }
    return accessToken;
  }

  getProfile(cb) {
    let accessToken = this.getAccessToken();
    if (accessToken) {
      this.auth0.client.userInfo(accessToken, (err, profile) => {
        if (profile) {
          this.userProfile = profile;
        }
        cb(err, profile);
      });
    }
  }
}

const auth = new Auth();

const handleAuthentication = (returnUrl = "/allfiles/") => {
  if (/access_token|id_token|error/.test(window.location.hash)) {
    auth.handleAuthentication(returnUrl);
  }
};

class App extends Component {
  constructor() {
    super();

    this._componentWillMount();

    this.state = {
      windowInnerHeight: window.innerHeight,
      windowInnerWidth: window.innerWidth,
      visible: false,
      //     auth: new Auth(),
      active: false,
      dimmerBg: true,
      dimmerText: "Loading",
      token: "",
      windows: {},
      mobileData: {},
      mobileWindow: false,
      defaultLinks: true,
      startupAd: false,
      likeWebsiteNotification: true,
    };
  }

  _notificationSystem = null;

  toggleVisibility = () => this.setState({ visible: !this.state.visible });

  logout() {
    Api.token("").then((res) => {
      auth.logout();
    });
  }

  _addNotification = (level, title, message) => {
    this._notificationSystem.addNotification({
      title: title,
      message: message,
      level: level,
      autoDismiss: 10,
    });
  };

  _showDimmer = (show = false, dimmerBg = true, dimmerText = "Loading") => {
    if (this.state.active !== show || this.state.dimmerText !== dimmerText) {
      this.setState({
        active: show,
        dimmerBg: dimmerBg,
        dimmerText: dimmerText,
      });
    }
  };

  _componentWillMount() {
    if (window.location.hostname !== "localhost") console.log = function () {}; // disable console.log
    //  this.state.auth.handleAuthentication();
    if (auth.isAuthenticated()) {
      const { userProfile, getProfile } = auth;
      if (!userProfile) {
        getProfile((err, profile) => {
          Api.token(localStorage.getItem("access_token")).then((res) => {
            Api.checkUsage().then((response) => {
              //    console.log(response.data.result.defaultLinks);
              //    console.log(profile);
              this._darkMode(response.data.result.darkMode);
              this.setState({
                profile,
                userId: true,
                token: res,
                defaultLinks: response.data.result.defaultLinks,
                startupAd: response.data.result.startupAd,
                likeWebsiteNotification:
                  response.data.result.likeWebsiteNotification,
              });
            });
          });
          //    this.setState({ profile });
        });
      } else {
        Api.token(localStorage.getItem("access_token")).then((res) => {
          Api.checkUsage().then((response) => {
            //   console.log(response.data.result.defaultLinks);
            //    console.log(userProfile);
            this._darkMode(response.data.result.darkMode);
            this.setState({
              profile: userProfile,
              userId: true,
              token: res,
              defaultLinks: response.data.result.defaultLinks,
              startupAd: response.data.result.startupAd,
              likeWebsiteNotification:
                response.data.result.likeWebsiteNotification,
            });
          });
        });
        //   this.setState({ profile: userProfile });
      }
    } else {
      Api.token("");
    }
  }

  componentDidMount() {
    this._notificationSystem = this.refs.notificationSystem;

    if (
      !this.props.isAuth &&
      window.location.pathname === "/" &&
      window.location.hash !== ""
    ) {
      let hash = window.location.hash.slice(1);
      console.log(hash);
      if (hash === "relogin") {
        //     this._addNotification("info", "Please log in again.");
        this._notificationSystem.addNotification({
          title: "Please log in again.",
          level: "info",
          autoDismiss: false,
          action: {
            label: "Log In",
            callback: function () {
              auth.login();
            },
          },
        });
      }
    }

    window.addEventListener("resize", () =>
      this.setState({
        windowInnerHeight: window.innerHeight,
        windowInnerWidth: window.innerWidth,
      })
    );
  }

  /*
  componentWillUnmount() {
    window.removeEventListener("resize", () => this.setState({windowInnerHeight: window.innerHeight, windowInnerWidth: window.innerWidth}));
  }
*/

  componentDidUpdate(prevProps, prevState) {
    // only update chart if the data has changed
    if (prevState.token !== this.state.token) {
      //   console.log("Now:");
      //   console.log(this.state);
      //   console.log("--------------------");
      //   console.log("Prev:");
      //   console.log(prevState);
      this.setState({ active: false });
    }
  }

  controlWindow = (data, action, filesList = []) => {
    if (isTablet || isMobile || window.innerWidth < 992) {
      let linktype = data.linktype,
        arrFileList = [];

      for (let a = 0; a < filesList.length; a++) {
        if (filesList[a].linktype === linktype) arrFileList.push(filesList[a]);
      }

      data["filesList"] = arrFileList;

      this.setState({
        mobileWindow: true,
        mobileData: data,
      });
    } else {
      let windows = this.state.windows;
      let arrTemp;
      switch (action) {
        case "remove":
          arrTemp = [];
          for (let a = 0; a < windows.length; a++) {
            if (windows[a]["_id"] !== data) arrTemp.push(windows[a]);
          }
          this.setState({ windows: arrTemp });
          break;
        case "minimize":
          arrTemp = windows;
          for (let a = 0; a < arrTemp.length; a++) {
            if (arrTemp[a]["_id"] === data) arrTemp[a]["minimize"] = true;
          }
          this.setState({ windows: arrTemp });
          break;
        case "cancel-minimize":
          arrTemp = windows;
          for (let a = 0; a < arrTemp.length; a++) {
            if (arrTemp[a]["_id"] === data) arrTemp[a]["minimize"] = false;
          }
          this.setState({ windows: arrTemp });
          break;
        case "add":
        default:
          let exist = false,
            existIdNum = null,
            linktype = data.linktype,
            arrFileList = [];

          arrTemp = windows;

          for (let a = 0; a < windows.length; a++) {
            if (windows[a]["_id"] === data["_id"]) {
              existIdNum = a;
              exist = true;
            }
          }

          for (let a = 0; a < filesList.length; a++) {
            if (filesList[a].linktype === linktype)
              arrFileList.push(filesList[a]);
          }

          data["filesList"] = arrFileList;
          if (!exist) {
            // console.clear();
            // console.log("arrTemp", arrTemp);
            // console.log("data", data);

            if (
              arrTemp &&
              Object.keys(arrTemp).length === 0 &&
              arrTemp.constructor === Object
            ) {
              arrTemp = [data];
            } else {
              arrTemp.push(data);
            }

            this.setState({ windows: arrTemp });
            // this.setState({ windows: [...arrTemp, data] });
          } else {
            data["_id"] = Math.random().toString(32).slice(2);
            arrTemp[existIdNum] = data;
            this.setState({ windows: arrTemp });
            this._addNotification(
              "warning",
              "Attention",
              "Opened in his original cloud."
            );
          }
          break;
      }
    }
  };

  _closeMobileWindow = () => {
    this.setState({
      mobileWindow: false,
    });
  };

  _closeDesktopWindows = () => {
    this.setState({
      windows: [],
    });
  };

  _accountUsageAction = (action, param) => {
    if (action === "defaultLinks" && param !== this.state.defaultLinks) {
      this.setState({ defaultLinks: param });
    }
  };

  _darkMode = (val = false) => {
    if (val) {
      if (!window.document.body.className.includes("darkMode"))
        window.document.body.className += " darkMode";
    } else {
      if (window.document.body.className.includes("darkMode"))
        window.document.body.className = window.document.body.className
          .replace(" darkMode", "")
          .replace("darkMode", "");
    }
  };

  _likeWebsite = (action = "show") => {
    let uid = "likeCloudOS";

    if (action === "show") {
      this._notificationSystem.addNotification({
        title:
          "If you like this " +
          siteCategory.toLowerCase() +
          " you can make it as your default Homepage or Share it.",
        level: "info",
        autoDismiss: false,
        dismissible: "button",
        uid: uid,
        children: (
          <div className="likeCloudOS">
            <Button
              primary
              size="mini"
              onClick={() => this._likeWebsite("homepage")}
            >
              <Icon name="home" /> Make your Homepage
            </Button>
            <Button
              primary
              size="mini"
              onClick={() => this._likeWebsite("share")}
            >
              <Icon name="share" /> Share
            </Button>
            <Checkbox
              label="Don't show this message again."
              onClick={() => this._likeWebsite("remove")}
            />
          </div>
        ),
      });

      this.setState({ likeWebsiteNotification: false });
    } else if (action === "remove") {
      this._notificationSystem.removeNotification(uid);
      this.setState({ likeWebsiteNotification: false });
      Api.accountOptions({ likeWebsiteNotification: false });
    } else {
      this.setState({ likeWebsiteNotification: action });
    }
  };

  _openNewsWindow = () => {
    this._showDimmer(true, false);

    Api.checkUsage().then((response) => {
      Api.newsNYP(
        response.data.result.news,
        response.data.result.sport,
        response.data.result.business,
        response.data.result.entertainment,
        response.data.result.fashion,
        response.data.result.tech,
        response.data.result.living,
        response.data.result.realEstate
      ).then((res) => {
        let rss = res.data,
          rssArr = [],
          counter = 1000;

        if (rss.feed.items.length > 0) {
          rss.feed.items.forEach((element) => {
            let isoDate = new Date(element.isoDate),
              isoDateArr = isoDate.toDateString().split(" "),
              isoTimeString = isoDate.toTimeString(),
              isoTimeSplit = from24to12(
                isoTimeString.split(":")[0] + ":" + isoTimeString.split(":")[1]
              ),
              isoDateString =
                monthFullNames[isoDateArr[1]] +
                " " +
                isoDateArr[2] +
                ", " +
                isoDateArr[3];

            rssArr.push(
              <Item key={"news-" + counter++}>
                <Item.Image size="small" src={element.enclosure.url} />
                <Item.Content>
                  <Item.Header
                    as="a"
                    href={element.link}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {decodeHtmlCharCodes(element.title)}
                  </Item.Header>
                  <Item.Meta>
                    <span className="date">
                      {isoDateString + " " + isoTimeSplit}
                    </span>
                  </Item.Meta>
                  <Item.Description>
                    <p>
                      {decodeHtmlCharCodes(element.content).replace(
                        /&nbsp;/g,
                        " "
                      )}
                    </p>
                  </Item.Description>
                </Item.Content>
              </Item>
            );
          });
        } else {
          rssArr.push(
            <Item key="0">
              <Item.Content>
                <Item.Description>
                  Please select at least one news category in{" "}
                  <a href="/account">My Account</a> page.
                </Item.Description>
              </Item.Content>
            </Item>
          );
        }
        //  console.log("*** News: ***", rssArr);

        let tempData = {
          confirm: true,
          counter: 0,
          extension: "png",
          filesList: [],
          linkname: "News",
          feed: rssArr,
          linktype: "news",
          path: "/",
          size: 0,
          source: "NYP",
          _id: "1",
        };

        let arrTemp = this.state.windows;

        if (
          arrTemp &&
          Object.keys(arrTemp).length === 0 &&
          arrTemp.constructor === Object
        ) {
          arrTemp = [tempData];
        } else {
          arrTemp.push(tempData);
        }

        if (isTablet || isMobile) {
          this.setState({
            visible: false,
            mobileData: tempData,
            mobileWindow: true,
          });
        } else {
          this.setState({
            visible: false,
            windows: arrTemp,
          });
        }

        this._showDimmer(false);
      });
    });
  };

  render() {
    const {
      mobileWindow,
      mobileData,
      dimmerBg,
      dimmerText,
      likeWebsiteNotification,
    } = this.state;
    const isAuth = auth.isAuthenticated();

    let sub =
      typeof this.state.profile !== "undefined" &&
      this.state.profile !== {} &&
      this.state.token !== "" ? (
        <CloudWindow
          {...this.state}
          isAuth={isAuth}
          _addNotification={this._addNotification}
          showDimmer={this._showDimmer}
          controlWindow={this.controlWindow}
          closeDesktopWindows={this._closeDesktopWindows}
          darkMode={this._darkMode}
          toggleVisibility={this.toggleVisibility}
        >
          <Suspense fallback={""}>
            <GoogleAds />
          </Suspense>
        </CloudWindow>
      ) : (
        <div>
          <Dimmer active inverted>
            <Loader size="massive">Loading</Loader>
          </Dimmer>
        </div>
      );
    let account =
      typeof this.state.profile !== "undefined" &&
      this.state.profile !== {} &&
      this.state.token !== "" ? (
        <Account
          auth={auth}
          profile={this.state.profile}
          accountUsageAction={this._accountUsageAction}
          _addNotification={this._addNotification}
          showDimmer={this._showDimmer}
          darkMode={this._darkMode}
        />
      ) : (
        <div>
          <Dimmer active inverted>
            <Loader size="massive">Loading</Loader>
          </Dimmer>
        </div>
      );
    let shareTitle = siteTitle + " " + siteCategory;
    let sideMenu = this.state.visible ? "side-menu-active" : "";
    let mobileDevice = isTablet || isMobile ? true : false;

    return (
      <BrowserRouter>
        <div>
          <GoogleTagManager gtmId="GTM-N8KV3RG" />
          <NotificationSystem ref="notificationSystem" />
          <WindowsLooper
            windows={this.state.windows}
            controlWindow={this.controlWindow}
            windowInnerWidth={this.state.windowInnerWidth}
            showDimmer={this._showDimmer}
            _addNotification={this._addNotification}
          />
          <Dimmer
            className={dimmerBg ? "dimmer" : "dimmer noBackground"}
            active={this.state.active}
            inverted
          >
            <Loader size="massive">{dimmerText}</Loader>
          </Dimmer>

          {!mobileDevice &&
            likeWebsiteNotification &&
            window.location.pathname !== "/result/" && (
              <LikeWebsite
                auth={isAuth}
                modal={likeWebsiteNotification}
                shareTitle={shareTitle}
                likeWebsite={this._likeWebsite}
              />
            )}

          {mobileWindow && (
            <ModalMobile
              open={mobileWindow}
              element={mobileData}
              closeMobileWindow={this._closeMobileWindow}
              showDimmer={this._showDimmer}
              _addNotification={this._addNotification}
            />
          )}

          {!isAuth && (
            <LoginBar auth={auth} toggleVisibility={this.toggleVisibility} />
          )}
          <div className="border-top"></div>
          <Sidebar.Pushable as={Segment} className="pushableWrap">
            <SideMenu
              visible={this.state.visible}
              toggleVisibility={this.toggleVisibility}
              logout={this.logout}
              isAuth={isAuth}
              shareTitle={shareTitle}
              GoogleAds={GoogleAds}
            />
            <Sidebar.Pusher>
              <Segment basic className={`segmentMain ${sideMenu}`}>
                <BrowserNotification
                  auth={auth}
                  show={false}
                  _addNotification={this._addNotification}
                />
                <Icon
                  name="content"
                  className="burger"
                  size="huge"
                  onClick={this.toggleVisibility}
                />
                {isAuth ? (
                  <>
                    <a onClick={() => this._openNewsWindow()}>
                      <NewspaperSVG className="news" />
                    </a>
                    <Weather />
                  </>
                ) : null}
                <CloudHeader {...this.state} auth={auth} logout={this.logout} />

                <Routes
                  isAuth={isAuth}
                  profile={this.state.profile}
                  _darkMode={this._darkMode}
                  sub={sub}
                  loginPricing={auth.loginPricing}
                  mobileDevice={mobileDevice}
                  handleAuthentication={handleAuthentication}
                  account={account}
                  auth={auth}
                  _addNotification={this._addNotification}
                />

                <StartupAd show={this.state.startupAd} />
                <CookieBanner
                  disableStyle={true}
                  className="clearfix"
                  message="This website uses cookies to ensure you get the best experience on our website."
                  link={<Link to="/cookie">More info</Link>}
                  buttonMessage="Got it"
                />
                <Footer />
              </Segment>
            </Sidebar.Pusher>
          </Sidebar.Pushable>
        </div>
      </BrowserRouter>
    );
  }
}

export default App;
