import React, { Component } from 'react';
import { Modal, Header, Button } from 'semantic-ui-react';
import RadioButtonGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import * as Api from '../Api';
import { removeUnsafeChars, CloudRadio, CloudTextField, CloudChipInput } from '../../js/functions';

export default class EditLinkModal extends Component { 
  constructor(props) {
      super(props);
      this.state = {
          linkname: props.selectedElement.linkname,
          linkurl: props.selectedElement.linkurl,
          linktype: props.selectedElement.linktype,
          linktag: props.selectedElement.linktag,
          submitted: false,
          urlVal: "",
          nameVal: ""
      }
      this.commonChange = this.commonChange.bind(this);
      this._showContent = this._showContent.bind(this);
  }

  _showContent = (e) => {
    e.preventDefault();
    let { linkname, linkurl, linktype } = this.state;
 //   console.log(this.state);
    if(linkname.trim() === "" || linkurl === ""){
      this.setState({ submitted: true });
      return false;
    } else {
      if(!this.isUrl(linkurl)){
        this.setState({ submitted: true, urlVal: "Invalid URL" });
        return false;
      }
    }
    console.log(linktype);
    var linkimg = '';
    switch(linktype){
      case "youtube":
        linkimg = linkurl.match(/(?:https?:\/{2})?(?:w{3}\.)?youtu(?:be)?\.(?:com|be)(?:\/watch\?v=|\/)([^\s&]+)/);

        let result = linkimg[1];
        if(result === "" || result.includes("/") || result.includes("=") || result.includes("?")){
            this.setState({ submitted: true, urlVal: "Wrong video url" });
            return false;
        } else {
            result = "https://img.youtube.com/vi/" + result + "/default.jpg";
            this._submitLink(result);
        }
        break;
      case "vimeo":
        linkimg = linkurl.replace('https://vimeo.com/','').replace('https://www.vimeo.com/','');

        if(linkimg === "" || linkimg.includes("/") || linkimg.includes("=") || linkimg.includes("?")){
            this.setState({ submitted: true, urlVal: "Wrong video url" });
            return false;
        } else {
          Api.vimeoThumbnail(linkimg).then(res => {
            linkimg = res[0].thumbnail_medium;
            this._submitLink(linkimg);
          });
        }
        break;
      case "pornhub":
        if(linkurl.indexOf("?viewkey=") > -1){
          linkurl = linkurl.slice(linkurl.indexOf("?viewkey=") + 9, linkurl.length);
          if(linkurl.indexOf("&") > -1){
            linkurl = linkurl.slice(0, linkurl.indexOf("&"));
          }
          linkurl = "https://www.pornhub.com/embed/" + linkurl;
          this.setState({ linkurl: linkurl }, function(){
            linkimg = '';
            this._submitLink();
          })
        } 
        else {
        //  linkimg = '';
        //  this._submitLink();
          this.setState({ submitted: true, urlVal: "Wrong video url" });
          return false;
        }
        break;
      case "xvideos":
          if(linkurl.indexOf("/video") > -1){
            linkurl = linkurl.slice(linkurl.indexOf("/video") + 6, linkurl.length);
            if(linkurl.indexOf("/") > -1){
              linkurl = linkurl.slice(0, linkurl.indexOf("/"));
            }

            if(linkurl === "" || linkurl.includes("/") || linkurl.includes("=") || linkurl.includes("?")){
              this.setState({ submitted: true, urlVal: "Wrong video url" });
              return false;
            } else {
              linkurl = "https://www.xvideos.com/embedframe/" + linkurl;
              this.setState({ linkurl: linkurl }, function(){
                linkimg = '';
                this._submitLink();
              })
            }
          } 
          else if (linkurl.includes("xvideos.com/embedframe")){
            this.setState({ linkurl: linkurl }, function(){
              linkimg = '';
              this._submitLink();
            })
          }
          else {
        //   linkimg = '';
          //  this._submitLink();
            this.setState({ submitted: true, urlVal: "Wrong video url" });
            return false;
          }
          break;  
      case "tiktok":
          console.log(linkurl)
          if(linkurl.toLowerCase().indexOf("/video/") > -1 && linkurl.toLowerCase().indexOf("tiktok.com") > -1){
            linkurl = (linkurl.indexOf("?") > -1) ? linkurl.slice(0, linkurl.indexOf("?") + 1) : linkurl;
            this.setState({ linkurl: linkurl }, function(){
              console.log(this.state.linkurl)
              linkimg = '';
              this._submitLink();
            })
          } 
          else {
            this.setState({ submitted: true, urlVal: "Wrong video url" });
            return false;
          }
          break;                 
      default:
          linkimg = '';
          this._submitLink();
          break;
    }
  } 

  _urlGenerator(url, name){
    return url.slice(0, url.lastIndexOf("/") + 1) + name;
  }
  
  _submitLink(img = '') {
    let { linkname, linkurl, linktype, linktag } = this.state;
    let { selectedElement } = this.props;
    linkname = linkname.trim();

    if(selectedElement.linkname === linkname && selectedElement.linkurl === linkurl && selectedElement.linktype === linktype && selectedElement.linktag === linktag){
      this._closeModal();
    } 
    else {

      let obj = (img === '') ? { 
              linkname: linkname, 
              linkurl: linkurl, 
              linktype: linktype,
              linktag: linktag
          } : 
          { 
            linkname: linkname, 
            linkurl: linkurl, 
            linktype: linktype,
            linktag: linktag,
            linkimg: img
          };

      if(selectedElement.hasOwnProperty("thumbnailWebsite") && selectedElement.thumbnailWebsite !== null) {
        obj = Object.assign({ thumbnailWebsite: this._urlGenerator(selectedElement.thumbnailWebsite, linkname + ".png") }, obj);
      }    

      let funcResult = this.props.editFile(selectedElement._id, obj);

      if(funcResult){
        this._closeModal();
      }
      else {
        this.setState({ submitted: true, nameVal: "Name already exist" });
        return false;
      }
    }

  }

  commonChange(event) {
    if(event.target.name === "linkname"){
      event.target.value = removeUnsafeChars(event.target.value);
    }
    this.setState({
        [event.target.name]: event.target.value.trim()
    });
    if(event.target.name === "linkurl"){
      if(this.isUrl(event.target.value.trim())){
        this.setState({ urlVal: "" });
      }      
    }
  }

  handleChange(chips){
    this.setState({ linktag: chips });
 //   console.log(chips);
  }

  isUrl(val) {
    var regexp = /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i
    return regexp.test(val);
  }

  _closeModal = () => {
    this.props.close();
      
    this.setState({
      linkname: '',
      linkurl: '',
      linktype: 'website',
      linktag: [],
      submitted: false,
      urlVal: "",
      nameVal: ""
    });
  }

  render(){

    let urlErrorMsg = "", 
        nameErrorMsg = "";

    if(this.state.linkname === "" && this.state.submitted) {
      nameErrorMsg = "This field is required";
    } 
    else if(this.state.linkurl !== "" && this.state.submitted && this.state.nameVal !== ""){
      nameErrorMsg = this.state.nameVal;
    }   
    else {
      nameErrorMsg = "";
    }

    if(this.state.linkurl === "" && this.state.submitted) {
      urlErrorMsg = "This field is required";
    } 
    else if(this.state.linkurl !== "" && this.state.submitted && this.state.urlVal !== ""){
      urlErrorMsg = this.state.urlVal;
    } 
    else {
      urlErrorMsg = "";
    }


    return (
      <Modal size="mini" dimmer={this.props.dimmer} open={this.props.open} onClose={this._closeModal}>
          <Modal.Header>Edit Link</Modal.Header>
          <Modal.Content>
            <Modal.Description>
              <Header size='small'>Please specify the content of the link.</Header>
              <CloudTextField className="Cloud-Input" variant="standard" label="Link Name" name="linkname" onChange={this.commonChange} helperText={ nameErrorMsg } value={ this.state.linkname } /><br />
              <CloudTextField disabled className="Cloud-Input" variant="standard" label="URL"  name="linkurl" onChange={this.commonChange} helperText={ urlErrorMsg } value={ this.state.linkurl } /><br /><br />
              <p>Link type:</p><br />
              <RadioButtonGroup className="Cloud-Group" name="linktype" value={ this.state.linktype } onChange={this.commonChange}>
                <FormControlLabel control={<CloudRadio />} disabled={(this.state.linktype !== "website")} value="website" label="Website" />
                <FormControlLabel control={<CloudRadio />} disabled={(this.state.linktype !== "youtube")} value="youtube" label="Youtube Video" />
                <FormControlLabel control={<CloudRadio />} disabled={(this.state.linktype !== "tiktok")}  value="tiktok"  label="TikTok Video" />
                <FormControlLabel control={<CloudRadio />} disabled={(this.state.linktype !== "vimeo")}   value="vimeo"   label="Vimeo Video" />
                <FormControlLabel control={<CloudRadio />} disabled={(this.state.linktype !== "pornhub")} value="pornhub" label="Pornhub Video" />
                <FormControlLabel control={<CloudRadio />} disabled={(this.state.linktype !== "xvideos")} value="xvideos" label="XVIDEOS Video" />
              </RadioButtonGroup>
              <CloudChipInput className="Cloud-Input" id="tag" name="tag" label="Tags" onChange={(chips) => this.handleChange(chips)} defaultValue={ this.state.linktag } />
            </Modal.Description>
          </Modal.Content>
          <Modal.Actions>
            <Button negative icon='close' labelPosition='right' content="Cancel" onClick={this._closeModal} />
            <Button type="submit" positive icon='checkmark' labelPosition='left' content="Update" onClick={this._showContent} />
          </Modal.Actions>
      </Modal>
    );
  }
}