import React, { Component } from "react";
import {
  Header,
  Image,
  Divider,
  Button,
  Icon,
  Segment,
} from "semantic-ui-react";
import Handshake from "../images/svg/payed2.svg";
import "../css/page-404.css";

export default class Payed extends Component {
  render() {
    return (
      <Segment compact className="page404" textAlign="center">
        <Divider hidden />
        <Header as="h1" textAlign="center" color="green">
          Purchase Successfully Completed
        </Header>
        <Image src={Handshake} size="medium" />
        <Divider hidden />
        <Button as="a" color="blue" href="/">
          <Icon name="home" />
          Go Home
        </Button>
        <Divider hidden />
      </Segment>
    );
  }
}
