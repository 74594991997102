import react from "react";
import {
  Button,
  Menu,
  Icon,
  Dropdown,
  Input,
  Segment,
} from "semantic-ui-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Show from "./Show";
const Toolbar = ({
  filesList,
  disable,
  download,
  activeIndex,
  activeLayout,
  isAuth,
  mode,
  activeItem,
  ram,
  ramAction,
  searchBox,
  tag,
  _setTag,
  controlWindow,
  _mobileMenu,
  mobileMenu,
  gotoMobile,
  searchText,
  handleLayoutClick,
  paste,
  showUpload,
  showFolder,
  showLink,
  showEdit,
  showDelete,
  CutOrCopy,
  showDownload,
  setSelectFiles,
  selectFile,
}) => {
  return (
    <div className="cloudMenuInner">
      {mode !== "mobile" && (
        <div className="left">
          <Menu icon="labeled" size="mini" color="blue">
            <Menu.Item
              id="uploadFiles"
              disabled={isAuth === false}
              name="upload"
              active={activeItem === "upload"}
              onClick={showUpload}
            >
              <Icon name="cloud upload" />
              <span>Upload</span>
            </Menu.Item>

            <Menu.Item
              disabled={isAuth === false}
              name="create folder"
              active={activeItem === "create folder"}
              onClick={showFolder("blurring")}
            >
              <Icon name="folder" />
              <span>Create folder</span>
            </Menu.Item>

            <Menu.Item
              disabled={isAuth === false}
              name="add"
              active={activeItem === "add"}
              onClick={showLink("blurring")}
            >
              <Icon name="add" />
              <span>Add</span>
            </Menu.Item>

            <Menu.Item
              disabled={disable}
              name="edit"
              active={activeItem === "edit"}
              onClick={showEdit("blurring")}
            >
              <Icon name="edit" />
              <span>Edit</span>
            </Menu.Item>

            <Menu.Item
              disabled={disable}
              name="delete"
              active={activeItem === "delete"}
              onClick={showDelete("blurring")}
            >
              <Icon name="delete" />
              <span>Delete</span>
            </Menu.Item>

            <Menu.Item
              disabled={disable}
              name="copy"
              active={activeItem === "copy"}
              onClick={() => CutOrCopy(filesList, "copy")}
            >
              <Icon name="copy" />
              <span>Copy</span>
            </Menu.Item>

            <Menu.Item
              disabled={disable}
              name="cut"
              active={activeItem === "cut"}
              onClick={() => CutOrCopy(filesList, "cut")}
            >
              <Icon name="cut" />
              <span>Cut</span>
            </Menu.Item>

            <Menu.Item
              disabled={ram.length > 0 && ramAction !== null ? false : true}
              name="paste"
              active={activeItem === "paste"}
              onClick={() => paste()}
            >
              <Icon name="paste" />
              <span>Paste</span>
            </Menu.Item>

            <Menu.Item
              disabled={download}
              name="download"
              active={activeItem === "download"}
              onClick={() => showDownload()}
            >
              <Icon name="download" />
              <span>Download</span>
            </Menu.Item>
          </Menu>
        </div>
      )}

      {mode !== "fixed" && (
        <div className="right">
          <Menu size="mini">
            <Menu.Item>
              <Button.Group>
                {activeLayout === "list" && (
                  <Button
                    name="block-layout"
                    icon
                    active={activeLayout === "block"}
                    onClick={handleLayoutClick}
                    role="button"
                    aria-label="Block layout"
                  >
                    <Icon name="block layout" />
                  </Button>
                )}
                {activeLayout === "block" && (
                  <Button
                    name="list-layout"
                    icon
                    active={activeLayout === "list"}
                    onClick={handleLayoutClick}
                    role="button"
                    aria-label="List layout"
                  >
                    <Icon name="list layout" />
                  </Button>
                )}
              </Button.Group>
            </Menu.Item>
            <Menu.Item>
              <Dropdown icon="filter" floating button className="icon">
                <Dropdown.Menu>
                  <Dropdown.Item
                    icon="delete"
                    text="Clear"
                    onClick={() => _setTag("")}
                  />
                  <Dropdown.Header icon="tags" content="Filter by tag" />
                  <Dropdown.Divider />
                  {tag.map((option) => (
                    <Dropdown.Item
                      key={option}
                      text={option}
                      value={option}
                      onClick={() => _setTag(option)}
                    />
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </Menu.Item>

            {mode !== "mobile" && (
              <Menu.Item>
                <label aria-label="search">
                  <Input
                    className="icon"
                    size="mini"
                    icon="search"
                    placeholder="Search in folder"
                    name="search"
                    onChange={(e) => searchText(e)}
                    value={searchBox}
                  />
                </label>
              </Menu.Item>
            )}

            {mode === "mobile" && (
              <Menu.Item className="menuTraget">
                <Dropdown
                  icon="target"
                  text="Menu"
                  floating
                  labeled
                  button
                  className="icon"
                >
                  <Dropdown.Menu>
                    <Dropdown.Item
                      icon="cloud upload"
                      text="Upload"
                      disabled={isAuth === false}
                      onClick={showUpload}
                    />
                    <Dropdown.Item
                      icon="folder"
                      text="Create folder"
                      disabled={isAuth === false}
                      onClick={showFolder("blurring")}
                    />
                    <Dropdown.Item
                      icon="add"
                      text="Add"
                      disabled={isAuth === false}
                      onClick={showLink("blurring")}
                    />
                    <Dropdown.Item
                      icon="edit"
                      text="Edit"
                      disabled={disable}
                      onClick={showEdit("blurring")}
                    />
                    <Dropdown.Item
                      icon="delete"
                      text="Delete"
                      disabled={disable}
                      onClick={showDelete("blurring")}
                    />
                    <Dropdown.Item
                      icon="copy"
                      text="Copy"
                      disabled={disable}
                      onClick={() => CutOrCopy(filesList, "copy")}
                    />
                    <Dropdown.Item
                      icon="cut"
                      text="Cut"
                      disabled={disable}
                      onClick={() => CutOrCopy(filesList, "cut")}
                    />
                    <Dropdown.Item
                      icon="paste"
                      text="Paste"
                      disabled={
                        ram.length > 0 && ramAction !== null ? false : true
                      }
                      onClick={() => paste()}
                    />
                    <Dropdown.Item
                      icon="download"
                      text="Download"
                      disabled={download}
                      onClick={() => showDownload()}
                    />
                    {!selectFile && (
                      <Dropdown.Item
                        icon="checkmark box"
                        text="Select..."
                        onClick={() => setSelectFiles(true)}
                      />
                    )}
                    {selectFile && (
                      <Dropdown.Item
                        icon="square outline"
                        text="Clear Selection"
                        onClick={() => setSelectFiles(false)}
                      />
                    )}
                  </Dropdown.Menu>
                </Dropdown>
              </Menu.Item>
            )}
          </Menu>

          {mode === "mobile" && (
            <Segment>
              <Menu.Item>
                <label aria-label="search">
                  <Input
                    className="icon searchFolder"
                    fluid
                    icon="search"
                    placeholder="Search in folder"
                    name="search"
                    onChange={(e) => searchText(e)}
                    value={searchBox}
                  />
                </label>
              </Menu.Item>
            </Segment>
          )}
        </div>
      )}

      {mode === "mobile" && (
        <div>
          {!mobileMenu && activeIndex[0] !== -1 && (
            <Show
              objectElement={filesList}
              objectNumber={activeIndex}
              controlWindow={controlWindow}
              gotoMobile={gotoMobile}
            />
          )}
          <button
            name="circle-menu-button"
            aria-label="Circle menu button"
            className="circle-menu-button"
            onClick={() => _mobileMenu()}
          >
            <FontAwesomeIcon icon="fingerprint" />{" "}
          </button>
          <div
            className={
              mobileMenu
                ? "circle-menu-wrap circle-menu-opened-nav"
                : "circle-menu-wrap"
            }
          >
            <ul className="circle-menu">
              <li
                disabled={isAuth === false}
                name="add"
                onClick={showLink("blurring")}
              >
                <div className="text">
                  <Icon name="add" size="large" />
                </div>
              </li>

              <li disabled={disable} name="edit" onClick={showEdit("blurring")}>
                <div className="text">
                  <Icon name="edit" size="large" />
                </div>
              </li>

              <li
                disabled={disable}
                name="delete"
                onClick={showDelete("blurring")}
              >
                <div className="text">
                  <Icon name="delete" size="large" />
                </div>
              </li>

              <li>
                <div className="text"></div>
              </li>
              <li>
                <div className="text"></div>
              </li>
              <li>
                <div className="text"></div>
              </li>
              <li>
                <div className="text"></div>
              </li>
              <li>
                <div className="text"></div>
              </li>

              <li
                disabled={isAuth === false}
                name="upload"
                onClick={showUpload}
              >
                <div className="text">
                  <Icon name="cloud upload" size="large" />
                </div>
              </li>

              <li
                disabled={isAuth === false}
                name="create folder"
                onClick={showFolder("blurring")}
              >
                <div className="text">
                  <Icon name="folder" size="large" />
                </div>
              </li>
            </ul>

            <ul className="circle-menu-small">
              <li
                disabled={ram.length > 0 && ramAction !== null ? false : true}
                name="paste"
                onClick={() => paste()}
              >
                <div className="text">
                  <Icon name="paste" />
                </div>
              </li>

              <li
                disabled={download}
                name="download"
                onClick={(e) => showDownload(e)}
              >
                <div className="text">
                  <Icon name="download" />
                </div>
              </li>

              {!selectFile && (
                <li name="select" onClick={() => setSelectFiles(true)}>
                  <div className="text">
                    <Icon name="checkmark box" />
                  </div>
                </li>
              )}
              {selectFile && (
                <li name="unselect" onClick={() => setSelectFiles(false)}>
                  <div className="text">
                    <Icon name="square outline" />
                  </div>
                </li>
              )}

              <li>
                <div className="text"></div>
              </li>
              <li>
                <div className="text"></div>
              </li>
              <li>
                <div className="text"></div>
              </li>
              <li>
                <div className="text"></div>
              </li>
              <li>
                <div className="text"></div>
              </li>

              <li
                disabled={disable}
                name="copy"
                onClick={() => CutOrCopy(filesList, "copy")}
              >
                <div className="text">
                  <Icon name="copy" />
                </div>
              </li>

              <li
                disabled={disable}
                name="cut"
                onClick={() => CutOrCopy(filesList, "cut")}
              >
                <div className="text">
                  <Icon name="cut" />
                </div>
              </li>
            </ul>
          </div>
        </div>
      )}
    </div>
  );
};

export default Toolbar;
