import React, { Component } from 'react';
import { Button, Image } from 'semantic-ui-react';
import Eye from '../images/eye.svg';

export default class Show extends Component {

	openFile = () => {
		const { objectElement, objectNumber } = this.props;
		let selected = null;

		if(objectNumber.length > 1){
			selected = objectNumber[objectNumber.length - 1];
		} else {
			selected = objectNumber[0];
		}

		const element = objectElement[selected];
		console.log("objectNumber: ", selected);
		console.log("element: ", element);
		
		if(element.linktype === "website" || element.linktype === "webapp" || element.linktype === "webgame"){
			window.open(element.linkurl); 
			return false;
		}
		else if(element.linktype === "folder"){
			this.props.gotoMobile(element.linkname);
		}
		else {
			this.props.controlWindow(element, "add", objectElement);
		} 
	}

	render() {

		return (
			<Button circular className="open-element open" onClick={ this.openFile.bind(this) } ><Image size="mini" src={Eye} alt="" /></Button>
		);
	}
}