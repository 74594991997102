import React, { Component, lazy, Suspense } from "react";
import {
  Grid,
  Header,
  Image,
  Statistic,
  Checkbox,
  Label,
  Divider,
  Icon,
  Popup,
} from "semantic-ui-react";
import CountUp from "react-countup";
import { Pie } from "react-chartjs-2";
import { isMobile } from "react-device-detect";
import { bytesToSize, siteTitle } from "../js/functions";
import { checkUsage, accountOptions } from "../components/Api";
import ControlPanel from "../components/ControlPanel";

import "../css/account.css";

const GoogleAds = lazy(() => import("../components/GoogleAds"));

class ProgressBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      start: 0,
    };
  }

  percentage = (x, y) => {
    return (x / y) * 100;
  };

  animateValue = () => {
    let start = this.state.start,
      end = Math.floor(this.percentage(this.props.used, this.props.total)),
      duration = this.props.duration,
      range = end - start,
      current = start,
      increment = end > start ? 1 : -1,
      stepTime = Math.abs(Math.floor(duration / range)),
      parent = this;
    let interval = setInterval(function () {
      current += increment;

      if (current === end || end < 1) {
        clearInterval(parent.state.interval);
      }

      parent.setState({
        start: parent.state.start + 1,
      });
    }, stepTime);

    this.setState({ interval: interval });
  };

  componentDidMount() {
    if (this.props.used !== 0) this.animateValue();
  }

  componentWillUnmount() {
    clearInterval(this.state.interval);
  }

  render() {
    let used = bytesToSize(this.props.used);
    let total = bytesToSize(this.props.total);
    return (
      <div>
        <div className="SimpleProgressBarWrap">
          <div className="innerBar" style={{ width: this.state.start + "%" }}>
            <div className="progressNumber">{this.state.start}%</div>
          </div>
        </div>

        <strong>
          <CountUp start={0} end={used.size} duration={1} suffix={used.unit} />{" "}
          of {total.size + total.unit} Used
        </strong>
      </div>
    );
  }
}

export default class Account extends Component {
  constructor(props) {
    super(props);

    this._componentWillMount();

    this.state = {
      disable: false,
    };
  }

  _componentWillMount() {
    checkUsage().then((usage) => this.setState({ ...usage.data.result }));
  }

  toggle = (val) => {
    switch (val) {
      case "animation":
        let animation = !this.state.animation;
        this.setState({ animation: animation, disabled: true });
        this._setOptions({ animation: animation });
        break;
      case "darkMode":
        let darkMode = !this.state.darkMode;
        this.setState({ darkMode: darkMode, disabled: true });
        this._setOptions({ darkMode: darkMode });
        this.props.darkMode(darkMode);
        break;
      case "news":
        let news =
          typeof this.state.news === "undefined" ? false : !this.state.news;
        this.setState({ news: news, disabled: true });
        this._setOptions({ news: news });
        break;
      case "sport":
        let sport =
          typeof this.state.sport === "undefined" ? false : !this.state.sport;
        this.setState({ sport: sport, disabled: true });
        this._setOptions({ sport: sport });
        break;
      case "business":
        let business =
          typeof this.state.business === "undefined"
            ? false
            : !this.state.business;
        this.setState({ business: business, disabled: true });
        this._setOptions({ business: business });
        break;
      case "entertainment":
        let entertainment =
          typeof this.state.entertainment === "undefined"
            ? false
            : !this.state.entertainment;
        this.setState({ entertainment: entertainment, disabled: true });
        this._setOptions({ entertainment: entertainment });
        break;
      case "fashion":
        let fashion =
          typeof this.state.fashion === "undefined"
            ? false
            : !this.state.fashion;
        this.setState({ fashion: fashion, disabled: true });
        this._setOptions({ fashion: fashion });
        break;
      case "tech":
        let tech =
          typeof this.state.tech === "undefined" ? false : !this.state.tech;
        this.setState({ tech: tech, disabled: true });
        this._setOptions({ tech: tech });
        break;
      case "living":
        let living =
          typeof this.state.living === "undefined" ? false : !this.state.living;
        this.setState({ living: living, disabled: true });
        this._setOptions({ living: living });
        break;
      case "realEstate":
        let realEstate =
          typeof this.state.realEstate === "undefined"
            ? false
            : !this.state.realEstate;
        this.setState({ realEstate: realEstate, disabled: true });
        this._setOptions({ realEstate: realEstate });
        break;
      case "defaultLinks":
      default:
        let link = !this.state.defaultLinks;
        this.setState({ defaultLinks: link, disabled: true });
        this.props.accountUsageAction("defaultLinks", link);
        this._setOptions({ defaultLinks: link });
        break;
    }
  };

  _setOptions(obj) {
    accountOptions(obj).then((res) => {
      console.log(res);
      this.setState({ disabled: false });
      /*  if(res.hasOwnProperty("data") && res.data.hasOwnProperty("docs") && res.data.docs.ok){
        this.setState({ disabled: false });
      } else {
        var unkownKey = Object.keys(obj)[0];
        obj[unkownKey] = !obj[unkownKey];
        obj["disabled"] = false;
        this.setState(obj);
      } */
    });
  }

  render() {
    const { profile } = this.props,
      {
        news,
        sport,
        business,
        entertainment,
        fashion,
        tech,
        living,
        realEstate,
        defaultLinks,
        planSize,
        totalSize,
        available,
        Documents,
        Images,
        Links,
        Music,
        Other,
        Videos,
        Youtube,
        disable,
        description,
        darkMode,
      } = this.state,
      total = planSize,
      used = totalSize,
      availableSpace = available,
      usedConverted = bytesToSize(used),
      availableConverted = bytesToSize(availableSpace),
      data = {
        labels: [
          "Images",
          "Videos",
          "Music",
          "Links",
          "Youtube",
          "Documents",
          "Other",
        ],
        datasets: [
          {
            data: [Images, Videos, Music, Links, Youtube, Documents, Other],
            backgroundColor: [
              "#FF6384",
              "#4BC0C0",
              "#FFCE56",
              "#FF851B",
              "#36A2EB",
              "#B10DC9",
              "#E5E5E5",
            ],
          },
        ],
      },
      bar =
        typeof total !== undefined &&
        typeof total !== "undefined" &&
        typeof used !== undefined &&
        typeof used !== "undefined" ? (
          <ProgressBar used={used} total={total} duration={1000} />
        ) : (
          <div className="SimpleProgressBarWrap"></div>
        ),
      emptyPie =
        Images + Videos + Music + Links + Youtube + Documents + Other === 0 ? (
          <div className="circle">Empty</div>
        ) : null;

    return (
      <div className="account">
        <Grid centered>
          <Grid.Column mobile={16} tablet={12} computer={8}>
            <Header as="h1">
              <Image circular bordered src={profile.picture} /> {profile.name}{" "}
              account
            </Header>

            <Divider hidden />

            <ul>
              <li>
                <Checkbox
                  disabled={disable}
                  toggle
                  label="Show default elements."
                  value="defaultLinks"
                  onChange={() => this.toggle("defaultLinks")}
                  checked={defaultLinks}
                />{" "}
                <Popup
                  trigger={
                    <Icon
                      name="question circle"
                      size="large"
                      className="pointer"
                    />
                  }
                  on="click"
                  content={
                    "Hide the default elements in the main page that comes with " +
                    siteTitle +
                    " like: Youtube, Facebook, Gmail & etc..."
                  }
                />
              </li>
              <li>
                <Checkbox
                  disabled={disable}
                  toggle
                  label="Dark appearance."
                  value="darkMode"
                  onChange={() => this.toggle("darkMode")}
                  checked={darkMode}
                />{" "}
              </li>
              {/*   <li><Checkbox disabled={ disable } toggle label='Enable animation.' value='animation' onChange={()=>this.toggle('animation')} checked={animation} /> </li> */}
              <li>
                <Label size="large" horizontal className="status">
                  Status:
                </Label>{" "}
                <span>{description}</span>
              </li>
            </ul>

            {bar}

            <Statistic.Group size="small">
              <Statistic color="green">
                <Statistic.Label>Available</Statistic.Label>
                <Statistic.Value>
                  <CountUp
                    start={0}
                    end={availableConverted.size}
                    duration={1}
                    suffix={availableConverted.unit}
                  />
                </Statistic.Value>
              </Statistic>
              <Statistic color="red">
                <Statistic.Label>Used</Statistic.Label>
                <Statistic.Value>
                  <CountUp
                    start={0}
                    end={usedConverted.size}
                    duration={1}
                    suffix={usedConverted.unit}
                  />
                </Statistic.Value>
              </Statistic>
            </Statistic.Group>

            <div className="news-settings">
              <Header as="h2">Show News</Header>{" "}
              <Popup
                trigger={
                  <Icon
                    name="question circle"
                    size="large"
                    className="pointer"
                  />
                }
                position={isMobile ? "top center" : "top left"}
                on="click"
                content={
                  "Select which news do you want to see when you click on the News icon"
                }
              />
              <ul>
                <li>
                  <Checkbox
                    disabled={disable}
                    toggle
                    label="News"
                    value="news"
                    onChange={() => this.toggle("news")}
                    checked={typeof news === "undefined" ? true : news}
                  />{" "}
                </li>
                <li>
                  <Checkbox
                    disabled={disable}
                    toggle
                    label="Sport"
                    value="sport"
                    onChange={() => this.toggle("sport")}
                    checked={typeof sport === "undefined" ? true : sport}
                  />{" "}
                </li>
                <li>
                  <Checkbox
                    disabled={disable}
                    toggle
                    label="Business"
                    value="business"
                    onChange={() => this.toggle("business")}
                    checked={typeof business === "undefined" ? true : business}
                  />{" "}
                </li>
                <li>
                  <Checkbox
                    disabled={disable}
                    toggle
                    label="Entertainment"
                    value="entertainment"
                    onChange={() => this.toggle("entertainment")}
                    checked={
                      typeof entertainment === "undefined"
                        ? true
                        : entertainment
                    }
                  />{" "}
                </li>
                <li>
                  <Checkbox
                    disabled={disable}
                    toggle
                    label="Fashion"
                    value="fashion"
                    onChange={() => this.toggle("fashion")}
                    checked={typeof fashion === "undefined" ? true : fashion}
                  />{" "}
                </li>
                <li>
                  <Checkbox
                    disabled={disable}
                    toggle
                    label="Tech"
                    value="tech"
                    onChange={() => this.toggle("tech")}
                    checked={typeof tech === "undefined" ? true : tech}
                  />{" "}
                </li>
                <li>
                  <Checkbox
                    disabled={disable}
                    toggle
                    label="Living"
                    value="living"
                    onChange={() => this.toggle("living")}
                    checked={typeof living === "undefined" ? true : living}
                  />{" "}
                </li>
                <li>
                  <Checkbox
                    disabled={disable}
                    toggle
                    label="Real Estate"
                    value="realEstate"
                    onChange={() => this.toggle("realEstate")}
                    checked={
                      typeof realEstate === "undefined" ? true : realEstate
                    }
                  />{" "}
                </li>
              </ul>
            </div>

            <Suspense fallback={""}>
              <GoogleAds />
            </Suspense>

            <div className="dataUsage">
              {emptyPie}
              <Pie data={data} />
            </div>
            <Divider hidden />

            {profile.sub === "google-oauth2|110890134146877110175" && (
              <ControlPanel
                _addNotification={this.props._addNotification}
                showDimmer={this.props.showDimmer}
              />
            )}

            <Divider hidden />
          </Grid.Column>
        </Grid>
      </div>
    );
  }
}
