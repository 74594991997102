import React, { Component } from "react";
import { Container, Grid, Header, Icon, Divider } from "semantic-ui-react";
import { sitePrimaryDomain, siteTitle } from "../js/functions";

import "../css/pages.css";

export default class Terms extends Component {
  render() {
    return (
      <div className="terms">
        <Container fluid textAlign="center">
          <Divider hidden />
          <Header as="h1">
            <Icon name="drivers license" /> Terms of Service
          </Header>
          <Divider hidden />
        </Container>
        <Grid centered padded>
          <Grid.Column mobile={16} tablet={16} computer={8}>
            <Header as="h2">1. Terms</Header>
            <p>
              By accessing the website at{" "}
              <a href={sitePrimaryDomain}>{sitePrimaryDomain}</a>, you are
              agreeing to be bound by these terms of service, all applicable
              laws and regulations, and agree that you are responsible for
              compliance with any applicable local laws. If you do not agree
              with any of these terms, you are prohibited from using or
              accessing this site. The materials contained in this website are
              protected by applicable copyright and trademark law.
            </p>
            <Header as="h2">2. Use License</Header>
            <ol type="a">
              <li>
                Permission is granted to temporarily download one copy of the
                materials (information or software) on {siteTitle}' website for
                personal, non-commercial transitory viewing only. This is the
                grant of a license, not a transfer of title, and under this
                license you may not:
                <ol type="i">
                  <li>modify or copy the materials;</li>
                  <li>
                    use the materials for any commercial purpose, or for any
                    public display (commercial or non-commercial);
                  </li>
                  <li>
                    attempt to decompile or reverse engineer any software
                    contained on {siteTitle}' website;
                  </li>
                  <li>
                    remove any copyright or other proprietary notations from the
                    materials; or
                  </li>
                  <li>
                    transfer the materials to another person or "mirror" the
                    materials on any other server.
                  </li>
                </ol>
              </li>
              <li>
                This license shall automatically terminate if you violate any of
                these restrictions and may be terminated by {siteTitle} at any
                time. Upon terminating your viewing of these materials or upon
                the termination of this license, you must destroy any downloaded
                materials in your possession whether in electronic or printed
                format.
              </li>
            </ol>
            <Header as="h2">3. Disclaimer</Header>
            <ol type="a">
              <li>
                The materials on {siteTitle}' website are provided on an 'as is'
                basis. {siteTitle} makes no warranties, expressed or implied,
                and hereby disclaims and negates all other warranties including,
                without limitation, implied warranties or conditions of
                merchantability, fitness for a particular purpose, or
                non-infringement of intellectual property or other violation of
                rights.
              </li>
              <li>
                Further, {siteTitle} does not warrant or make any
                representations concerning the accuracy, likely results, or
                reliability of the use of the materials on its website or
                otherwise relating to such materials or on any sites linked to
                this site.
              </li>
            </ol>
          </Grid.Column>

          <Grid.Column mobile={16} tablet={16} computer={8}>
            <Header as="h2">4. Limitations</Header>
            <p>
              In no event shall {siteTitle} or its suppliers be liable for any
              damages (including, without limitation, damages for loss of data
              or profit, or due to business interruption) arising out of the use
              or inability to use the materials on {siteTitle}' website, even if{" "}
              {siteTitle} or a {siteTitle} authorized representative has been
              notified orally or in writing of the possibility of such damage.
              Because some jurisdictions do not allow limitations on implied
              warranties, or limitations of liability for consequential or
              incidental damages, these limitations may not apply to you.
            </p>
            <Header as="h2">5. Accuracy of materials</Header>
            <p>
              The materials appearing on {siteTitle}' website could include
              technical, typographical, or photographic errors. {siteTitle} does
              not warrant that any of the materials on its website are accurate,
              complete or current. {siteTitle} may make changes to the materials
              contained on its website at any time without notice. However{" "}
              {siteTitle} does not make any commitment to update the materials.
            </p>
            <Header as="h2">6. Links</Header>
            <p>
              {siteTitle} has not reviewed all of the sites linked to its
              website and is not responsible for the contents of any such linked
              site. The inclusion of any link does not imply endorsement by{" "}
              {siteTitle} of the site. Use of any such linked website is at the
              user's own risk.
            </p>
            <Header as="h2">7. Modifications</Header>
            <p>
              {siteTitle} may revise these terms of service for its website at
              any time without notice. By using this website you are agreeing to
              be bound by the then current version of these terms of service.
            </p>
            <Header as="h2">8. Governing Law</Header>
            <p>
              These terms and conditions are governed by and construed in
              accordance with the laws of Israel and you irrevocably submit to
              the exclusive jurisdiction of the courts in that State or
              location.
            </p>
          </Grid.Column>
        </Grid>

        <Divider hidden />
      </div>
    );
  }
}
