import React, { Component } from 'react';
import ModalWindow from './ModalWindow';

export default class WindowsLooper extends Component{
  static defaultProps = {
    windowInnerWidth: window.innerWidth
  }

  state = { activeId: 0 }

  activeClick = (id) => {
    this.setState({ activeId: id })
  }

  render(){
    const { windows, controlWindow } = this.props;
    const { activeId } = this.state
    let miniCount = Math.floor(this.props.windowInnerWidth / 150);
    let miniWidth = this.props.windowInnerWidth / miniCount;
    let miniWindowsCounter = 0;
    let windowsList = "";

    if(Object.keys(windows).length > 0){
        windowsList =  Object.keys(windows).map(window => {
          console.log("windows[window]: ",windows[window]);
        //    let randomNum = windows[window]._id + Math.random().toString(32).slice(2);
            return <ModalWindow minimizedIndex={ (typeof windows[window].minimize !== undefined && typeof windows[window].minimize !== 'undefined' && windows[window].minimize !== false) ? ++miniWindowsCounter : "" } 
                          key={ windows[window]._id } 
                          id={windows[window]._id} 
                          name={ windows[window].linkname } 
                          url={ windows[window].linkurl } 
                          controlWindow={ controlWindow } 
                          activeClick={ this.activeClick } 
                          active={activeId === windows[window]._id} 
                          miniCount={miniCount} 
                          miniWidth={miniWidth}
                          element={windows[window]} 
                          showDimmer={ this.props.showDimmer } 
                          _addNotification={this.props._addNotification} />
          
        }
      );
    }

    return(
      <div className="windowsList">
      { windowsList }
      </div>
    );
  }
}