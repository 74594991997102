import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import RadioButton from '@material-ui/core/Radio';
import ChipInput from 'material-ui-chip-input';

const CloudColor = '#1FBCD3';

export const domainS3 = "https://data.cloudos.cloud/"; // "https://s3.amazonaws.com/os5-cloud/";  //"https://data.cloudos5.com/"; //// d1e30qpttx1ziq.cloudfront.net

//export const domainS3WithoutCach = "https://s3.amazonaws.com/os5-cloud/";

export const maximumFileSize = 524288000; // 500mb //10485760; // 10MB in binary

export const sitePrimaryDomain = window.location.protocol + "//" + window.location.hostname + ((window.location.port === "") ? "" : ":") + window.location.port; //"https://www.CloudOS5.com"; // "https://cloudos5.herokuapp.com"; 

export const siteTitle = "Cloud OS";

export const siteCategory = "Web App";

export const unsafeCharactersArray = ['@', '#', '!', '$', '%', '^', '&', '*', '(', ')', '+', '/', "'", '"', '{','}','[',']','<','>','?','=',';',':','`', '™', '€', '‰', '•', '‡', '†', '“', 'Œ','œ','Š','š','Ÿ','ƒ', '¡','¢',
'£','¤','¥','¦','§','¨','©','ª','«','¬','®','¯'];

export const onlinePlayVideoFileTypes = ['video/mp4', 'video/quicktime', 'video/webm', 'video/ogg'];

export const onlineConvertVideoFileTypes = ['video/avi', 'video/msvideo', 'video/x-msvideo', 'application/x-troff-msvideo'];

export const convertVideoMaximumFileSize = 524288000; // 500mb // 10485760; // 10MB in binary or "all"

export const websiteScreenshot = true;

export const calculateAspectRatioFit = (srcWidth, srcHeight, maxWidth = 122, maxHeight = 91) => { 
  srcWidth = Math.round(srcWidth);
  srcHeight = Math.round(srcHeight);
  var ratio = Math.min(maxWidth / srcWidth, maxHeight / srcHeight);
  return { width: Math.round(srcWidth*ratio), height: Math.round(srcHeight*ratio) };
}  

export const getCurrentUrl = () => {
  const url = window.location.href;
  if(url.indexOf("/allfiles") < 1) return "";
  let path = url.slice((url.indexOf("/allfiles") + 9), url.length);
  return (path === "/" || path === "") ? "/" : path;
}

export const bytesToSize = (bytes,decimals) => {
  if(bytes === 0) return { size: 0, unit: 'KB' };
  var k = 1024,
      dm = decimals || 2,
      sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
      i = Math.floor(Math.log(bytes) / Math.log(k));
  return { size: parseFloat((bytes / Math.pow(k, i)).toFixed(dm)), unit: sizes[i] };
}

export const formatDate = (date) => {
  date = new Date(date);
  var day = date.getDate();
  var year = date.getFullYear();

  return date.toLocaleString("en-us", { month: "short" }) + ' ' + day + ', ' + year;
}


export const getFileType = (file) => {
  let fileExtension = file.slice(file.lastIndexOf(".") + 1, file.length);
  let type = "";
  switch(fileExtension.toLowerCase()){
    case "docx":
      type = "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
      break;
    case "doc":
      type = "application/msword";
      break;
    case "ppt":
      type = 'application/vnd.ms-powerpoint';
      break;
    case "pptx":
      type = 'application/vnd.openxmlformats-officedocument.presentationml.presentation';
      break;
    case "xls":
      type = "application/vnd.ms-excel";
      break;
    case "xlsx":
      type = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
      break;
    case "pdf":
      type = 'application/pdf';
      break;
    case "txt":
      type = 'text/plain';
      break;
    case "mp3":
      type = 'audio/mpeg';
      break;
    case "wav":
      type = 'audio/wav';
      break;
    case "flac":
      type = 'audio/flac';
      break;
    case "mkv":
      type = "video/x-matroska";
      break;
    case "mp4":
      type = "video/mp4";
      break;
    case "avi":
      type = "video/avi";
      break;
    case "mov":
      type = "video/quicktime";  
      break;
    case "3gp":
      type = "video/3gpp";  
      break;
    case "ts":
      type = "video/MP2T";  
      break;
    case "m3u8":
      type = "application/x-mpegURL";  
      break;
    case "jpeg":
    case "jpg":
      type = "image/jpeg";  
      break;
    case "tiff":
      type = "image/tiff";  
      break;
    case "bmp":
      type = "image/bmp";  
      break;
    case "svg":
      type = "image/svg+xml";  
      break;
    case "gif":
      type = "image/gif";  
      break;
    case "png":
      type = "image/png";  
      break;
    case "eps":
    case "ai":
      type = "application/postscript";  
      break;
    case "psd":
      type = "application/octet-stream";  
      break;
    case "zip":
      type = "application/zip";  
      break;
    case 'webm':
      type = 'video/webm';
      break;
    case 'ogv':
      type = 'video/ogg';
      break;
    default:
      type = "";
  }
  return type;
}


export const removeUnsafeChars = (str) => {
  return str.replace(/[^\w\s-.]/gi, '').replace(/\s\s+/g, ' ');
}

export const spaceToPlus = (str) => {
  if(str.includes(" ")){
    if(str.includes("/")){
      let arr = str.split("/");
      arr[arr.length - 1] = arr[arr.length - 1].trim().replace(/ /g, "+");
      str = arr.join("/");
    } else {
      str = str.trim().replace(/ /g, "+");
    }
  }
  return str;
}

export const plusToSpace = (str) =>{
  if(str.includes("+")){
    if(str.includes("/")){
      let arr = str.split("/");
      arr[arr.length - 1] = arr[arr.length - 1].trim().replace(/\+/g, " ");
      str = arr.join("/");
    } else {
      str = str.trim().replace(/\+/g, " ")
    }
  }
  return str;
}

export const checkUnSafeCharacters = (str) => {
  for (var i = 0; i !== unsafeCharactersArray.length; i++) {
      if (str.indexOf(unsafeCharactersArray[i]) !== - 1) {
        return true;
      }
  }
  return false; 
}

export const replaceUnSafeCharacters = (str) => {  
 /* for (var i = 0; i !== unsafeCharactersArray.length; i++) {
    str = str.replace(unsafeCharactersArray[i],"");
  } */
  return str.replace(/[[\]&/\\@#!,+&()$~^%'":*?=<>{}`™€‰•‡†“ŒœŠšŸƒ¡¢£¤¥¦§¨©ª«¬®¯]/g,'');
}

export const relogin = () => {
  localStorage.removeItem('access_token');
  localStorage.removeItem('id_token');
  localStorage.removeItem('expires_at');
//  window.history.replace('/');
  window.location.assign('/#relogin');
  return false;
}

export const stripSignedURL = (str) =>{
  if(str.includes("?")){
    str = str.slice(0, str.lastIndexOf("?"));
  }
  return str;
}


export const decodeHtmlCharCodes = str => str.replace(/(&#(\d+);)/g, (match, capture, charCode) => String.fromCharCode(charCode));

export const from24to12 = (time) => {
  // Check correct time format and split into components
  time = time.toString ().match (/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

  if (time.length > 1) { // If time format correct
    time = time.slice (1);  // Remove full string match value
    time[5] = +time[0] < 12 ? 'am' : 'pm'; // Set AM/PM
    time[0] = +time[0] % 12 || 12; // Adjust hours
  }
  return time.join (''); // return adjusted time or original string
}


export const CloudRadio = withStyles({
  root: {
    color: '#333',
    '&$checked': {
      color: CloudColor,
    },
    "&&:hover": {
      backgroundColor: 'transparent'
    }    
  },
  checked: {},
})(RadioButton);;

export const CloudTextField = withStyles({
  root: {
    '& label.Mui-focused': {
      color: CloudColor
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: CloudColor
    },
    '& .MuiInput-underline:hover': {
      borderBottomColor: CloudColor
    },
    '& .MuiFormHelperText-root': {
      color: 'red'
    } 
  }
})(TextField);

export const CloudChipInput = withStyles({
  root: {
    '& label.Mui-focused': {
      color: CloudColor
    },
    '& div:after': {
      borderBottomColor: CloudColor
    }
  }
})(ChipInput);

export const randomKey = () => {
  return Math.random().toString(32).slice(2);
}
