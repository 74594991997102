import React, { Component } from 'react';
import { Modal, Header, Button } from 'semantic-ui-react';
import RadioButtonGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import * as Api from './Api';
import { removeUnsafeChars, CloudRadio, CloudTextField, CloudChipInput } from '../js/functions';

const GOOGLE_API_KEY = 'AIzaSyAWMj2QJlk55E3KzJornhShGKIjcRSd_oM';


export default class LinkModal extends Component { 
  constructor() {
      super();
      this.state = {
          linkname: '',
          linkurl: '',
          linktype: 'website',
          linktag: [],
          submitted: false,
          urlVal: "",
          nameVal: ""
      }
      this.commonChange = this.commonChange.bind(this);
      this._showContent = this._showContent.bind(this);
  }

  
  _showContent = (e) => {
    e.preventDefault();
    let { linkname, linktype, linkurl } = this.state;
    linkname = linkname.trim();
    linkurl = linkurl.trim();
 //   console.log(this.state);
    if(linkname === "" || linkurl === ""){
      this.setState({ submitted: true });
      return false;
    } else {
      if(!this.isUrl(linkurl)){
        this.setState({ submitted: true, urlVal: "Invalid URL" });
        return false;
      }
    }

    var linkimg = '';
    switch(linktype){
      case "youtube":
        linkimg = linkurl.match(/(?:https?:\/{2})?(?:w{3}\.)?youtu(?:be)?\.(?:com|be)(?:\/watch\?v=|\/)([^\s&]+)/);

        let result = linkimg[1];
        if(result === "" || result.includes("/") || result.includes("=") || result.includes("?")){
            this.setState({ submitted: true, urlVal: "Wrong video url" });
            return false;
        } else {
            result = "https://img.youtube.com/vi/" + result + "/default.jpg";
            this._submitLink(result);
        }
        break;
      case "vimeo":
        linkimg = linkurl.replace('https://vimeo.com/','').replace('https://www.vimeo.com/','');

        if(linkimg === "" || linkimg.includes("/") || linkimg.includes("=") || linkimg.includes("?")){
            this.setState({ submitted: true, urlVal: "Wrong video url" });
            return false;
        } else {
          Api.vimeoThumbnail(linkimg).then(res => {
            linkimg = res[0].thumbnail_medium;
            this._submitLink(linkimg);
          });
        }
        break;
      case "pornhub":
        if(linkurl.indexOf("?viewkey=") > -1){
          linkurl = linkurl.slice(linkurl.indexOf("?viewkey=") + 9, linkurl.length);
          if(linkurl.indexOf("&") > -1){
            linkurl = linkurl.slice(0, linkurl.indexOf("&"));
          }
          linkurl = "https://www.pornhub.com/embed/" + linkurl;
          this.setState({ linkurl: linkurl }, function(){
            linkimg = '';
            this._submitLink();
          })
        } 
        else {
        //  linkimg = '';
        //  this._submitLink();
          this.setState({ submitted: true, urlVal: "Wrong video url" });
          return false;
        }
        break;
      case "xvideos":
        if(linkurl.indexOf("/video") > -1){
          linkurl = linkurl.slice(linkurl.indexOf("/video") + 6, linkurl.length);
          if(linkurl.indexOf("/") > -1){
            linkurl = linkurl.slice(0, linkurl.indexOf("/"));
          }

          if(linkurl === "" || linkurl.includes("/") || linkurl.includes("=") || linkurl.includes("?")){
            this.setState({ submitted: true, urlVal: "Wrong video url" });
            return false;
          } else {
            linkurl = "https://www.xvideos.com/embedframe/" + linkurl;
            this.setState({ linkurl: linkurl }, function(){
              linkimg = '';
              this._submitLink();
            })
          }
        } 
        else if (linkurl.includes("xvideos.com/embedframe")){
          this.setState({ linkurl: linkurl }, function(){
            linkimg = '';
            this._submitLink();
          })
        }
        else {
       //   linkimg = '';
        //  this._submitLink();
          this.setState({ submitted: true, urlVal: "Wrong video url" });
          return false;
        }
        break;
      case "playlist":
        let playlistId = linkurl.match(/(?:https?:\/{2})?(?:w{3}\.)?youtu(?:be)?\.(?:com|be)(?:\/playlist\?list=|\/)([^\s&]+)/);

        if(playlistId === "" || playlistId.includes("/") || playlistId.includes("=") || playlistId.includes("?")){
            this.setState({ submitted: true, urlVal: "Wrong playlist url" });
            return false;
        } else {
          this.fetchPlaylist(playlistId[1]);
        }

        break;
      case "tiktok":
          if(linkurl.toLowerCase().indexOf("/video/") > -1 && linkurl.toLowerCase().indexOf("tiktok.com") > -1){
            linkurl = (linkurl.indexOf("?") > -1) ? linkurl.slice(0, linkurl.indexOf("?") + 1) : linkurl;
            this.setState({ linkurl: linkurl }, function(){
              linkimg = '';
              this._submitLink();
            })
          } 
          else {
            this.setState({ submitted: true, urlVal: "Wrong video url" });
            return false;
          }
          break;        
      default:
          linkimg = '';
          this._submitLink();
          break;
    }
  } 


  fetchPlaylist(playlist) {
    const parent = this;
    const script = document.createElement("script");
    script.src = "https://apis.google.com/js/client.js";

    script.onload = () => {
      window.gapi.load('client', () => {
        window.gapi.client.setApiKey(GOOGLE_API_KEY);
        window.gapi.client.load('youtube', 'v3', function() {

            var request = window.gapi.client.youtube.playlistItems.list({
                part: 'snippet',
                playlistId: playlist,
                maxResults: 50
            });

            request.execute(function(response) {
                let listArr = [];
                let folder = true;

                if(response.hasOwnProperty("items") && response.items.length > 0){
                  for (var i = 0; i < response.items.length; i++) {
                    let videoId = response.items[i].snippet.resourceId.videoId;
                    listArr.push({
                      linkname: response.items[i].snippet.title,
                      linktype: "youtube",
                      linkurl: "https://youtu.be/" + videoId,
                      linktag: parent.state.linktag,
                      linkimg: "https://img.youtube.com/vi/" + videoId + "/default.jpg",
                      thumbnailWebsite: null
                    })
                  }

                  if(listArr.length > 0){
                    if(parent.props.addNewFile({ 
                          linkname: parent.state.linkname, 
                          linktype: 'folder',
                          linktag: parent.state.linktag
                      }, folder)){

                        Api.addPlaylist(parent.state.linkname, listArr).then(res => {

                          parent._closeModal();
                          
                        })
                    }
                    else {
                      parent.setState({ submitted: true, nameVal: "Name already exist" });
                      return false;
                    }
                  }
                  else {
                    parent.setState({ submitted: true, urlVal: "Empty playlist" });
                    return false;
                  }
                }
                else {
                  parent.setState({ submitted: true, urlVal: "Playlist doesn't exist" });
                  return false;
                }
            });
        }); 
      });
    };

    document.body.appendChild(script);
  }



  _submitLink(img = '') {
    let funcResult = (img === '') ? this.props.addNewFile({ 
                                        linkname: this.state.linkname.trim(), 
                                        linkurl: this.state.linkurl.trim(), 
                                        linktype: this.state.linktype,
                                        linktag: this.state.linktag
                                    }) : 
                                    this.props.addNewFile({ 
                                      linkname: this.state.linkname.trim(), 
                                      linkurl: this.state.linkurl.trim(), 
                                      linktype: this.state.linktype,
                                      linktag: this.state.linktag,
                                      linkimg: img
                                    });


    if(funcResult){
      this._closeModal();
    }
    else {
      this.setState({ submitted: true, nameVal: "Name already exist" });
      return false;
    }
  }

  commonChange(event) {
    if(event.target.name === "linkname"){
      event.target.value = removeUnsafeChars(event.target.value);
    }
    this.setState({
        [event.target.name]: event.target.value
    });
    if(event.target.name === "linkurl"){
      if(this.isUrl(event.target.value.trim())){
        this.setState({ urlVal: "" });
      }      
    }
  }

  handleChange(chips){
    this.setState({ linktag: chips });
 //   console.log(chips);
  }

  isUrl(val) {
    var regexp = /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i
    return regexp.test(val);
  }

  _closeModal = () => {
    this.props.closeModal();
      
    this.setState({
      linkname: '',
      linkurl: '',
      linktype: 'website',
      linktag: [],
      submitted: false,
      urlVal: "",
      nameVal: ""
    });
  }

  render(){

    let urlErrorMsg = "", 
        nameErrorMsg = "";

    if(this.state.linkname === "" && this.state.submitted) {
      nameErrorMsg = "This field is required";
    } 
    else if(this.state.linkurl !== "" && this.state.submitted && this.state.nameVal !== ""){
      nameErrorMsg = this.state.nameVal;
    }   
    else {
      nameErrorMsg = "";
    }

    if(this.state.linkurl === "" && this.state.submitted) {
      urlErrorMsg = "This field is required";
    } 
    else if(this.state.linkurl !== "" && this.state.submitted && this.state.urlVal !== ""){
      urlErrorMsg = this.state.urlVal;
    } 
    else {
      urlErrorMsg = "";
    }


    return (
      <Modal size="mini" dimmer={this.props.dimmer} open={this.props.openLinkModal} onClose={this._closeModal}>
          <Modal.Header>Add Link</Modal.Header>
          <Modal.Content>
            <Modal.Description>
              <Header size='small'>Please specify the content of the link.</Header>
              <CloudTextField className="Cloud-Input" variant="standard" label="Name" name="linkname" onChange={this.commonChange} helperText={ nameErrorMsg } /><br />
              <CloudTextField className="Cloud-Input" variant="standard" label="URL"  name="linkurl" onChange={this.commonChange} helperText={ urlErrorMsg } /><br /><br />
              <p>Link type:</p><br />
              <RadioButtonGroup className="Cloud-Group" name="linktype" defaultValue="website" onChange={this.commonChange}>
                <FormControlLabel value="website" control={<CloudRadio />} label="Website" />
                <FormControlLabel value="youtube" control={<CloudRadio />} label="Youtube Video" />
                <FormControlLabel value="playlist" control={<CloudRadio />} label="Youtube Playlist" />
                <FormControlLabel value="tiktok" control={<CloudRadio />} label="TikTok Video" />
                <FormControlLabel value="vimeo" control={<CloudRadio />} label="Vimeo Video" />
                <FormControlLabel value="pornhub" control={<CloudRadio />} label="Pornhub Video" />
                <FormControlLabel value="xvideos" control={<CloudRadio />} label="XVIDEOS Video" />
              </RadioButtonGroup>
              <CloudChipInput className="Cloud-Input" id="tag" name="tag" label="Tags" onChange={(chips) => this.handleChange(chips)} />
            </Modal.Description>
          </Modal.Content>
          <Modal.Actions>
            <Button negative icon='close' labelPosition='right' content="Cancel" onClick={this._closeModal} />
            <Button type="submit" positive icon='checkmark' labelPosition='left' content="Add" onClick={this._showContent} />
          </Modal.Actions>
      </Modal>
    );
  }
}