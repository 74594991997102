import React, { Component } from "react";
import {
  Header,
  Image,
  Divider,
  Button,
  Icon,
  Segment,
} from "semantic-ui-react";
import NotFound from "../images/svg/no-page2.svg";
import "../css/page-404.css";

export default class PageNotFound extends Component {
  render() {
    return (
      <Segment compact className="page404" textAlign="center">
        <Divider hidden />
        <Image src={NotFound} size="medium" />
        <Header as="h1" textAlign="center">
          Page Not Found
          <Divider hidden />
        </Header>
        <Button as="a" color="blue" href="/">
          <Icon name="home" />
          Go Home
        </Button>
        <Divider hidden />
      </Segment>
    );
  }
}
