import React, { Component } from 'react';
import { Modal, Button } from 'semantic-ui-react';
import { removeUnsafeChars, CloudTextField, CloudChipInput } from '../../js/functions';

export default class EditFolderModal extends Component { 
  constructor(props) {
      super(props);
      this.state = {
          linkname: props.selectedElement.linkname.replace("." + this.props.selectedElement.extension, ""),
          linktag: (typeof props.selectedElement.linktag === undefined) ? [] : props.selectedElement.linktag,
          submitted: false,
          nameVal: ''
      }
      this.commonChange = this.commonChange.bind(this);
      this._showContent = this._showContent.bind(this);
  }

  _urlGenerator(url, name){
    return url.slice(0, url.lastIndexOf("/") + 1) + name;
  }

  _showContent = (e) => {
    e.preventDefault();
    const { selectedElement } = this.props;
    let { linkname, linktag } = this.state;
    linkname = linkname.trim();

    if(selectedElement.linkname === linkname && selectedElement.linktag === linktag){
      this._closeModal();
    }
    else{
      if(linkname === ""){
        this.setState({ submitted: true });
        return false;
      } 
      console.log("selectedElement: ", selectedElement);
      let obj = { 
        linkname: linkname + "." + selectedElement.extension, 
        linktag: linktag,
        linkurl: this._urlGenerator(selectedElement.linkurl, linkname + "." + selectedElement.extension),
      }

      if(selectedElement.hasOwnProperty("thumbnailImage")){
        if(selectedElement.hasOwnProperty("thumbnailVideo")) {
          obj["thumbnailImage"] = this._urlGenerator(selectedElement.thumbnailImage, linkname + ".png"); 
          obj["thumbnailVideo"] = this._urlGenerator(selectedElement.thumbnailVideo, linkname + ".webm");
        } else { 
          obj["thumbnailImage"] = this._urlGenerator(selectedElement.thumbnailImage, linkname + "." + selectedElement.extension);
        }
      }

      if(selectedElement.hasOwnProperty("openElement")){
        let openElement = selectedElement.openElement;
        let openElementExtension = openElement.slice(openElement.lastIndexOf("."), openElement.length)
        obj["openElement"] = this._urlGenerator(selectedElement.openElement, linkname + openElementExtension);
      }

      if(this.props.editFile(selectedElement._id, obj)){
        this._closeModal();
      }
      else {
        this.setState({ submitted: true, nameVal: "Name already exist" });
        return false;
      }
    }
  } 

  commonChange(event) {
    if(event.target.name === "linkname"){
      event.target.value = removeUnsafeChars(event.target.value);
    }
    this.setState({
        [event.target.name]: event.target.value
    });
  }

  handleChange(chips){
    this.setState({ linktag: chips });
  }

  _closeModal = () => {
    this.props.close();
      
    this.setState({
        linkname: '',
        linktag: [],
        submitted: false,
        nameVal: ''
    });
  }

  render(){

    let nameErrorMsg = "";

    if(this.state.linkname === "" && this.state.submitted) {
      nameErrorMsg = "This field is required";
    } 
    else if(this.state.submitted && this.state.nameVal !== ""){
      nameErrorMsg = this.state.nameVal;
    }   
    else {
      nameErrorMsg = "";
    }

    return (
      <Modal size="mini" dimmer={this.props.dimmer} open={this.props.open} onClose={this._closeModal}>
          <Modal.Header>Edit File</Modal.Header>
          <Modal.Content>
            <Modal.Description>
              <CloudTextField className="Cloud-Input mb30" variant="standard" label="File Name" name="linkname" onChange={this.commonChange} helperText={ nameErrorMsg } value={ this.state.linkname } /> 
              <span className="File-Extension">{ "." + this.props.selectedElement.extension }</span>
              <br />
              <CloudChipInput className="Cloud-Input" id="tag" name="tag" label="Tags" onChange={(chips) => this.handleChange(chips)} defaultValue={ this.state.linktag } />
            </Modal.Description>
          </Modal.Content>
          <Modal.Actions>
            <Button negative icon='close' labelPosition='right' content="Cancel" onClick={this._closeModal} />
            <Button type="submit" positive icon='checkmark' labelPosition='left' content="Update" onClick={this._showContent} />
          </Modal.Actions>
      </Modal>
    );
  }
}