import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Container } from 'semantic-ui-react';
import { sitePrimaryDomain } from '../js/functions';

export default class Footer extends Component {
	render() {
		return (
			<Container as="footer" fluid textAlign="center" className="footer">
				Copyright © { new Date().getFullYear() } { sitePrimaryDomain.replace("https://","").replace("www.","") }. All rights reserved. Created by <a href="https://www.EliteWebmaster.com" target="_blank" rel="noopener">Elite Webmaster</a>
			</Container> 
		);
	}
}