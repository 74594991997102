import React, { Component } from 'react';
import { Modal, Header, Button, Icon } from 'semantic-ui-react';


export default class DeleteModal extends Component{
  _submit = () => {
    this.props.close();
    this.props.delete(this.props.filesList);
  }
  render(){
    return(
      <Modal open={this.props.open} basic size='mini'>
      <Header content='Are you sure ?' />
      <Modal.Actions>
        <Button color='red' inverted onClick={ this.props.close }>
          <Icon name='remove' /> No
        </Button>
        <Button color='green' inverted onClick={ this._submit.bind(this) }>
          <Icon name='checkmark' /> Yes
        </Button>
      </Modal.Actions>
    </Modal>
    );
  }
}