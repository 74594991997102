import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Button, Dropdown, Image, Icon } from "semantic-ui-react";
import { isTablet, isMobile } from "react-device-detect";
import { siteTitle } from "../js/functions";
import ParallaxClouds from "./ParallaxClouds";
import logo from "./../images/logo.svg";

import "../css/club-header.css";

class GoogleCustomSearch extends React.Component {
  componentDidMount() {
    (function () {
      var cx = "partner-pub-6964773164594476:y642oud4ttg";
      var gcse = document.createElement("script");
      gcse.type = "text/javascript";
      gcse.async = true;
      gcse.src = "https://cse.google.com/cse.js?cx=" + cx;
      var s = document.getElementsByTagName("script")[0];
      s.parentNode.insertBefore(gcse, s);
    })();

    if (this.props.auth) {
      window.onload = function () {
        document.getElementById("gsc-i-id1").focus();
      };
    }
  }

  render() {
    return (
      <div className="googleSearch">
        <div
          className="searchBox"
          dangerouslySetInnerHTML={{
            __html:
              '<gcse:searchbox-only resultsUrl="/result/"></gcse:searchbox-only>',
          }}
        ></div>
      </div>
    );
  }
}

export default class CloudHeader extends Component {
  render() {
    const { auth, profile } = this.props;
    let privatDetails;

    if (!auth.isAuthenticated() || typeof profile === "undefined") {
      privatDetails = (
        <Button inverted className="btnLogin" onClick={() => auth.login()}>
          Log In
        </Button>
      );
    } else {
      const trigger = (
        <span>
          <Image avatar src={profile.picture} floated="right" size="mini" />{" "}
          <span className="profile-name">{profile.name}</span>
        </span>
      );

      const DropdownImageTriggerExample = () => (
        //    <Dropdown className="profile" trigger={trigger} options={options} onChange={this._dropdownMenu.bind(this)} pointing='top right' icon={null} />
        <Dropdown
          className="profile"
          trigger={trigger}
          pointing="top right"
          icon={null}
        >
          <Dropdown.Menu>
            <Dropdown.Item key="user" value="account">
              <Link to="/account">
                <Icon name="user" /> My Account
              </Link>
            </Dropdown.Item>
            <Dropdown.Item key="pricing" value="pricing">
              <Link to="/pricing">
                <Icon name="ticket" /> Pricing
              </Link>
            </Dropdown.Item>
            <Dropdown.Item key="home" value="home">
              <Link to="/">
                <Icon name="home" /> Home
              </Link>
            </Dropdown.Item>
            <Dropdown.Item
              key="sign-out"
              value="sign out"
              onClick={() => this.props.logout()}
            >
              <Icon name="sign out" /> Log Out
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      );

      privatDetails = DropdownImageTriggerExample();
    }

    return (
      <header
        className={"cloudHead" + (isTablet || isMobile ? " mobileHead" : "")}
      >
        {privatDetails}
        <Link className="App-logo" onClick={this.toggleVisibility} to="/">
          <img src={logo} alt={siteTitle} width="130" height="95" />
        </Link>
        <GoogleCustomSearch auth={auth.isAuthenticated()} />
        <ParallaxClouds />
      </header>
    );
  }
}
