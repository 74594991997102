import React, { Component } from 'react';
import { libreOffice, checkIfExist } from './Api';
import Pdf from './Pdf';
import PdfMobile from './PdfMobile';

export default class LibreOffice extends Component{
  static defaultProps = {
    mobile: false
  }

  constructor(props){
    super(props);
    this.state = ({
      openElementUrl: props.openElement,
      exist: false
    });
  }

  _convertFile = () => {
    let { element } = this.props;
    libreOffice(element).then(res => {
      console.log("libreOffice res: ", res);
      if(res.hasOwnProperty("data") && res.data.hasOwnProperty("ok") && res.data.ok){
        this.setState({
          openElementUrl: res.data.openElement,
          exist: true
        });
      } else {
        this.props._addNotification("error", "Error", "Some error occurred");
        console.log("*** Some error occurred ****");
      }
  //    this.props.showDimmer(false);
    });
  }

  componentDidMount(){
    this.props.showDimmer(true);
    if(this.state.openElementUrl === null){
      this._convertFile();
    } else {
      checkIfExist(this.state.openElementUrl).then(res => {
        if(res.data.file){
          this.setState({
            exist: true
          });
        } else {
          console.log("*********** Doesn't Exist ***********");
          this._convertFile();
        }
      });
    }
  } 

  render(){
    let { element } = this.props;
    let file = "";

    if(this.state.openElementUrl !== null && this.state.exist){
      element["openElement"] = this.state.openElementUrl;
      if(this.props.mobile){
        file = <PdfMobile element={ element } showDimmer={ this.props.showDimmer } documentPages={ this.props.documentPages } documentZoom={ this.props.documentZoom } />
      } else {
        file = <Pdf element={ element } showDimmer={ this.props.showDimmer } documentPages={ this.props.documentPages } documentZoom={ this.props.documentZoom } />
      }
    }
    return(
      <div>
        { file }
      </div>
    );
  }
}