import React, { Component, lazy, Suspense } from "react";
import Slider from "react-slick";
import { Segment, Header, Image } from "semantic-ui-react";
import IntroTopText from "./IntroTopText";
import IntroText from "./IntroText";
import IntroTabs from "./IntroTabs";
import uploadSVG from "../images/svg/upload4.svg";
import noRegistrationSVG from "../images/svg/no-registration4.svg";
import oneSVG from "../images/svg/one3.svg";
import worldSVG from "../images/svg/startuppage.svg";

import "../css/intro-wrap.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default class Intro extends Component {
  render() {
    const settings = {
        dots: true,
        infinite: true,
        speed: 1000,
        slidesToShow: 1,
        slidesToScroll: 1,
      },
      mobileDevice = this.props.mobileDevice,
      IntroVideo = lazy(() => import("./IntroVideo"));

    return (
      <div className="introWrap">
        {mobileDevice && (
          <Slider {...settings}>
            <div>
              <Image
                className="carouselIcon"
                src={uploadSVG}
                alt="Add or Upload your favorite media"
              />
              <h2>Add or Upload your favorite media.</h2>
            </div>
            <div>
              <Image
                className="carouselIcon"
                src={noRegistrationSVG}
                alt="No registration needed just log in"
              />
              <h2>No registration needed just log in.</h2>
            </div>
            <div>
              <Image
                className="carouselIcon"
                src={oneSVG}
                alt="Organize all your data in one place"
              />
              <h2>Organize all your data in one place.</h2>
            </div>
            <div>
              <Image
                className="carouselIcon"
                src={worldSVG}
                alt="Access from anywhere in the world"
              />
              <h2>Use as your default startup page.</h2>
            </div>
          </Slider>
        )}

        {!mobileDevice && (
          <div>
            <div className="threeIcons minWidth992">
              <Segment.Group horizontal className="introText">
                <Segment>
                  <Header as="h2" className="desktopH2" icon>
                    <Image
                      width="120"
                      height="120"
                      className="carouselIcon"
                      src={uploadSVG}
                      alt="Add or Upload your favorite media"
                    />
                    Add or Upload your favorite media.
                  </Header>
                </Segment>
                <Segment>
                  <Header as="h2" className="desktopH2" icon>
                    <Image
                      width="120"
                      height="120"
                      className="carouselIcon"
                      src={noRegistrationSVG}
                      alt="No registration needed just log in"
                    />
                    No registration needed just log in.
                  </Header>
                </Segment>
                <Segment>
                  <Header as="h2" className="desktopH2" icon>
                    <Image
                      width="120"
                      height="120"
                      className="carouselIcon"
                      src={oneSVG}
                      alt="Organize all your data in one place"
                    />
                    Organize all your data in one place.
                  </Header>
                </Segment>
                <Segment>
                  <Header as="h2" className="desktopH2" icon>
                    <Image
                      width="120"
                      height="120"
                      className="carouselIcon"
                      src={worldSVG}
                      alt="Access from anywhere in the world"
                    />
                    Use as your default startup page.
                  </Header>
                </Segment>
              </Segment.Group>
            </div>

            <div className={mobileDevice ? "maxWidth10000" : "maxWidth991"}>
              <Slider {...settings}>
                <div>
                  <Image
                    className="carouselIcon"
                    src={uploadSVG}
                    alt="Add or Upload your favorite media"
                  />
                  <h2>Add or Upload your favorite media.</h2>
                </div>
                <div>
                  <Image
                    className="carouselIcon"
                    src={noRegistrationSVG}
                    alt="No registration needed just log in"
                  />
                  <h2>No registration needed just log in.</h2>
                </div>
                <div>
                  <Image
                    className="carouselIcon"
                    src={oneSVG}
                    alt="Organize all your data in one place"
                  />
                  <h2>Organize all your data in one place.</h2>
                </div>
                <div>
                  <Image
                    className="carouselIcon"
                    src={worldSVG}
                    alt="Access from anywhere in the world"
                  />
                  <h2>Use as your default startup page.</h2>
                </div>
              </Slider>
            </div>
          </div>
        )}

        <Suspense fallback="">
          <IntroVideo />
        </Suspense>

        <IntroTopText />

        <IntroText />

        <IntroTabs darkMode={this.props.darkMode} />
      </div>
    );
  }
}
