import React, { Component } from 'react';
import { Icon, Header, Card, Button, Divider, Message, Modal } from 'semantic-ui-react';
import { showCancelledAccounts, showAccountData, deleteAccount } from './Api';
import ReactJson from 'react-json-view';

export default class ControlPanel extends Component {

	constructor(){
		super();
		this.state = {
			cards: null,
			s3: null,
			db: null,
			open: false
		}
	}

	_delete = (account) => {
		this.setState({ open: true, account: account });
	}

	_deleteConfirmed = () => {
		this.close();
		this.props.showDimmer(true);
		deleteAccount(this.state.account.user).then(res => {
			let result = res.data;
			console.log("*** deleteAccount:", result);
			this.props.showDimmer(false);
			if(result.hasOwnProperty("error") && result.error){
				this.props._addNotification("error", "Error", "Some error occurred");	
			} else {
				this.props._addNotification("success", "Deleted successfully", "");
			}
			this.componentDidMount();
			this._clear();
		});
	}

	_show = (account) => {
		showAccountData(account.user).then(res => {
			let s3 = res.data.content.s3;
			let db = res.data.content.db;
			let notFound = <Message color='green'>Not found</Message>

		//	s3 = s3.map(res => { return res.file; });

			this.setState({
				s3: (s3.length > 0) ? <ReactJson displayDataTypes={false} src={s3} /> : notFound,
				db: (s3.length > 0) ? <ReactJson displayDataTypes={false} src={db} /> : notFound
			})

		//	console.log(res);
		})
	}

	_clear = () => {
		this.setState({
			s3: null,
			db: null
		})
	}

	close = () => this.setState({ open: false });

	componentDidMount(){
		showCancelledAccounts().then(res => {
			console.log(res.data.docs);
			let docs = res.data.docs;
			let result = null;
			if(docs < 1){
				result = <Message color='green'>Cancellations not found</Message>
			} else {
				result = docs.map(account => {

					let purchaseDate = account["purchaseDate"];

					let todayDate = new Date();
					todayDate.setMonth(todayDate.getMonth()+1);
	
					let mongoDate = new Date(purchaseDate);
	
					todayDate.setDate(mongoDate.getDate());

					return 	(<Card key={ account.user }>
								<Card.Content>
									<Card.Header as="h3">{ account.user }</Card.Header>
									<Card.Description>
										Plan: { account.plan }<br />
										Delete after: <span>{ todayDate.toDateString() }</span>
									</Card.Description>
								</Card.Content>
								<Card.Content extra>
									<div className='ui two buttons'>
										<Button basic color='green' onClick={() => this._show(account)}>
											<Icon name="eye" /> Show
										</Button>
										<Button basic color='red' onClick={() => this._delete(account)}>
											<Icon name="x" /> Delete
										</Button>
									</div>
								</Card.Content>
							</Card>);
				})
			}
			
			this.setState({
				cards: result
			})
		});
	}

	render() {
		let { cards, s3, db } = this.state;
		return (
			<div className="controlPanel">
			    <Modal dimmer='blurring' size='mini' closeOnEscape={false} closeOnDimmerClick={false} open={this.state.open} onClose={this.close}>
					<Modal.Header><Icon name="id badge" /> Delete Account & Content</Modal.Header>
					<Modal.Content>
						<p>Are you sure that you want to delete this account and his content ?</p>
					</Modal.Content>
					<Modal.Actions>
						<Button negative onClick={this.close}>No</Button>
						<Button positive onClick={this._deleteConfirmed.bind(this)}>Yes</Button>
					</Modal.Actions>
				</Modal>

                <Header as='h1'>
                  <Icon name="settings" /> Control Panel
                </Header>

				<Card.Group centered>
					{ cards }
				</Card.Group>

				<Divider hidden />
				{
					(s3 !== null) && <div><Header as='h2' color='yellow'>AWS S3</Header>{ s3 }</div>
				}
				<Divider hidden />
				{
					(db !== null) && <div><Header as='h2' color='green'>MongoDB</Header>{ db }</div>
				}
				<Divider hidden />
				{
					(db !== null || s3 !== null) && <Button color='blue' onClick={this._clear.bind(this)}><Icon name="erase" /> Clear</Button>
				}
				<Divider hidden />
			</div>
		);
	}
}