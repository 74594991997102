import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import ReactPlayer from 'react-player';
import { domainS3 } from '../js/functions';

export default class Audio extends Component{

  shouldComponentUpdate(nextProps, nextState) {
  //  console.log("shouldComponentUpdate nextState: ", nextState);
  //  console.log("shouldComponentUpdate nextProps: ", nextProps);
    return (this.props.element._id !== nextProps.element._id) ? true : false;
  }

  componentDidMount(){
  //  console.log(ReactDOM.findDOMNode(this.refs["audio_" + this.props.id]).childNodes[0]);
    const { linktype, _id } = this.props.element;
    if(linktype === "audio/mpeg" || linktype === "audio/mp3"){
      var audio = ReactDOM.findDOMNode(this.refs["audio_" + _id]).childNodes[0];
      if(typeof audio !== undefined && typeof audio !== "undefined" && audio !== null){
        audio.crossOrigin = "anonymous";
        var AudioContext = window.AudioContext || window.webkitAudioContext;
        var context = new AudioContext();
        var src = context.createMediaElementSource(audio);
        var analyser = context.createAnalyser();

        var canvas = ReactDOM.findDOMNode(this.refs["audio_canvas_" + _id]);
        canvas.width = window.innerWidth;
        canvas.height = window.innerHeight;
        var ctx = canvas.getContext("2d");

        src.crossOrigin = "anonymous";
        src.connect(analyser);
        analyser.connect(context.destination);

        analyser.fftSize = 256;

        var bufferLength = analyser.frequencyBinCount;
     //   console.log(bufferLength);

        var dataArray = new Uint8Array(bufferLength);

        var WIDTH = canvas.width;
        var HEIGHT = canvas.height;

        var barWidth = (WIDTH / bufferLength) * 2.5;
        var barHeight;
        var x = 0;

        function renderFrame() {
          requestAnimationFrame(renderFrame);

          x = 0;

          analyser.getByteFrequencyData(dataArray);

          ctx.fillStyle = "#fff";
          ctx.fillRect(0, 0, WIDTH, HEIGHT);

          for (var i = 0; i < bufferLength; i++) {
            barHeight = dataArray[i];
            
            var r = barHeight + (25 * (i/bufferLength));
            var g = 250 * (i/bufferLength);
            var b = 50;

            ctx.fillStyle = "rgb(" + r + "," + g + "," + b + ")";
            ctx.fillRect(x, HEIGHT - barHeight, barWidth, barHeight);

            x += barWidth + 1;
          }
        }
        renderFrame();
      }
    }
  }

  render(){
    const { _id, linkurl } = this.props.element;

    return(
      <div>
        <canvas id={ "audio_canvas_" + _id } ref={ "audio_canvas_" + _id } className="audioVisualizerCanvas"></canvas>
        <ReactPlayer id={ "audio_" + _id } url={ domainS3 + linkurl } ref={ "audio_" + _id } className="audioFile" playing controls  onEnded={ () => this.props.arrowFiles("next") } />
      </div>
    );
  }
}