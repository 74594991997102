import React, { Component } from "react";
import {
  Container,
  Grid,
  Header,
  Icon,
  Divider,
  Image,
  Button,
} from "semantic-ui-react";
import { siteTitle, siteCategory, domainS3 } from "../js/functions";
import imageWindows from "../images/win-cloudos.jpg";
import imageMacOS from "../images/mac-cloudos.jpg";

import "../css/pages.css";

export default class About extends Component {
  render() {
    return (
      <div className="about">
        <Container fluid textAlign="center">
          <Divider hidden />
          <Header as="h1">
            <Icon name="desktop" /> Open On Startup
          </Header>
          <Divider hidden />
        </Container>
        <Grid centered padded>
          <Grid.Column mobile={16} tablet={16} computer={8} textAlign="center">
            <Header as="h2" textAlign="center">
              Windows Users
            </Header>
            <Image src={imageWindows} className="startup" />
            Please download this program if you want to open{" "}
            {siteTitle + " " + siteCategory} when windows startup.
            <Button
              as="a"
              href={domainS3 + "defaultImages/Win-CloudOS.zip"}
              color="black"
              className="startup"
            >
              <Icon name="windows" /> Download
            </Button>
          </Grid.Column>

          <Grid.Column mobile={16} tablet={16} computer={8} textAlign="center">
            <Header as="h2" textAlign="center">
              macOS Users
            </Header>
            <Image src={imageMacOS} className="startup" />
            Please download this app if you want to open{" "}
            {siteTitle + " " + siteCategory} when macOS startup.
            <br />
            <strong>
              If you can't run the downloaded file you need to open your System
              Preferences <Icon name="arrow right" /> Security & Privacy{" "}
              <Icon name="arrow right" /> in the General tab click on the Open
              Anyway button.
            </strong>
            <Button
              as="a"
              href={domainS3 + "defaultImages/MacOS-CloudOS.zip"}
              color="black"
              className="startup"
            >
              <Icon name="apple" /> Download
            </Button>
          </Grid.Column>
        </Grid>

        <Divider hidden />
      </div>
    );
  }
}
