import axios from "axios";
import * as MyFunctions from "../js/functions";

export const fetchFilesList = (sub, defaultLinks) => {
  let url = MyFunctions.getCurrentUrl();
  if (
    typeof sub !== undefined &&
    typeof sub !== "undefined" &&
    sub !== null &&
    sub !== "" &&
    sub !== "none"
  ) {
    return axios.post("/api/", { url, sub, defaultLinks }).then((resp) => {
      if (
        resp.hasOwnProperty("data") &&
        resp.data.hasOwnProperty("reset") &&
        resp.data.hasOwnProperty("logout") &&
        resp.data.reset &&
        resp.data.logout
      ) {
        console.log("*** relogin ***");
        MyFunctions.relogin();
        return {};
      }
      return resp.data;
    });
  } else {
    sub = "";
    url = "/";
    return axios.post("/api/", { url, sub, defaultLinks }).then((resp) => {
      if (
        resp.hasOwnProperty("data") &&
        resp.data.hasOwnProperty("reset") &&
        resp.data.hasOwnProperty("logout") &&
        resp.data.reset &&
        resp.data.logout
      ) {
        console.log("*** relogin ***");
        MyFunctions.relogin();
        return {};
      }
      return resp.data;
    });
  }
};

export const addFile = (sub, obj) => {
  let url = MyFunctions.getCurrentUrl();
  if (
    typeof sub !== undefined &&
    sub !== null &&
    sub !== "" &&
    sub !== "none"
  ) {
    return axios.post("/api/add/", { url, sub, obj }).then((resp) => {
      if (
        resp.hasOwnProperty("data") &&
        resp.data.hasOwnProperty("reset") &&
        resp.data.hasOwnProperty("logout") &&
        resp.data.reset &&
        resp.data.logout
      ) {
        console.log("*** relogin ***");
        MyFunctions.relogin();
        return {};
      }
      return resp.data;
    });
  } else {
    console.log("User not logged in");
  }
};

export const addPlaylist = (name, obj) => {
  let url = MyFunctions.getCurrentUrl();

  return axios.post("/api/addplaylist/", { url, name, obj }).then((resp) => {
    if (
      resp.hasOwnProperty("data") &&
      resp.data.hasOwnProperty("reset") &&
      resp.data.hasOwnProperty("logout") &&
      resp.data.reset &&
      resp.data.logout
    ) {
      console.log("*** relogin ***");
      MyFunctions.relogin();
      return {};
    }
    return resp.data;
  });
};

export const editFile = (sub, id, obj) => {
  let url = MyFunctions.getCurrentUrl();
  if (
    typeof sub !== undefined &&
    sub !== null &&
    sub !== "" &&
    sub !== "none"
  ) {
    return axios.post("/api/edit/", { url, sub, obj, id }).then((resp) => {
      if (
        resp.hasOwnProperty("data") &&
        resp.data.hasOwnProperty("reset") &&
        resp.data.hasOwnProperty("logout") &&
        resp.data.reset &&
        resp.data.logout
      ) {
        console.log("*** relogin ***");
        MyFunctions.relogin();
        return {};
      }
      return resp.data;
    });
  } else {
    console.log("User not logged in");
  }
};

export const editFileAmazonS3 = (arrOld, arrNew) => {
  if (
    arrNew.length > 0 &&
    arrOld.length > 0 &&
    arrNew.length === arrOld.length
  ) {
    return axios.post("/api/edit/file", { arrOld, arrNew }).then((resp) => {
      if (
        resp.hasOwnProperty("data") &&
        resp.data.hasOwnProperty("reset") &&
        resp.data.hasOwnProperty("logout") &&
        resp.data.reset &&
        resp.data.logout
      ) {
        console.log("*** relogin ***");
        MyFunctions.relogin();
        return {};
      }
      return resp.data;
    });
  } else {
    console.log("User not logged in");
  }
};

export const editPath = (sub, prevPath, newPath) => {
  if (
    typeof sub !== undefined &&
    sub !== null &&
    sub !== "" &&
    sub !== "none"
  ) {
    return axios
      .post("/api/edit/path/", { sub, prevPath, newPath })
      .then((resp) => resp.data);
  } else {
    console.log("User not logged in");
  }
};

export const pasteFile = (arr, folder, action, actionUrl) => {
  let url = MyFunctions.getCurrentUrl();
  return axios
    .post("/api/paste/", { url, arr, folder, action, actionUrl })
    .then((resp) => {
      if (
        resp.hasOwnProperty("data") &&
        resp.data.hasOwnProperty("reset") &&
        resp.data.hasOwnProperty("logout") &&
        resp.data.reset &&
        resp.data.logout
      ) {
        console.log("*** relogin ***");
        MyFunctions.relogin();
        return {};
      }
      return resp.data;
    });
};

export const deleteFile = (
  sub,
  arr,
  folder = false,
  deleteFromS3 = [],
  deleteElements = []
) => {
  let url = MyFunctions.getCurrentUrl();
  if (
    typeof sub !== undefined &&
    sub !== null &&
    sub !== "" &&
    sub !== "none"
  ) {
    for (let a = 0; a < deleteElements.length; a++) {
      if (deleteElements[a].hasOwnProperty("filesList")) {
        delete deleteElements[a].filesList;
      }
    }

    //   console.log("Delete Api: ", { data: { url, sub, arr, folder, deleteFromS3, deleteElements }});
    return axios
      .delete("/api/delete/", {
        data: { url, sub, arr, folder, deleteFromS3, deleteElements },
      })
      .then((resp) => {
        if (
          resp.hasOwnProperty("data") &&
          resp.data.hasOwnProperty("reset") &&
          resp.data.hasOwnProperty("logout") &&
          resp.data.reset &&
          resp.data.logout
        ) {
          console.log("*** relogin ***");
          MyFunctions.relogin();
          return {};
        }
        return resp.data;
      });
  } else {
    console.log("User not logged in");
  }
};

export const token = (token) => {
  if (typeof token !== undefined && token !== null) {
    return axios.post("/api/token/", { token }).then((resp) => resp.data);
  } else {
    console.log("User not logged in");
  }
};

export const download = (url = "") => {
  if (url.length > 1) {
    let myHost =
      window.location.hostname === "localhost"
        ? "http://localhost:8080"
        : MyFunctions.sitePrimaryDomain;
    window.open(myHost + "/api/download" + url, "_top");
    //  return axios.get('/api/download?obj=' + obj[0].Key ).then(resp => resp);
  }
};

export const vimeoThumbnail = (videoId) => {
  if (typeof videoId !== undefined && videoId !== null && videoId !== "") {
    return axios
      .get("http://vimeo.com/api/v2/video/" + videoId + ".json")
      .then((resp) => resp.data);
  } else {
    console.log("User not logged in");
  }
};

export const uploadFile = (files) => {
  let url = MyFunctions.getCurrentUrl();
  return axios
    .post("/api/upload/file/", { url: url, files: files })
    .then((resp) => resp.data);
};

export const checkIfExist = (url) => {
  return axios.post("/api/exist/", { url: url }).then((resp) => resp);
};

export const signUrl = (url) => {
  return axios.post("/api/signurl/", { url: url }).then((resp) => resp);
};

export const getPlans = (auth) => {
  return axios.post("/api/account/plans", { auth: auth }).then((resp) => resp);
};

export const libreOffice = (element) => {
  console.log("element: ", element);
  let openElement = element.hasOwnProperty("openElement")
    ? element.openElement
    : null;
  return axios
    .post("/api/libreoffice/", {
      id: element._id,
      linkurl: element.linkurl,
      openElement: openElement,
    })
    .then((resp) => {
      console.log("resp: ", resp);
      if (
        resp.hasOwnProperty("data") &&
        resp.data.hasOwnProperty("reset") &&
        resp.data.hasOwnProperty("logout") &&
        resp.data.reset &&
        resp.data.logout
      ) {
        console.log("*** relogin ***");
        MyFunctions.relogin();
        return {};
      }
      return resp;
    });
};

export const convertVideo = (element) => {
  return axios
    .post("/api/ffmpeg/", { id: element._id, linkurl: element.linkurl })
    .catch((error) => {
      console.log("*** catch ***");
      console.log(error);
      return { wait: true };
    })
    .then((resp) => {
      console.log("resp: ", resp);
      if (
        resp.hasOwnProperty("data") &&
        resp.data.hasOwnProperty("reset") &&
        resp.data.hasOwnProperty("logout") &&
        resp.data.reset &&
        resp.data.logout
      ) {
        console.log("*** relogin ***");
        MyFunctions.relogin();
        return {};
      }
      return resp.data;
    });
};

export const updateOpenElement = (id, linkurl) => {
  return axios
    .post("/api/ffmpeg/update/", { id: id, linkurl: linkurl })
    .then((resp) => {
      console.log("resp: ", resp);
      if (
        resp.hasOwnProperty("data") &&
        resp.data.hasOwnProperty("reset") &&
        resp.data.hasOwnProperty("logout") &&
        resp.data.reset &&
        resp.data.logout
      ) {
        console.log("*** relogin ***");
        MyFunctions.relogin();
        return {};
      }
      return resp.data;
    });
};

export const checkUsage = () => {
  return axios.post("/api/account/usage/").then((resp) => {
    console.log("resp: ", resp);
    if (
      resp.hasOwnProperty("data") &&
      resp.data.hasOwnProperty("reset") &&
      resp.data.hasOwnProperty("logout") &&
      resp.data.reset &&
      resp.data.logout
    ) {
      console.log("*** relogin ***");
      MyFunctions.relogin();
      return {};
    }
    return resp;
  });
};

export const accountOptions = (obj) => {
  return axios.post("/api/account/options/", { obj: obj }).then((resp) => {
    console.log("resp: ", resp);
    if (
      resp.hasOwnProperty("data") &&
      resp.data.hasOwnProperty("reset") &&
      resp.data.hasOwnProperty("logout") &&
      resp.data.reset &&
      resp.data.logout
    ) {
      console.log("*** relogin ***");
      MyFunctions.relogin();
      return {};
    }
    return resp;
  });
};

export const sendMail = (obj) => {
  return axios.post("/api/sendmail/", { obj: obj }).then((resp) => resp);
};

export const fetchUpdates = () => {
  return axios.post("/api/news/updates").then((resp) => resp.data);
};

export const addUpdatesFeed = (obj) => {
  return axios.post("/api/news/insert", { obj }).then((resp) => {
    console.log("resp: ", resp);
    if (
      resp.hasOwnProperty("data") &&
      resp.data.hasOwnProperty("reset") &&
      resp.data.hasOwnProperty("logout") &&
      resp.data.reset &&
      resp.data.logout
    ) {
      console.log("*** relogin ***");
      MyFunctions.relogin();
      return {};
    }
    return resp.data;
  });
};

export const confirmFile = (_id, confirm) => {
  return axios
    .post("/api/confirm", { _id: _id, confirm: confirm })
    .then((resp) => {
      console.log("resp: ", resp);
      if (
        resp.hasOwnProperty("data") &&
        resp.data.hasOwnProperty("reset") &&
        resp.data.hasOwnProperty("logout") &&
        resp.data.reset &&
        resp.data.logout
      ) {
        console.log("*** relogin ***");
        MyFunctions.relogin();
        return {};
      }
      return resp;
    });
};

export const showCancelledAccounts = () => {
  return axios.post("/api/account/cancelled").then((resp) => {
    console.log("resp: ", resp);
    if (
      resp.hasOwnProperty("data") &&
      resp.data.hasOwnProperty("reset") &&
      resp.data.hasOwnProperty("logout") &&
      resp.data.reset &&
      resp.data.logout
    ) {
      console.log("*** relogin ***");
      MyFunctions.relogin();
      return {};
    }
    return resp;
  });
};

export const showAccountData = (accountId) => {
  return axios
    .post("/api/account/data", { accountId: accountId })
    .then((resp) => {
      console.log("resp: ", resp);
      if (
        resp.hasOwnProperty("data") &&
        resp.data.hasOwnProperty("reset") &&
        resp.data.hasOwnProperty("logout") &&
        resp.data.reset &&
        resp.data.logout
      ) {
        console.log("*** relogin ***");
        MyFunctions.relogin();
        return {};
      }
      return resp;
    });
};

export const deleteAccount = (accountId) => {
  return axios
    .post("/api/account/delete", { accountId: accountId })
    .then((resp) => {
      console.log("resp: ", resp);
      if (
        resp.hasOwnProperty("data") &&
        resp.data.hasOwnProperty("reset") &&
        resp.data.hasOwnProperty("logout") &&
        resp.data.reset &&
        resp.data.logout
      ) {
        console.log("*** relogin ***");
        MyFunctions.relogin();
        return {};
      }
      return resp;
    });
};

export const testOnly = () => {
  return axios.post("/api/ipnotify-test-only/").then((resp) => resp);
};

export const tikTokData = (videoUrl) => {
  return axios
    .get("https://www.tiktok.com/oembed?url=" + videoUrl, {
      withCredentials: false,
    })
    .then((resp) => resp);
};

export const newsNYP = (
  news,
  sport,
  business,
  entertainment,
  fashion,
  tech,
  living,
  realEstate
) => {
  return axios
    .post("/api/news/", {
      news: news,
      sport: sport,
      business: business,
      entertainment: entertainment,
      fashion: fashion,
      tech: tech,
      living: living,
      realEstate: realEstate,
    })
    .then((resp) => {
      console.log("resp: ", resp);
      if (
        resp.hasOwnProperty("data") &&
        resp.data.hasOwnProperty("reset") &&
        resp.data.hasOwnProperty("logout") &&
        resp.data.reset &&
        resp.data.logout
      ) {
        console.log("*** relogin ***");
        MyFunctions.relogin();
        return {};
      }
      return resp;
    });
};

export const weatherApi = (latitude, longitude) => {
  return axios
    .post("/api/weather/", { latitude: latitude, longitude: longitude })
    .then((resp) => resp);
};

// https://www.tiktok.com/oembed?url=https://www.tiktok.com/@valeriyabearwolf/video/7005164001206242561
