import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Grid, Icon, Button } from "semantic-ui-react";
import { siteTitle } from "../js/functions";
import logo from "./../images/logo-horizontal.svg";
import logoSmall from "./../images/logo-small.svg";

import "../css/sticky-menu.css";

export default class LoginBar extends Component {
  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll, true);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  handleScroll(event) {
    let scrollTop = event.srcElement.scrollTop;

    //	console.log("window.pageYOffset: ", event.srcElement.scrollTop);
    let target = window.document.getElementById("not-auth-menu");
    let emptybox = window.document.getElementById("emptybox");

    if (scrollTop > 250) {
      if (!target.className.includes("slide-in")) {
        target.className =
          target.className.replace(" slide-out", "").replace("slide-out", "") +
          " slide-in";
        emptybox.style.display = "block";
      }
    } else if (scrollTop < 250) {
      if (target.className.includes("slide-in")) {
        target.className =
          target.className.replace(" slide-in", "").replace("slide-in", "") +
          " slide-out";
        emptybox.style.display = "none";
      }
    }
  }

  render() {
    return (
      <div className="sticky-menu not-auth">
        <div id="not-auth-menu" className={"scroll-menu"}>
          <Grid columns={3}>
            <Grid.Row>
              <Grid.Column>
                <Icon
                  name="content"
                  className="burger"
                  size="huge"
                  onClick={this.props.toggleVisibility}
                />
              </Grid.Column>
              <Grid.Column>
                <Link className="App-logo" to="/">
                  <img src={logo} alt={siteTitle} width="200" height="59" />
                </Link>
                <Link className="App-logo-small" to="/">
                  <img src={logoSmall} alt={siteTitle} width="80" height="70" />
                </Link>
              </Grid.Column>
              <Grid.Column>
                <Button
                  inverted
                  className="btnLogin"
                  onClick={() => this.props.auth.login()}
                >
                  Log In
                </Button>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
      </div>
    );
  }
}
