import React, { Component } from "react";
import { Transition, Icon, Image, Button } from "semantic-ui-react";
import { siteTitle, domainS3 } from "../js/functions";
import imageWindows from "../images/thumb-win-cloudos.jpg";
import imageMacOS from "../images/thumb-mac-cloudos.jpg";
import { isTablet, isMobile, osName } from "react-device-detect";
import { accountOptions } from "../components/Api";
import "../css/startup-ad-wrap.css";

export default class StartupAd extends Component {
  state = { visible: true };

  toggleVisibility = () => {
    accountOptions({ startupAd: false }).then((res) => {
      console.log(res);
      this.setState({ visible: false });
    });
  };

  render() {
    return (
      <div>
        {this.props.show &&
          !isMobile &&
          !isTablet &&
          (osName === "Windows" || osName === "Mac OS") && (
            <Transition
              visible={this.state.visible}
              animation="fade"
              duration={1000}
            >
              <div className="startupAdWrap">
                <button className="closeAd" onClick={this.toggleVisibility}>
                  <Icon name="x" />
                </button>
                {osName === "Windows" && (
                  <div className="startupAd">
                    <div className="desc">
                      Open {siteTitle}
                      <br /> on startup
                    </div>

                    <Image centered src={imageWindows} />

                    <Button
                      as="a"
                      href={domainS3 + "defaultImages/Win-CloudOS.zip"}
                      color="black"
                      className="startup"
                    >
                      <Icon name="windows" /> Download
                    </Button>
                  </div>
                )}

                {osName === "Mac OS" && (
                  <div className="startupAd">
                    <div className="desc">
                      Open {siteTitle.replace(" 5", "")}
                      <br /> on startup
                    </div>

                    <Image centered src={imageMacOS} />

                    <Button
                      as="a"
                      href={domainS3 + "defaultImages/MacOS-CloudOS.zip"}
                      color="black"
                      className="startup"
                    >
                      <Icon name="apple" /> Download
                    </Button>
                  </div>
                )}
              </div>
            </Transition>
          )}
      </div>
    );
  }
}
