import React, { Component } from 'react';
import Parallax from 'parallax-js';
import { isTablet, isMobile } from 'react-device-detect';

export default class ParallaxClouds extends Component {
	componentDidMount() {
		if(!isTablet && !isMobile){
			this.parallax = new Parallax(this.scene,{
				scalarY: 8.5,
			});
		}
	}
	
	componentWillUnmount() {
		if(!isTablet && !isMobile){
			this.parallax.disable();
		}
	}

	render() {
	//	console.log(!isTablet && !isMobile);
		if(!isTablet && !isMobile){
			return (
				<ul ref={el => this.scene = el}>
					<li className="layer" data-depth="0.20">
						<div className="parallax-clouds cloud1"></div>
					</li>
					<li className="layer" data-depth="0.50">
						<div className="parallax-clouds cloud2"></div>
					</li>
					<li className="layer" data-depth="1.00">
						<div className="parallax-clouds cloud3"></div>
					</li>
				</ul>
			);
		} else {
			return (
				<div className="parallax-clouds mobile"></div>
			);
		}
	}
}