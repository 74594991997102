import React, { Component } from 'react';
import { Breadcrumb } from 'semantic-ui-react';

export default class NavBreadcrumbs extends Component{
  render(){
    var url = window.location.pathname,
              bread = [], 
              myBreadcrumb = [];

    url = url.split("/");

    for(let a = 0; a < url.length; a++){
      if(url[a] !== "") {
        bread.push(url[a]);
      }
    }

    if(bread.length > 1){
      let urlBuilder = '/';
      for(let a = 0; a < bread.length; a++){
        bread[a] = bread[a].replace("%20", " ");
        urlBuilder += bread[a] + "/";
        if(a === 0){
          myBreadcrumb.push({ index: a, active: false, name: "All Files", url: urlBuilder });
        }
        else if(a !== bread.length - 1 && a !== 0) {
          myBreadcrumb.push({ index: a, active: false, name: bread[a], url: urlBuilder });
        } else {
          myBreadcrumb.push({ index: a, active: true, name: bread[a], url: '' });
        }
      }
    } else {
      myBreadcrumb.push({a: 0, active: true, name: "All Files", url: '' });
    }
    return(
      <Breadcrumb size='big' className="myBreadcrumb">
        { myBreadcrumb.map((element) => 
          <span className="spanDivider" key={ element.index + "Breadcrumb" }>
            <Breadcrumb.Divider icon='right chevron' />
            { element.active && 
              <Breadcrumb.Section key={ element.index + "Bread" } active>{ element.name }</Breadcrumb.Section>
            }
            { !element.active && 
              <Breadcrumb.Section key={ element.index + "Bread" }  onClick={() => this.props.goto(element.url.slice(0,-1)) }>{ element.name }</Breadcrumb.Section>
            }            
          </span>) 
        }
      </Breadcrumb>
    )
  }
}