import React, { Component } from 'react';
import EditLinkModal from './EditLinkModal';
import EditFolderModal from './EditFolderModal';
import EditFileModal from './EdiFileModal';

export default class EditModal extends Component{
  _close = () => {
    this.props.editeState();
    this.props.close();
  }

  render(){
    const { selectedElement, filesList } = this.props;

    let modal = null,
        selected = null;

    if(this.props.open && selectedElement[0] !== -1 && selectedElement.length === 1) {
      selected = filesList[selectedElement[0]]; 
      if(selected.linktype === "folder") {
        modal = <EditFolderModal open={ this.props.open } dimmer={ this.props.dimmer } close={ this._close } selectedElement={ selected }  editFile={ this.props.editFile } /> 
      } 
      else if(selected.hasOwnProperty("source")){
        modal = <EditFileModal open={ this.props.open } dimmer={ this.props.dimmer } close={ this._close } selectedElement={ selected } editFile={ this.props.editFile } /> 
      }
      else {
        modal = <EditLinkModal open={ this.props.open } dimmer={ this.props.dimmer } close={ this._close } selectedElement={ selected } editFile={ this.props.editFile } /> 
      }
      console.log(selected);
    } 

    return(
       modal 
    );
  }
}