import React, { Component } from "react";
import {
  Modal,
  Header,
  Button,
  Icon,
  Dimmer,
  Loader,
  Item,
} from "semantic-ui-react";
import ReactPlayer from "react-player";
import Swipeable from "react-swipeable";
import { download } from "./Api";
import {
  domainS3,
  onlinePlayVideoFileTypes,
  onlineConvertVideoFileTypes,
  convertVideoMaximumFileSize,
  bytesToSize,
} from "../js/functions";
import Audio from "./Audio";
import PdfMobile from "./PdfMobile";
import LibreOffice from "./LibreOffice";
import ConvertVideo from "./ConvertVideo";

import "../css/modal-mobile.css";

export default class ModalMobile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      documentPages: null,
      zoom: false,
      element: props.element,
      keyCounter: 0,
      active: false,
    };

    this._fileDownload.bind(this);
  }

  _arrowFiles = (arrow) => {
    const { element } = this.state;
    let index = null;
    console.log(arrow);

    for (let a = 0; a < element.filesList.length; a++) {
      if (element._id === element.filesList[a]._id) {
        //  console.log(a);
        index = a;
      }
    }

    if (index !== null) {
      if (arrow === "next") {
        if (index + 1 < element.filesList.length) {
          index = index + 1;
        } else {
          index = 0;
        }
      } else {
        if (index - 1 >= 0) {
          index = index - 1;
        } else {
          index = element.filesList.length - 1;
        }
      }

      element.filesList[index]["filesList"] = element.filesList;

      this.setState({
        element: element.filesList[index],
      });
    }
    return false;
  };

  _documentPages = (num) => {
    this.setState({ documentPages: num });
  };

  _documentZoom = (zoom) => {
    let newZoom = zoom <= 1.4 ? false : true;
    if (this.state.zoom !== newZoom) {
      this.setState({ zoom: newZoom });
    }
  };

  _fileDownload = () => {
    const { element } = this.state;
    download("?key0=" + element.linkurl);
  };

  _fileDownloadWrap = (message) => {
    return (
      <div className="downloadWrap">
        <Header size="medium">{message}</Header>
        <Button icon labelPosition="left" primary onClick={this._fileDownload}>
          <Icon name="download" /> Download
        </Button>
      </div>
    );
  };

  _showLoader = (show = false, dimmerBg = true, dimmerText = "Loading") => {
    if (this.state.active !== show || this.state.dimmerText !== dimmerText) {
      this.setState({
        active: show,
        dimmerBg: dimmerBg,
        dimmerText: dimmerText,
      });
    }
  };

  render() {
    const { element } = this.state;
    console.log("MobileData: ", element);

    let linktype = element.linktype,
      url = element.linkurl,
      linkname = element.linkname,
      file = null,
      libreffice = [
        "application/powerpoint",
        "application/vnd.ms-powerpoint",
        "application/vnd.openxmlformats-officedocument.presentationml.presentation",
        "application/excel",
        "application/vnd.ms-excel",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        "application/msword",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        "application/octet-stream",
        "text/plain",
      ];

    if (linktype.includes("image/")) {
      /*  let bgImageStyle = { 
        backgroundImage: "url('" + decodeURIComponent(domainS3 + url) + "')"
      }

      keyboardArrows = this._showKeyPads(element);
      file = <div className="windowBackgroundImage" style={ bgImageStyle }></div>
    */
      file = (
        <Swipeable
          trackMouse
          preventDefaultTouchmoveEvent
          onSwipedLeft={() => this._arrowFiles("next")}
          onSwipedRight={() => this._arrowFiles("prev")}
        >
          <img
            className="mobile-modal-image"
            src={decodeURIComponent(domainS3 + url)}
            alt={linkname}
          />
        </Swipeable>
      );
    } else if (linktype.includes("youtube") || linktype.includes("vimeo")) {
      //   keyboardArrows = this._showKeyPads(element);
      file = (
        <div className="ratio">
          <ReactPlayer
            url={url}
            className="react-player"
            playing
            controls
            onEnded={() => this._arrowFiles("next")}
          />
        </div>
      );
    } else if (linktype.includes("pornhub") || linktype.includes("xvideos")) {
      //   keyboardArrows = this._showKeyPads(element);
      file = (
        <div className="ratio">
          <iframe
            src={url}
            className="react-player"
            frameBorder="0"
            width="100%"
            height="100%"
            scrolling="no"
            title={linkname}
            allowFullScreen
          ></iframe>
        </div>
      );
    } else if (linktype.includes("video/")) {
      if (onlinePlayVideoFileTypes.includes(linktype)) {
        // , onlinePlayVideoFileTypes, onlineConvertVideoFileTypes
        url = element.hasOwnProperty("openElement") ? element.openElement : url;
        file = (
          <div className="ratio">
            <ReactPlayer
              url={domainS3 + url}
              className="react-player"
              playing
              controls
            />
          </div>
        );
      } else if (onlineConvertVideoFileTypes.includes(linktype)) {
        if (
          element.size <= convertVideoMaximumFileSize ||
          convertVideoMaximumFileSize === "all"
        ) {
          file = (
            <ConvertVideo
              mobile={true}
              element={element}
              fileDownloadWrap={this._fileDownloadWrap}
              showKeyPads={this._showKeyPads}
              showDimmer={this._showLoader}
            />
          );
        } else {
          let getSize = bytesToSize(convertVideoMaximumFileSize);
          file = this._fileDownloadWrap(
            "Maximum file size to convert video and play online is: " +
              getSize.size +
              getSize.unit +
              ", You can download and preview locally."
          );
        }
      } else {
        file = this._fileDownloadWrap(
          "Currently we unable to preview this file type, You can download and preview locally."
        );
      }

      //  file = <div className="ratio"><ReactPlayer url={ domainS3 + url } className='react-player' playing controls /></div>
    } else if (linktype.includes("audio/")) {
      //playing controls onReady={ this._audioVisualizer(id, domainS3 + url) }
      //     keyboardArrows = this._showKeyPads(element);
      file = (
        <div className="mobile-modal-audio">
          <Audio element={element} arrowFiles={this._arrowFiles} />
        </div>
      );
    } else if (linktype === "application/pdf") {
      file = (
        <PdfMobile
          element={element}
          showDimmer={this._showLoader}
          documentPages={this._documentPages}
          documentZoom={this._documentZoom}
        />
      );
    } else if (linktype.includes("tiktok")) {
      let videoIdArr = url.split("/");
      let videoId = videoIdArr[videoIdArr.length - 1];
      file = (
        <div className="tiktok-wrapper">
          <iframe
            src={"https://www.tiktok.com/embed/v2/" + videoId}
            className="react-player"
            frameBorder="0"
            width="100%"
            height="100%"
            scrolling="no"
            title={linkname}
            allowFullScreen
          ></iframe>
        </div>
      );
    } else if (libreffice.includes(linktype)) {
      file = (
        <LibreOffice
          mobile={true}
          element={element}
          documentPages={this._documentPages}
          documentZoom={this._documentZoom}
          openElement={
            element.hasOwnProperty("openElement") ? element.openElement : null
          }
          showDimmer={this._showLoader}
          _addNotification={this.props._addNotification}
        />
      );
    } else if (linktype === "news") {
      file = (
        <div className="news-mobile">
          <Item.Group>{element.feed}</Item.Group>
        </div>
      );
    } else {
      file = this._fileDownloadWrap(
        "Currently we unable to preview this file type, You can download and preview locally."
      );
    }

    return (
      <Modal
        dimmer="blurring"
        className="mobile-modal"
        size={"fullscreen"}
        open={this.props.open}
        onClose={this.props.closeMobileWindow.bind(this)}
        closeIcon
      >
        <Modal.Content className="mobile-modal-content">
          <div className="mobile-modal-title">{linkname}</div>
          <Dimmer
            className="dimmer noBackground"
            active={this.state.active}
            inverted
          >
            <Loader size="massive">Loading...</Loader>
          </Dimmer>
          {file}
        </Modal.Content>
      </Modal>
    );
  }
}
