import React, { Component } from "react";
import { Window, TitleBar, View } from "react-desktop/windows";
import Draggable from "react-draggable";
import { Resizable } from "re-resizable";
import ReactPlayer from "react-player";
import { Header, Icon, Button, Divider, Item } from "semantic-ui-react";
import { download } from "./Api";
import {
  domainS3,
  onlinePlayVideoFileTypes,
  onlineConvertVideoFileTypes,
  convertVideoMaximumFileSize,
  bytesToSize,
} from "../js/functions";
import Audio from "./Audio";
import Pdf from "./Pdf";
import ConvertVideo from "./ConvertVideo";
import LibreOffice from "./LibreOffice";
import AmazonAds from "./AmazonAds";
import keyUpImage from "../images/keyup.png";

import "../css/modal-window.css";

export default class ModalWindow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      documentPages: null,
      zoom: false,
      contentHeight: null,
      contentWidth: null,
      width: null,
      height: null,
      top: null,
      left: null,
      isMaximized: false,
      isMinimize: false,
      active: props.active,
      element: props.element,
      keyCounter: 0,
    };

    this._maximizeClick.bind(this);
    this._onRestoreDownClick.bind(this);
    this._onMinimizeClick.bind(this);
    this._onRestoreUpClick.bind(this);
    this._closeWindow.bind(this);
    this._activeWindow.bind(this);
    this._fileDownload.bind(this);
  }

  _windowDimansion = (width = null, height = null) => {
    let w = window.innerWidth,
      h = window.innerHeight,
      windowWidth = 0,
      windowHeight = 0,
      topPosition = 0,
      leftPosition = 0;

    if (width === null && height === null) {
      windowWidth = Math.floor(w / 2);
      windowHeight = Math.floor(h / 2);
      topPosition = Math.floor(Math.random() * (h - windowHeight)) + 10;
      leftPosition = Math.floor(Math.random() * (w - windowWidth)) + 10;
    } else {
      width += 26;
      height += 26 + 31;

      if (width < 360) width = 360;
      if (height < 200) height = 200;

      if (w < width && h < height) {
        windowWidth = w;
        windowHeight = h;
        topPosition = 0;
        leftPosition = 0;
      } else if (w > width && h < height) {
        windowWidth = width;
        windowHeight = h;
        topPosition = 0;
        leftPosition = Math.floor(Math.random() * (w - windowWidth)) + 0;
      } else if (w < width && h > height) {
        windowWidth = w;
        windowHeight = height;
        topPosition = Math.floor(Math.random() * (h - windowHeight)) + 0;
        leftPosition = 0;
      } else {
        windowWidth = width;
        windowHeight = height;
        topPosition = Math.floor(Math.random() * (h - windowHeight)) + 0;
        leftPosition = Math.floor(Math.random() * (w - windowWidth)) + 0;
      }
    }

    let size = {
      name: this.state.element.linkname,
      width: windowWidth,
      height: windowHeight,
      top: topPosition,
      left: leftPosition,
    };
    //  console.log("Window Dimension & Position: ", size);

    return size;
  };

  _maximizeClick = () => {
    this.setState({
      isMaximized: true,
      isMinimize: false,
      restoreWidth: this.state.width,
      restoreHeight: this.state.height,
      restoreTop: this.state.top,
      restoreLeft: this.state.left,
      width: window.innerWidth,
      height: window.innerHeight,
      top: 0,
      left: 0,
    });
  };

  _onRestoreDownClick = () => {
    this.setState({
      isMaximized: !this.state.isMaximized,
      isMinimize: false,
      width: this.state.restoreWidth,
      height: this.state.restoreHeight,
      top: this.state.restoreTop,
      left: this.state.restoreLeft,
    });
  };

  _onMinimizeClick = () => {
    this.setState({
      isMaximized: false,
      isMinimize: true,
    });
    this.props.controlWindow(this.state.element._id, "minimize");
  };

  _onRestoreUpClick = () => {
    this.setState({
      isMinimize: false,
      width: this.state.restoreWidth
        ? this.state.restoreWidth
        : this.state.width,
      height: this.state.restoreHeight
        ? this.state.restoreHeight
        : this.state.height,
      top: this.state.restoreTop ? this.state.restoreTop : this.state.top,
      left: this.state.restoreLeft ? this.state.restoreLeft : this.state.left,
    });
    this.props.controlWindow(this.state.element._id, "cancel-minimize");
  };

  _dragStop = (e, position) => {
    const { x, y } = position;
    if (x !== 0 && y !== 0) {
      this.setState({
        restoreTop: this.state.top + y,
        restoreLeft: this.state.left + x,
      });
      //   console.log(position);
    }
  };

  _closeWindow = (event) => {
    //  console.log("close: ", this.state.element._id);
    this.props.controlWindow(this.props.element._id, "remove");
  };

  _activeWindow = (event) => {
    this.props.activeClick(this.props.element._id);
    //  this.setState({ active: true });
  };

  _documentPages = (num) => {
    this.setState({ documentPages: num });
  };

  _documentZoom = (zoom) => {
    let newZoom = zoom <= 1.4 ? false : true;
    if (this.state.zoom !== newZoom) {
      this.setState({ zoom: newZoom });
    }
  };

  _fileDownload = () => {
    const { element } = this.state;
    download("?key0=" + element.linkurl);
  };

  _fileDownloadWrap = (message) => {
    return (
      <div className="downloadWrap">
        <Divider hidden clearing />
        <Header size="small">{message}</Header>
        <Button icon labelPosition="left" primary onClick={this._fileDownload}>
          <Icon name="download" /> Download
        </Button>
        <Divider hidden clearing />
        <AmazonAds
          windowWidth={this.state.width}
          windowHeight={this.state.height}
        />
        <br />
      </div>
    );
  };

  _arrowFiles = (arrow) => {
    const { element } = this.state;
    let index = null;
    //  console.log(arrow);

    for (let a = 0; a < element.filesList.length; a++) {
      if (element._id === element.filesList[a]._id) {
        //  console.log(a);
        index = a;
      }
    }

    if (index !== null) {
      if (arrow === "next") {
        if (index + 1 < element.filesList.length) {
          index = index + 1;
        } else {
          index = 0;
        }
      } else {
        if (index - 1 >= 0) {
          index = index - 1;
        } else {
          index = element.filesList.length - 1;
        }
      }

      element.filesList[index]["filesList"] = element.filesList;
      //    console.log("View element: ", element.filesList[index]);
      if (element.filesList[index].linktype.includes("image/")) {
        this.props.showDimmer(true, false);
        var img = new Image();
        let parent = this;
        img.onload = function () {
          parent.setState({
            contentWidth: this.width,
            contentHeight: this.height,
            element: element.filesList[index],
          });
          parent.props.showDimmer(false, false);
        };
        img.src = decodeURIComponent(
          domainS3 + element.filesList[index].linkurl
        );
      } else {
        this.setState({
          element: element.filesList[index],
        });
      }
    }
  };

  handleKeyPress = (event) => {
    event.preventDefault();
    event.stopImmediatePropagation();
    this.setState({ keyCounter: this.state.keyCounter + 0.5 });
    if (Number.isInteger(this.state.keyCounter) && this.props.active) {
      if (event.key === "ArrowUp" || event.key === "ArrowRight") {
        this._arrowFiles("next");
      } else {
        this._arrowFiles("prev");
      }
    }
  };

  /* 
  shouldComponentUpdate(nextProps, nextState) {
    console.log("shouldComponentUpdate nextState: ", nextState);
    return (this.state.width !== nextState.width || this.state.height !== nextState.height) ? true : false;
  }
*/

  _showKeyPads = (element) => {
    if (
      !this.state.isMinimize &&
      this.props.active &&
      element.hasOwnProperty("filesList") &&
      element.filesList.length > 1
    ) {
      let keyboardArrows = (
        <div className="keyboardArrows">
          <button
            className="keyboardUp"
            onClick={() => this._arrowFiles("next")}
          >
            <img src={keyUpImage} alt="" />
          </button>
          <button
            className="keyboardRight"
            onClick={() => this._arrowFiles("next")}
          >
            <img src={keyUpImage} alt="" />
          </button>
          <button
            className="keyboardLeft"
            onClick={() => this._arrowFiles("prev")}
          >
            <img src={keyUpImage} alt="" />
          </button>
          <button
            className="keyboardDown"
            onClick={() => this._arrowFiles("prev")}
          >
            <img src={keyUpImage} alt="" />
          </button>
        </div>
      );

      document.addEventListener("keydown", this.handleKeyPress);
      document.addEventListener("keyup", this.handleKeyPress);
      document.addEventListener("keyright", this.handleKeyPress);
      document.addEventListener("keyleft", this.handleKeyPress);

      return keyboardArrows;
    } else {
      document.removeEventListener("keydown", this.handleKeyPress);
      document.removeEventListener("keyup", this.handleKeyPress);
      document.removeEventListener("keyright", this.handleKeyPress);
      document.removeEventListener("keyleft", this.handleKeyPress);
    }
  };

  componentDidMount() {
    let { element, width, height, top, left } = this.state;

    let url = element.linkurl,
      linktype = element.linktype;
    if (width === null && height === null && top === null && left === null) {
      let libreffice = [
        "application/powerpoint",
        "application/vnd.ms-powerpoint",
        "application/vnd.openxmlformats-officedocument.presentationml.presentation",
        "application/excel",
        "application/vnd.ms-excel",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        "application/msword",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        "application/octet-stream",
        "text/plain",
      ];

      this._activeWindow();

      if (linktype.includes("image/")) {
        this.props.showDimmer(true, false);

        var img = new Image();
        let parent = this;
        img.onload = function () {
          let dimension = parent._windowDimansion(this.width, this.height);
          parent.setState({
            contentWidth: this.width,
            contentHeight: this.height,
            width: dimension.width,
            height: dimension.height,
            top: dimension.top,
            left: dimension.left,
          });
          parent.props.showDimmer(false, false);
        };
        img.src = domainS3 + url;
      } else if (
        linktype === "application/pdf" ||
        libreffice.includes(linktype)
      ) {
        let dimension = this._windowDimansion(965, 1177);
        this.setState({
          width: dimension.width,
          height: dimension.height,
          top: dimension.top,
          left: dimension.left,
        });
      } else if (linktype === "tiktok") {
        let dimension = this._windowDimansion(550, 650);
        this.setState({
          width: dimension.width,
          height: dimension.height,
          top: dimension.top,
          left: dimension.left,
        });
      } else {
        let dimension = this._windowDimansion();
        this.setState({
          width: dimension.width,
          height: dimension.height,
          top: dimension.top,
          left: dimension.left,
        });
      }
    }
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.handleKeyPress);
    document.removeEventListener("keyup", this.handleKeyPress);
    document.removeEventListener("keyright", this.handleKeyPress);
    document.removeEventListener("keyleft", this.handleKeyPress);
  }

  render() {
    const { miniWidth, miniCount, minimizedIndex } = this.props;
    let { element, width, height, top, left } = this.state;
    console.log("ModalWindow element: ", element);
    let miniStyle = {},
      linktype = element.linktype,
      url = element.linkurl,
      name = element.linkname,
      windowModalCssClass = "",
      windowViewCssClass = "",
      keyboardArrows = "";

    if (width !== null && height !== null && top !== null && left !== null) {
      const style = {
        top: this.state.top + "px",
        left: this.state.left + "px",
      };

      miniStyle = {
        width: miniWidth + "px",
      };

      if (minimizedIndex !== "") {
        let left = 0,
          bottom = 0;

        if (miniCount >= minimizedIndex) {
          left = miniWidth * (minimizedIndex - 1) + "px";
        } else {
          bottom = (Math.ceil(minimizedIndex / miniCount) - 1) * 31 + "px";
          if ((minimizedIndex / miniCount) % 1 !== 0) {
            left =
              (minimizedIndex -
                Math.floor(minimizedIndex / miniCount) * miniCount -
                1) *
                miniWidth +
              "px"; // miniWidth * (Math.round(miniCount / minimizedIndex) - 1)  + "px";
          } else {
            left = (miniCount - 1) * miniWidth + "px";
          }
        }

        miniStyle = {
          width: miniWidth + "px",
          bottom: bottom,
          left: left,
        };
      }

      //   let someDate = new Date().toString();
      let maximizedClass = this.state.isMaximized ? "noTransform" : "";
      let activeClass = this.props.active ? "activeWindow" : "";

      let file;
      let libreffice = [
        "application/powerpoint",
        "application/vnd.ms-powerpoint",
        "application/vnd.openxmlformats-officedocument.presentationml.presentation",
        "application/excel",
        "application/vnd.ms-excel",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        "application/msword",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        "application/octet-stream",
        "text/plain",
      ];

      if (linktype.includes("image/")) {
        let bgImageStyle = {
          backgroundImage: "url('" + decodeURIComponent(domainS3 + url) + "')",
        };

        if (element._id === this.props.id) {
          bgImageStyle["maxWidth"] = this.state.contentWidth + "px";
          bgImageStyle["maxHeight"] = this.state.contentHeight + "px";
        } else {
          if (width > this.state.contentWidth) {
            bgImageStyle["maxWidth"] = this.state.contentWidth + "px";
          }
          if (height > this.state.contentHeight) {
            bgImageStyle["maxHeight"] = this.state.contentHeight + "px";
          }
        }

        keyboardArrows = this._showKeyPads(element);
        file = (
          <div className="windowBackgroundImage" style={bgImageStyle}></div>
        );
      } else if (linktype.includes("youtube") || linktype.includes("vimeo")) {
        windowModalCssClass += " video-modal";
        keyboardArrows = this._showKeyPads(element);
        file = (
          <div className="player-wrapper video-player">
            <ReactPlayer
              url={url}
              className="react-player"
              playing
              controls
              width="100%"
              height="100%"
              onEnded={() => this._arrowFiles("next")}
            />
          </div>
        );
      } else if (linktype.includes("pornhub") || linktype.includes("xvideos")) {
        windowModalCssClass += " video-modal";
        keyboardArrows = this._showKeyPads(element);
        file = (
          <div className="player-wrapper video-player">
            <iframe
              src={url}
              className="react-player"
              frameBorder="0"
              width="100%"
              height="100%"
              scrolling="no"
              title={name}
              allowFullScreen
            ></iframe>
          </div>
        );
      } else if (linktype.includes("video/")) {
        if (onlinePlayVideoFileTypes.includes(linktype)) {
          // , onlinePlayVideoFileTypes, onlineConvertVideoFileTypes
          windowModalCssClass += "block video-modal";
          keyboardArrows = this._showKeyPads(element);
          url = element.hasOwnProperty("openElement")
            ? element.openElement
            : url;
          file = (
            <div className="player-wrapper video-player">
              <ReactPlayer
                url={domainS3 + url}
                className="react-player"
                playing
                controls
                width="100%"
                height="100%"
              />
            </div>
          );
        } else if (onlineConvertVideoFileTypes.includes(linktype)) {
          windowModalCssClass += "block video-modal";
          keyboardArrows = this._showKeyPads(element);

          if (
            element.size <= convertVideoMaximumFileSize ||
            convertVideoMaximumFileSize === "all"
          ) {
            file = (
              <ConvertVideo
                element={element}
                fileDownloadWrap={this._fileDownloadWrap}
                showKeyPads={this._showKeyPads}
                showDimmer={this.props.showDimmer}
              />
            );
          } else {
            let getSize = bytesToSize(convertVideoMaximumFileSize);
            file = this._fileDownloadWrap(
              "Maximum file size to convert video and play online is: " +
                getSize.size +
                getSize.unit +
                ", You can download and preview locally."
            );
          }
        } else {
          file = this._fileDownloadWrap(
            "Currently we unable to preview this file type, You can download and preview locally."
          );
        }
      } else if (linktype.includes("tiktok")) {
        windowModalCssClass += " video-modal";
        keyboardArrows = this._showKeyPads(element);
        let videoIdArr = url.split("/");
        let videoId = videoIdArr[videoIdArr.length - 1];
        file = (
          <div className="tiktok-wrapper">
            <iframe
              src={"https://www.tiktok.com/embed/v2/" + videoId}
              className="react-player"
              frameBorder="0"
              width="100%"
              height="100%"
              scrolling="no"
              title={name}
              allowFullScreen
            ></iframe>
          </div>
        );
      } else if (linktype.includes("audio/")) {
        //playing controls onReady={ this._audioVisualizer(id, domainS3 + url) }
        keyboardArrows = this._showKeyPads(element);
        file = <Audio element={element} arrowFiles={this._arrowFiles} />;
      } else if (linktype === "application/pdf") {
        windowModalCssClass += "block document";
        if (this.state.documentPages !== null) {
          if (this.state.height < 1177 || this.state.zoom) {
            windowViewCssClass += " scrollV";
          }
          if (this.state.width < 965 || this.state.zoom) {
            windowViewCssClass += " scrollH";
          }
        }
        file = (
          <Pdf
            element={element}
            showDimmer={this.props.showDimmer}
            documentPages={this._documentPages}
            documentZoom={this._documentZoom}
          />
        );
      } else if (libreffice.includes(linktype)) {
        windowModalCssClass += "block document";
        if (this.state.documentPages !== null) {
          if (this.state.height < 1177 || this.state.zoom) {
            windowViewCssClass += " scrollV";
          }
          if (this.state.width < 965 || this.state.zoom) {
            windowViewCssClass += " scrollH";
          }
        }

        //   console.log("LibreOffice: ", element);
        file = (
          <LibreOffice
            element={element}
            documentPages={this._documentPages}
            documentZoom={this._documentZoom}
            openElement={
              element.hasOwnProperty("openElement") ? element.openElement : null
            }
            showDimmer={this.props.showDimmer}
            _addNotification={this.props._addNotification}
          />
        );
      } else if (linktype === "news") {
        windowModalCssClass += " news-modal";
        windowViewCssClass += " overflowY";
        file = <Item.Group>{element.feed}</Item.Group>;
      } else {
        file = this._fileDownloadWrap(
          "Currently we unable to preview this file type, You can download and preview locally."
        );
      }
      //  console.log("Image Dimension: ", { width: this.props.width, height: this.props.height });

      return !this.state.isMinimize ? (
        <div>
          {keyboardArrows}
          <Draggable handle=".windowTitle" onStop={this._dragStop}>
            <Resizable
              minWidth="360"
              minHeight="200"
              className={`resizableWindow ${maximizedClass} ${activeClass}`}
              style={style}
              size={{ width: width, height: height }}
              onResizeStop={(e, direction, ref, d) => {
                this.setState({
                  width: this.state.width + d.width,
                  height: this.state.height + d.height,
                });
              }}
            >
              <Window
                color="#46BBDB"
                theme="light"
                chrome
                width="100%"
                height="100%"
                padding="12px"
                className={`windowModal ${windowModalCssClass}`}
                onClick={this._activeWindow}
              >
                <TitleBar
                  className="windowTitle"
                  title={name}
                  controls
                  isMaximized={this.state.isMaximized}
                  onCloseClick={this._closeWindow}
                  onMaximizeClick={this._maximizeClick}
                  onRestoreDownClick={this._onRestoreDownClick}
                  onMinimizeClick={this._onMinimizeClick}
                />
                <View className={`windowView ${windowViewCssClass}`}>
                  {file}
                </View>
              </Window>
            </Resizable>
          </Draggable>
        </div>
      ) : (
        <button
          className="windowMinimize"
          style={miniStyle}
          onClick={this._onRestoreUpClick}
        >
          <span>
            <svg x="0px" y="0px" viewBox="0 0 10.2 10.2" data-radium="true">
              <path d="M2.1,0v2H0v8.1h8.2v-2h2V0H2.1z M7.2,9.2H1.1V3h6.1V9.2z M9.2,7.1h-1V2H3.1V1h6.1V7.1z"></path>
            </svg>
            {name}
          </span>
        </button>
      );
    } else {
      return <div></div>;
    }
  }
}
