import React, { Component } from 'react';
import { Modal, Button } from 'semantic-ui-react';
import { removeUnsafeChars, CloudTextField, CloudChipInput } from '../../js/functions';

export default class EditFolderModal extends Component { 
  constructor(props) {
      super(props);
      this.state = {
          linkname: props.selectedElement.linkname,
          linktag: props.selectedElement.linktag,
          submitted: false,
          nameVal: ''
      }
      this.commonChange = this.commonChange.bind(this);
      this._showContent = this._showContent.bind(this);
  }

  _showContent = (e) => {
    e.preventDefault();
    let folder = true;
    let { linkname, linktag } = this.state;
    linkname = linkname.trim();
    if(this.props.selectedElement.linkname === linkname && this.props.selectedElement.linktag === linktag){
      this._closeModal();
    }
    else{
      if(linkname === ""){
        this.setState({ submitted: true });
        return false;
      } 

      if(this.props.editFile(this.props.selectedElement._id, { 
          linkname: linkname, 
          linktype: 'folder',
          linktag: linktag
      }, folder)){
        this._closeModal();
      }
      else {
        this.setState({ submitted: true, nameVal: "Name already exist" });
        return false;
      }
    }
  } 

  commonChange(event) {
    if(event.target.name === "linkname"){
      event.target.value = removeUnsafeChars(event.target.value);
    }
    this.setState({
        [event.target.name]: event.target.value
    });
  }

  handleChange(chips){
    this.setState({ linktag: chips });
  }

  _closeModal = () => {
    this.props.close();
      
    this.setState({
        linkname: '',
        linktag: [],
        submitted: false,
        nameVal: ''
    });
  }

  render(){

    let nameErrorMsg = "";

    if(this.state.linkname === "" && this.state.submitted) {
      nameErrorMsg = "This field is required";
    } 
    else if(this.state.submitted && this.state.nameVal !== ""){
      nameErrorMsg = this.state.nameVal;
    }   
    else {
      nameErrorMsg = "";
    }


    return (
      <Modal size="mini" dimmer={this.props.dimmer} open={this.props.open} onClose={this._closeModal}>
          <Modal.Header>Edit Folder</Modal.Header>
          <Modal.Content>
            <Modal.Description>
              <CloudTextField className="Cloud-Input mb30" variant="standard" label="Folder Name" name="linkname" onChange={this.commonChange} helperText={ nameErrorMsg } value={ this.state.linkname } /><br />
              <CloudChipInput className="Cloud-Input" id="tag" name="tag" label="Tags" onChange={(chips) => this.handleChange(chips)} defaultValue={ this.state.linktag } />
            </Modal.Description>
          </Modal.Content>
          <Modal.Actions>
            <Button negative icon='close' labelPosition='right' content="Cancel" onClick={this._closeModal} />
            <Button type="submit" positive icon='checkmark' labelPosition='left' content="Update" onClick={this._showContent} />
          </Modal.Actions>
      </Modal>
    );
  }
}