import React, { Component } from "react";
import {
  Divider,
  Grid,
  Button,
  Icon,
  Form,
  Message,
  Header,
} from "semantic-ui-react";
import ReCAPTCHA from "react-google-recaptcha";
import { sendMail } from "../components/Api";
import { CloudTextField } from "../js/functions";
import "../css/contact.css";

export default class Contact extends Component {
  constructor() {
    super();
    this.state = {
      name: "",
      email: "",
      message: "",
      nameStatus: "",
      emailStatus: "",
      disable: false,
    };
    this.commonChange = this.commonChange.bind(this);
    this.__validatecaptcha = this._validate.bind(this);
  }

  _send = (value) => {
    if (value !== "undefined" || value !== null || value !== "") {
      let obj = {
        name: this.state.name,
        email: this.state.email,
        message: this.state.message,
        captcha: value,
      };
      if (obj.name !== "" && obj.email !== "" && obj.captcha !== null) {
        sendMail(obj).then((res) => {
          //  console.log(res);
          if (res.data === "sent") {
            this.props._addNotification(
              "success",
              "Message sent successfully",
              ""
            );

            this.setState({
              name: "",
              email: "",
              message: "",
              nameStatus: "",
              emailStatus: "",
              disable: false,
            });
          } else {
            this.props._addNotification(
              "error",
              "Error",
              "Your message could not be sent"
            );
            this.setState({
              disable: false,
            });
          }
          this.captcha.reset();
        });
        console.log(obj);
      }
    } else {
      this.props._addNotification(
        "error",
        "Error",
        "Your message could not be sent"
      );
      this.setState({
        disable: false,
      });
      console.log("Captcha value: ", value);
    }
  };

  commonChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
    //   console.log("Event name: ", { name: event.target.name, value: event.target.value });
  };

  _validate = () => {
    let valid = true;
    let message = {};

    this.setState({ disable: true });
    if (this.state.name === "") {
      valid = false;
      message["nameStatus"] = "This field is required";
    }

    if (this.state.email === "") {
      valid = false;
      message["emailStatus"] = "This field is required";
    } else {
      if (!/(.+)@(.+){2,}\.(.+){2,}/.test(this.state.email)) {
        valid = false;
        message["emailStatus"] = "Email Address not valid";
      }
    }

    if (valid) {
      this.captcha.execute();
    } else {
      message["disable"] = false;
      this.setState(message);
      return false;
    }
  };

  shouldComponentUpdate(nextProps, nextState) {
    if (nextState === this.state) {
      return false;
    } else {
      return true;
    }
  }
  render() {
    return (
      <div className="contact">
        <Divider hidden clearing />
        <Grid centered>
          <Grid.Column mobile={15} tablet={15} computer={8}>
            <Message attached>
              <Header as="h1">
                <Icon name="at" />
                Contact
              </Header>
            </Message>
            <Form className="attached fluid segment" onSubmit={this._validate}>
              <CloudTextField
                className="text-field"
                variant="standard"
                label="Name"
                name="name"
                onChange={this.commonChange}
                value={this.state.name}
                helperText={this.state.nameStatus}
                fullWidth={true}
              />
              <CloudTextField
                className="text-field"
                variant="standard"
                label="E-mail"
                name="email"
                onChange={this.commonChange}
                value={this.state.email}
                helperText={this.state.emailStatus}
                fullWidth={true}
              />
              <CloudTextField
                className="text-field"
                variant="standard"
                label="Message"
                name="message"
                onChange={this.commonChange}
                value={this.state.message}
                multiline
                minRows={1}
                maxRows={4}
                fullWidth={true}
              />
              <ReCAPTCHA
                name="captcha"
                ref={(el) => {
                  this.captcha = el;
                }}
                size="invisible"
                sitekey="6LfBW1YUAAAAAGeWoS8xOIdO55gKS8ivqitqSHvK"
                onChange={this._send}
              />
              <Divider hidden />
              <Button
                disabled={this.state.disable}
                loading={this.state.disable}
                color="green"
              >
                <Icon name="send" /> Send
              </Button>
            </Form>
          </Grid.Column>
        </Grid>
        <Divider hidden clearing />
        <Divider hidden clearing />
        <Divider hidden clearing />
      </div>
    );
  }
}
