import React, { Component } from "react";
import { Grid, Header, Image } from "semantic-ui-react";
import ReactPlayer from "react-player";
import { domainS3, siteTitle } from "../js/functions";
import firstImage from "../images/upload-window4.png";
import secondeImage from "../images/add-window4.png";

export default class introText extends Component {
  render() {
    return (
      <div className="introTopTextComponentWrap">
        <Grid className="introTextComponent" verticalAlign="middle">
          <Grid.Row>
            <Grid.Column mobile={16} tablet={8} computer={8}>
              <Image
                src={firstImage}
                size="big"
                centered
                alt="Upload Files"
                width="600"
                height="359"
              />
            </Grid.Column>
            <Grid.Column mobile={16} tablet={8} computer={8}>
              <Header as="h2" textAlign="center">
                Upload Files
              </Header>
              <p>
                Easily upload files to our cloud storage web app that stored
                locally on your Desktop computer, Tablet and Mobile device or
                from different online providers: Google Drive, Dropbox and
                Instegram. <br />
                <br />
                Most popular uploaded files type you can view online and for
                some video files type that can't view online we will convert it
                to different file type so you can watch them online. We also
                automatically generating thumbnail from uploaded photos and
                videos so you can easily recognize your photos and videos.
              </p>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column
              mobile={16}
              tablet={8}
              computer={8}
              className="maxWidth767"
            >
              <Image
                src={secondeImage}
                size="large"
                centered
                alt="Add External Videos and Websites"
                width="450"
                height="459"
              />
            </Grid.Column>
            <Grid.Column mobile={16} tablet={8} computer={8}>
              <Header as="h2" textAlign="center">
                Add External Videos and Websites
              </Header>
              <p>
                Add you favorite: Websites, Youtube Videos, Youtube Playlists,
                Vimeo Videos, Pornhub Videos and XVIDEOS Videos. <br />
                We will expand this list in the future so you will have an
                option to add content from more external sources. <br />
                <br />
                Any website that you will add to {siteTitle} we will
                automaticlly generate and attach an image thumbnail of the
                website.
              </p>
            </Grid.Column>
            <Grid.Column
              mobile={16}
              tablet={8}
              computer={8}
              className="minWidth768"
            >
              <Image
                src={secondeImage}
                size="large"
                centered
                alt="Add External Videos and Websites"
                width="450"
                height="459"
              />
            </Grid.Column>
          </Grid.Row>

          <Grid.Row className="minWidth768">
            <Grid.Column>
              <Header as="h2" textAlign="center">
                {siteTitle} in Action
              </Header>
              <ReactPlayer
                className="cloudos-in-action-video"
                playing
                muted
                config={{
                  file: {
                    attributes: {
                      autoPlay: true,
                      muted: true,
                      playsInline: true,
                    },
                  },
                }}
                loop={true}
                width="100%"
                height="100%"
                url={domainS3 + "defaultImages/cloudos-in-action.mp4"}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    );
  }
}
