import react, { Suspense } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { Dimmer, Loader, Container } from "semantic-ui-react";
import { isTablet, isMobile } from "react-device-detect";
import GoogleSearchResults from "./GoogleResults";
import About from "../pages/about";
import Extension from "../pages/extension";
import Startup from "../pages/startup";
import Contact from "../pages/contact";
import Updates from "../pages/updates";
import Faq from "../pages/faq";
import Terms from "../pages/terms";
import Cookie from "../pages/cookie";
import Privacy from "../pages/privacy";
import PageNotFound from "../pages/404";
import Pricing from "../pages/pricing";
import Payed from "../pages/payed";
import Cancelled from "../pages/cancelled";
import Intro from "./Intro";
import { relogin } from "../js/functions";

const Routes = ({
  isAuth,
  profile,
  _darkMode,
  sub,
  mobileDevice,
  loginPricing,
  handleAuthentication,
  account,
  auth,
  _addNotification,
}) => {
  return (
    <Switch>
      <Route
        exact
        path="/"
        render={() =>
          isAuth ? (
            <Redirect to="/allfiles/" />
          ) : (
            <Container
              fluid
              className={
                "cloud-content clearfix" +
                (isTablet || isMobile ? " cloud-content-mobile" : "")
              }
            >
              <Intro mobileDevice={mobileDevice} darkMode={_darkMode} />
            </Container>
          )
        }
      />{" "}
      <Route
        path="/allfiles/"
        render={() => (!isAuth ? <Redirect to="/" /> : sub)}
      />
      <Route
        path="/home"
        render={() => (
          <CloudWindow>
            <Suspense fallback={""}>
              <GoogleAds />
            </Suspense>
          </CloudWindow>
        )}
      />
      <Route
        path="/pricing"
        render={() => (
          <Pricing isAuth={isAuth} login={loginPricing} profile={profile} />
        )}
      />
      <Route path="/payed" render={() => <Payed />} />
      <Route path="/cancelled" render={() => <Cancelled />} />
      <Route path="/about" component={About} />
      <Route path="/faq" component={Faq} />
      <Route path="/terms" component={Terms} />
      <Route path="/cookie" component={Cookie} />
      <Route path="/privacy" component={Privacy} />
      <Route path="/extension" component={Extension} />
      <Route path="/startup" component={Startup} />
      <Route
        path="/contact"
        render={() => <Contact _addNotification={_addNotification} />}
      />
      <Route
        path="/login"
        render={() => {
          auth.login();
          return true;
        }}
      />
      <Route
        path="/loginpricing"
        render={() => {
          auth.loginPricing();
          return true;
        }}
      />
      <Route path="/relogin" render={() => relogin()} />
      <Route
        path="/updates"
        render={() => (
          <Updates auth={auth} _addNotification={_addNotification} />
        )}
      />
      <Route path="/result" component={GoogleSearchResults} />
      <Route
        path="/account"
        render={() => (!isAuth ? <Redirect to="/" /> : account)}
      />
      <Route
        path="/callback"
        render={() => {
          handleAuthentication();
          return (
            <Dimmer className="dimmer" active={true} inverted>
              <Loader size="massive">Loading</Loader>
            </Dimmer>
          );
        }}
      />
      <Route
        path="/pricingcallback"
        render={() => {
          handleAuthentication("/pricing");
          return (
            <Dimmer className="dimmer" active={true} inverted>
              <Loader size="massive">Loading</Loader>
            </Dimmer>
          );
        }}
      />
      <Route render={() => <PageNotFound />} />
    </Switch>
  );
};

export default Routes;
