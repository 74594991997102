import React, { Component } from 'react';
import { Divider } from 'semantic-ui-react';

export default class GoogleSearchResults extends Component {
  render(){ 
    return (
      <div>
        <div className="resultsBox" dangerouslySetInnerHTML={{ __html: '<gcse:searchresults-only></gcse:searchresults-only>' }}></div>
        <Divider hidden clearing />
        <Divider hidden clearing />
      </div>
    );
  }
}